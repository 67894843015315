import {
	IFilterDefinition,
	ISelectFilterDefinition,
	ITextFilterDefinition,
	OptionType
} from '@evinced-private/ui-common';

const DEFAULT_PERIOD_OPTION: OptionType = { value: 30, label: 'Last 30 days' };

export const SESSIONS_PERIOD_OPTIONS: OptionType[] = [
	DEFAULT_PERIOD_OPTION,
	{ value: 60, label: 'Last 60 days' },
	{ value: 90, label: 'Last 90 days' },
	{ value: 'all', label: 'All Sessions' }
];

const searchFilter: ITextFilterDefinition = {
	id: 'searchValue',
	label: 'Search',
	filterType: 'text',
	defaultValue: '',
	componentProps: {
		className: 'search-filter',
		placeholder: 'Search'
	}
};

const sessionsPeriodFilter: ISelectFilterDefinition = {
	id: 'sessionsPeriod',
	label: 'sessions period',
	filterType: 'select',
	defaultValue: [DEFAULT_PERIOD_OPTION],
	componentProps: {
		placeholder: 'Select Sessions Period',
		isMulti: false
	}
};

const createdByFilter: ISelectFilterDefinition = {
	id: 'createdBy',
	label: 'created by',
	filterType: 'select',
	defaultValue: [],
	componentProps: {
		placeholder: 'Select Created By',
		allSelectedPlaceholder: 'All Created By',
		someItemsSelectedText: (numberOfItems) => `Created By (${numberOfItems})`,
		allowSelectAll: true,
		isMulti: true,
		className: 'created-by-filter'
	}
};

export const wfaSessionsFilterDefinitions: IFilterDefinition[] = [
	searchFilter,
	createdByFilter,
	sessionsPeriodFilter
];
