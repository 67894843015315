enum DETECTION_TYPES {
	USE_AXE_BEST_PRACTICES = 'USE_AXE_BEST_PRACTICES',
	USE_AXE_NEEDS_REVIEW = 'USE_AXE_NEEDS_REVIEW',
	USE_EVINCED_RECOMMENDED_ISSUE_TYPES = 'USE_EVINCED_RECOMMENDED_ISSUE_TYPES'
}

const EVINCED_RECOMMENDED_TEXT = 'Default validations';
const NEEDS_REVIEW_ENABLED_TEXT = 'Include needs review';
const BEST_PRACTICES_ENABLED_TEXT = 'Include best practices';
const NEEDS_REVIEW_AND_BEST_PRACTICES_ENABLED_TEXT = 'Include best practices and needs review';

export const formatDetectionTypesLabel = (detectionTypes: string[]): string => {
	if (
		detectionTypes.includes(DETECTION_TYPES.USE_AXE_NEEDS_REVIEW) &&
		detectionTypes.includes(DETECTION_TYPES.USE_AXE_BEST_PRACTICES)
	) {
		return NEEDS_REVIEW_AND_BEST_PRACTICES_ENABLED_TEXT;
	}
	if (detectionTypes.includes(DETECTION_TYPES.USE_AXE_NEEDS_REVIEW)) {
		return NEEDS_REVIEW_ENABLED_TEXT;
	}
	if (detectionTypes.includes(DETECTION_TYPES.USE_AXE_BEST_PRACTICES)) {
		return BEST_PRACTICES_ENABLED_TEXT;
	}
	return EVINCED_RECOMMENDED_TEXT;
};
