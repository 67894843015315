import { ClientError, GraphQLClient } from 'graphql-request';

import AuthenticationService from 'src/services/AuthenticationService';
import Logger from 'src/services/Logger';

import RoutesHelper from '../helpers/RoutesHelper';

const GRAPHQL_ENDPOINT = `${process.env.API_ENDPOINT}/graphql`;

export const gqlClient = new GraphQLClient(GRAPHQL_ENDPOINT, {
	headers: () => ({
		Authorization: `Bearer ${AuthenticationService.getAccessToken()}`,
		'Content-Type': 'application/graphql'
	}),
	responseMiddleware: async (response) => {
		if (response instanceof ClientError) {
			const error = response;

			Logger.error('Error from server', error);
			if (error.response?.status === 401) {
				await AuthenticationService.logout();
			} else if (error.response?.status === 403) {
				// redirect occurs. flow ends here
				window.location.href = RoutesHelper.getUnauthorized403Page();
			}
		}
	}
});
