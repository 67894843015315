import React, { FC, useMemo } from 'react';

import { EvSection, TABLE_SR_SUMMARY_IDS } from '@evinced-private/ui-common';

import PlatformTable from 'src/components/common/platform-table/PlatformTable';
import { buildFullTableId } from 'src/helpers/TableStateHelper';
import {
	WFA_SESSION_COMPONENTS_TABLE_ID,
	WFA_SESSION_ISSUES_TABLE_ID } from 'src/pages/dashboards-container/dashboard_consts';
import { useWfaComponentSampleIssueQuery } from 'src/pages/wfa-dashboard/hooks/useWfaComponentSampleIssueQuery';
import { useWfaSessionComponentsQuery } from 'src/pages/wfa-dashboard/hooks/useWfaSessionComponentsQuery';
import {
	WFA_SESSION_COMPONENTS_TAB_DAID,
	WFA_SESSION_COMPONENTS_TABLE_DAID
} from 'src/pages/wfa-dashboard/WfaDashboardAutomationIds';
import { useTableState } from 'src/providers/dashboard/TableStateProvider';

import { WfaSingleSessionFiltersSection } from '../../common/filters-section/WfaSingleSessionFiltersSection';

import { getWfaComponentsColumns } from './WfaSessionComponentsTableHelper';

import './WfaSessionComponents.scss';

interface IWfaSessionComponentsProps {
	sessionId: string;
}

export const WfaSessionComponents: FC<IWfaSessionComponentsProps> = ({ sessionId }) => {
	const { getTableStateById, onFilterChange } = useTableState();
	const tableId = useMemo(
		() => buildFullTableId(WFA_SESSION_COMPONENTS_TABLE_ID, sessionId),
		[sessionId]
	);
	const tableState = getTableStateById(tableId);
	const { filters, tableSort, paginationInfo } = tableState;

	// remove this when actually using the hook
	console.log(useWfaComponentSampleIssueQuery);

	const componentsQuery = useWfaSessionComponentsQuery({
		sessionId,
		sort: tableSort?.[0],
		page: paginationInfo.page,
		pageSize: paginationInfo.sizePerPage,
		filters
	});

	const handleComponentClicked = (componentId: string): void => {
		const issuesTableIdForSession = buildFullTableId(WFA_SESSION_ISSUES_TABLE_ID, sessionId);
		onFilterChange(issuesTableIdForSession, {
			searchValue: componentId
		});
	};

	const { total = 0, unfilteredTotal = 0, rows = [] } = componentsQuery.data ?? {};

	return (
		<EvSection
			className="wfa-session-components"
			dataAutomationId={WFA_SESSION_COMPONENTS_TAB_DAID}
		>
			<WfaSingleSessionFiltersSection
				sessionId={sessionId}
				filters={filters}
				ariaLabel="WFA Session's components filters"
				onFilterChange={onFilterChange}
				tableId={tableId}
			/>
			<EvSection
				ariaLabel="WFA Session's components table"
				ariaLive="polite"
				ariaDescribedby={TABLE_SR_SUMMARY_IDS}
				skipLinkId="main-section"
			>
				<PlatformTable
					dataAutomationId={WFA_SESSION_COMPONENTS_TABLE_DAID}
					isTableLoading={componentsQuery.isLoading || componentsQuery.isPreviousData}
					tableId={tableId}
					tableData={rows}
					totalCount={unfilteredTotal}
					totalPaginationableResults={total}
					className="session-components-table"
					persistTableState
					columns={getWfaComponentsColumns(sessionId, handleComponentClicked, tableId)}
					options={{
						keyField: 'id',
						title: 'WFA Session Components',
						caption: 'WFA Session Components table',
						pagination: true,
						dataType: 'components',
						remote: true
					}}
				/>
			</EvSection>
		</EvSection>
	);
};
