import React, { FC } from 'react';

import { SKIP_LINK_ANCHOR_ATTRIBUTE_NAME } from '@evinced-private/ui-common';

interface IEvSectionProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
	ariaLabel?: string;
	ariaLabelledby?: string;
	ariaDescribedby?: string;
	ariaLive?: 'off' | 'assertive' | 'polite';
	className?: string;
	/**
	 * When a skipLinkId is passed, the section will be on the skip links list
	 */
	skipLinkId?: string;
}
const EvSection: FC<IEvSectionProps> = ({
	children,
	ariaLabel = null,
	ariaLabelledby = null,
	ariaDescribedby = null,
	skipLinkId = null,
	ariaLive = null,
	className = ''
}: IEvSectionProps) => {
	const skipLinkAttribute = skipLinkId ? { [SKIP_LINK_ANCHOR_ATTRIBUTE_NAME]: skipLinkId } : {};
	return (
		<section
			aria-label={ariaLabel}
			aria-describedby={ariaDescribedby}
			aria-labelledby={ariaLabelledby}
			aria-live={ariaLive}
			className={className}
			tabIndex={skipLinkId ? -1 : null}
			{...skipLinkAttribute}
		>
			{children}
		</section>
	);
};

export default EvSection;
