import { callGETAndCache } from '../api/ServerCacheApi';
import API_URL_PATHS from '../consts/ApiUrlPaths';
import { ITenant, ProductType } from '../interfaces/Tenant';

const { TENANTS_URL_PATH } = API_URL_PATHS;

export async function getUserTenant(): Promise<ITenant> {
	const tenants: ITenant[] = await callGETAndCache(TENANTS_URL_PATH);
	if (!tenants || tenants.length === 0) {
		return null;
	}
	return tenants[0];
}

export const userHasAccessToProduct = async (
	productType: ProductType,
	userTenant?: ITenant
): Promise<boolean> => {
	let tenant: ITenant = null;
	if (userTenant) {
		tenant = { ...userTenant };
	} else {
		tenant = await getUserTenant();
	}
	if (!tenant) {
		return false;
	}

	// currently we have the logic of the product type home in the dashboard container
	// in order to leave this logic to that component (temporary)
	// we will return true and so we will go the the next level permissions guard
	if (productType === ProductType.HOME) {
		return true;
	}
	const productPermissions = tenant.products?.find((p) => p.type === productType);
	return !!productPermissions;
};
