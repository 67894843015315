import React, {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';

import { isEqual } from 'lodash-es';

import { IEvBreadcrumb } from 'src/components/header-navigation/EvBreadcrumbs';

interface IBreadcrumbRoutesContext {
	breadcrumbRoutes: IEvBreadcrumb[];
	setBreadcrumbRoutes: Dispatch<SetStateAction<IEvBreadcrumb[]>>;
}

const BreadcrumbRoutesContext = createContext<IBreadcrumbRoutesContext>(null);

export const BreadcrumbRoutesProvider = ({ children }): JSX.Element => {
	const [breadcrumbRoutes, setBreadcrumbRoutes] = useState<IEvBreadcrumb[]>([]);

	const value = useMemo(() => ({ breadcrumbRoutes, setBreadcrumbRoutes }), [breadcrumbRoutes]);

	return (
		<BreadcrumbRoutesContext.Provider value={value}>{children}</BreadcrumbRoutesContext.Provider>
	);
};

export const useBreadcrumbRoutes = (
	newBreadcrumbRoutes?: IEvBreadcrumb[]
): IBreadcrumbRoutesContext => {
	const { breadcrumbRoutes, setBreadcrumbRoutes } = useContext(BreadcrumbRoutesContext);

	useEffect(() => {
		if (!!newBreadcrumbRoutes?.length && !isEqual(breadcrumbRoutes, newBreadcrumbRoutes ?? [])) {
			setBreadcrumbRoutes(newBreadcrumbRoutes);
		}

		return () => {
			if (!!newBreadcrumbRoutes?.length && isEqual(breadcrumbRoutes, newBreadcrumbRoutes ?? [])) {
				setBreadcrumbRoutes([]);
			}
		};
	}, [setBreadcrumbRoutes, newBreadcrumbRoutes, breadcrumbRoutes]);

	return { breadcrumbRoutes, setBreadcrumbRoutes };
};
