import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { WFA_SESSIONS } from 'src/providers/reactQueryProvider/QueryKeys';
import { getWfaSessions } from 'src/services/wfa-dashboard/WfaDashboardService';
import { TWfaSessionsResult } from 'src/services/wfa-dashboard/WfaDashboardService.types';
import { ITableQueryParams } from 'src/types/TableQueryTypes';

import { IUseTableQueryParams } from '../WfaDashboardTypes';

export const useWfaSessionsQuery = ({
	filters,
	sort,
	page,
	pageSize
}: IUseTableQueryParams): UseQueryResult<TWfaSessionsResult> => {
	const sessionsQuery = useQuery(
		[WFA_SESSIONS, filters, sort, page, pageSize],
		async (): Promise<TWfaSessionsResult> =>
			getWfaSessions({
				filterOptions: filters as ITableQueryParams['filterOptions'],
				sort,
				page,
				pageSize
			}),
		{
			keepPreviousData: true
		}
	);

	return sessionsQuery;
};
