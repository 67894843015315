import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import decode from 'jwt-decode';

import localStorageApi from '../api/LocalStorageApi';
import RoutesHelper from '../helpers/RoutesHelper';

import Logger from './Logger';

const NO_USER_EMAIL = 'NO_USER_EMAIL';
const NO_USER_ACCOUNT = 'NO_USER_ACCOUNT';

let auth0Client: Auth0Client = null;

const getUserId = (authUserId: string): string => {
	return authUserId.split('|')[1];
};

const getAccessToken = (): string => {
	return localStorageApi.getAccessToken();
};

const getIdToken = (): string => {
	return localStorageApi.getIdToken();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const decodeToken = (token: string): any => {
	if (token) {
		return decode(token);
	}
	return null;
};

const getEvincedUserIdFromIdToken = (idToken: string): string => {
	if (!idToken) {
		return null;
	}
	const decodedToken = decodeToken(idToken);
	const domainEntity = decodedToken.sub;
	return domainEntity ? getUserId(domainEntity) : null;
};

const getEvincedUserId = (): string => {
	return getEvincedUserIdFromIdToken(getIdToken());
};

const isTokenExpired = (token: string): boolean => {
	try {
		const decoded = decodeToken(token);
		if (decoded.exp <= Date.now() / 1000) {
			// Checking if a specific token had expired
			return true;
		}
		return false;
	} catch (err) {
		return true;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AuthenticationResult = { accessToken: string; idToken: string; appState: any };

const setTokens = (authResult: AuthenticationResult): void => {
	localStorageApi.setAccessToken(authResult.accessToken);
	localStorageApi.setIdToken(authResult.idToken);
};

const getLoginAbsoluteUrl = (): string => {
	const { protocol, host } = window.location;
	const redirectToUrl = `${protocol}//${host}${RoutesHelper.getLoginPath()}`;
	return redirectToUrl;
};

const loginUrl = getLoginAbsoluteUrl();

const getAuth0Client = async (): Promise<Auth0Client> => {
	if (!auth0Client) {
		auth0Client = await createAuth0Client({
			domain: process.env.AUTH0_EVINCED_DOMAIN,
			clientId: process.env.AUTH0_CLIENT_ID,
			authorizationParams: {
				audience: process.env.AUTH0_EVINCED_API_AUDIENCE
			}
		});
	}
	return auth0Client;
};

const validateSession = (authResult): AuthCheckAndRevalidateReposnse => {
	if (authResult) {
		// if the token is as the one existing in local storage, use it.
		const localstorageUserId = getEvincedUserId();
		const auth0ResponseUserId = getEvincedUserIdFromIdToken(authResult.idToken);
		if (localstorageUserId === auth0ResponseUserId) {
			setTokens(authResult);
			return { auth0Result: authResult, didUserSwitchAccounts: false };
		}
		// user's session is valid, but it's different from the one existing in localstorage
		// this means that the user has switched account.
		// We'll remove the data from localstorage and reload app
		setTokens(authResult);
		return { auth0Result: authResult, didUserSwitchAccounts: true };
	}

	// got here if the user's token has expired/logged out,
	localStorageApi.removeAuthenticationTokens();
	return null;
};

const universalTokenSet = async (): Promise<AuthCheckAndRevalidateReposnse> => {
	const auth0Client = await getAuth0Client();
	let authResult;
	try {
		const accessToken = await auth0Client.getTokenSilently({ cacheMode: 'off' });
		authResult = {
			accessToken,
			idToken: accessToken,
			appState: null
		};
	} catch (err) {
		Logger.error(`Failed to get token: ${err}`);
	}
	return validateSession(authResult);
};

type AuthCheckAndRevalidateReposnse = {
	// results from auth0
	auth0Result: AuthenticationResult;
	// true iff the user related to Auth0's cookie session
	// is different than the one stored in localstorage
	didUserSwitchAccounts: boolean;
};

const checkAndRevalidateSession = async (): Promise<AuthCheckAndRevalidateReposnse> => {
	const sessionValidation = await universalTokenSet();
	return sessionValidation;
};

const checkUniversalLoginSwitchAccountsSession = async (token): Promise<void> => {
	const currentUserId = getEvincedUserIdFromIdToken(token);
	const localstorageUserId = getEvincedUserId();
	if (currentUserId !== localstorageUserId) {
		const auth0Client = await getAuth0Client();
		await auth0Client.logout();
	}
};

/**
 * @returns true iff user has a valid token in local storage
 */
const isLoggedIn = (): boolean => {
	// Checks if there is a saved token and if it's still valid
	const token = getAccessToken(); // Getting token from local storage
	return !!token && !isTokenExpired(token); // handwaiving here
};

const logout = async (): Promise<void> => {
	// Clear user tokens from local storage
	localStorageApi.removeValuesOnLogout();
	const auth0Client = await getAuth0Client();
	auth0Client.logout({ logoutParams: { returnTo: loginUrl } });
};

const getProfile = (): { name: string } => decodeToken(getIdToken());

const extractUserEmailFromToken = (decodedToken: { name: string }): string => {
	return (
		decodedToken?.name ||
		decodedToken?.[`https://${process.env.AUTH0_EVINCED_APP_DOMAIN}/email`] ||
		NO_USER_EMAIL
	);
};
const getUserEmail = (): string => {
	return extractUserEmailFromToken(getProfile());
};

const getUserEmailDomain = (): string => {
	const userEmail = extractUserEmailFromToken(getProfile());
	if (userEmail !== NO_USER_EMAIL) {
		const emailDomainIndex = userEmail.indexOf('@');
		if (emailDomainIndex !== -1) {
			return userEmail.substr(emailDomainIndex + 1);
		}
	}
	return NO_USER_ACCOUNT;
};

const getUserFromLocalToken = (): object => {
	const idToken = getIdToken();
	let userToken = {};
	if (idToken) {
		userToken = decodeToken(idToken);
	}
	return userToken;
};

const getUserAuth0Client = async (
	{ force }: { force?: boolean } = { force: false }
): Promise<object> => {
	if (force) {
		await universalTokenSet();
	}
	const auth0Client = await getAuth0Client();
	const user = await auth0Client.getUser();
	return user;
};

const getUserDetails = ({ user }: { user?: object } = {}): {
	firstName: string;
	lastName: string;
} => {
	if (!user) {
		user = getUserFromLocalToken();
	}
	const firstName = user[`https://${process.env.AUTH0_EVINCED_APP_DOMAIN}/given_name`] || '';
	const lastName = user[`https://${process.env.AUTH0_EVINCED_APP_DOMAIN}/family_name`] || '';
	return {
		firstName,
		lastName
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractAppMetadata = ({ user }: { user?: object } = {}): any => {
	if (!user) {
		user = getUserFromLocalToken();
	}
	return user[`https://${process.env.AUTH0_EVINCED_APP_DOMAIN}/app_metadata`] || {};
};

const isEvincedUser = (): boolean => {
	const userEmailDomain = getUserEmailDomain();
	return userEmailDomain === 'evinced.com';
};

export default {
	isLoggedIn,
	setTokens,
	logout,
	getAccessToken,
	checkUniversalLoginSwitchAccountsSession,
	getEvincedUserId,
	checkAndRevalidateSession,
	getUserEmail,
	getUserEmailDomain,
	getUserFromLocalToken,
	extractAppMetadata,
	getUserAuth0Client,
	getUserDetails,
	isEvincedUser
};
