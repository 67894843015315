import React, { FC, useMemo } from 'react';

import { EvSection, TABLE_SR_SUMMARY_IDS } from '@evinced-private/ui-common';

import PlatformTable from 'src/components/common/platform-table/PlatformTable';
import { buildFullTableId } from 'src/helpers/TableStateHelper';
import { WFA_SESSION_ISSUES_TABLE_ID } from 'src/pages/dashboards-container/dashboard_consts';
import { useWfaSessionAllIssuesQuery } from 'src/pages/wfa-dashboard/hooks/useWfaSessionAllIssuesQuery';
import {
	WFA_SESSION_ALL_ISSUES_TAB_DAID,
	WFA_SESSION_ISSUES_TABLE_DAID
} from 'src/pages/wfa-dashboard/WfaDashboardAutomationIds';
import { useTableState } from 'src/providers/dashboard/TableStateProvider';

import { WfaSingleSessionFiltersSection } from '../../common/filters-section/WfaSingleSessionFiltersSection';

import { getWfaAllIssuesColumns } from './WfaSessionAllIssuesTableHelper';

import './WfaSessionAllIssues.scss';

interface IWfaSessionIssuesProps {
	sessionId: string;
}

export const WfaSessionAllIssues: FC<IWfaSessionIssuesProps> = ({ sessionId }) => {
	const { getTableStateById, onFilterChange } = useTableState();
	const tableId = useMemo(
		() => buildFullTableId(WFA_SESSION_ISSUES_TABLE_ID, sessionId),
		[sessionId]
	);
	const tableState = getTableStateById(tableId);
	const { filters, tableSort, paginationInfo } = tableState;

	const issuesQuery = useWfaSessionAllIssuesQuery({
		sessionId,
		sort: tableSort?.[0],
		page: paginationInfo.page,
		pageSize: paginationInfo.sizePerPage,
		filters
	});

	const { total = 0, unfilteredTotal = 0, rows = [] } = issuesQuery.data ?? {};

	return (
		<EvSection
			className="wfa-session-all-issues-view"
			dataAutomationId={WFA_SESSION_ALL_ISSUES_TAB_DAID}
		>
			<WfaSingleSessionFiltersSection
				sessionId={sessionId}
				filters={filters}
				ariaLabel="WFA Session's all issues filters"
				onFilterChange={onFilterChange}
				tableId={tableId}
			/>
			<EvSection
				ariaLabel="WFA Session's all issues table"
				ariaLive="polite"
				ariaDescribedby={TABLE_SR_SUMMARY_IDS}
				skipLinkId="main-section"
			>
				<PlatformTable
					dataAutomationId={WFA_SESSION_ISSUES_TABLE_DAID}
					isTableLoading={issuesQuery.isLoading || issuesQuery.isPreviousData}
					tableId={tableId}
					tableData={rows}
					totalCount={unfilteredTotal}
					totalPaginationableResults={total}
					className="session-issues-table"
					persistTableState
					columns={getWfaAllIssuesColumns()}
					options={{
						keyField: 'id',
						title: 'WFA Session Issues',
						caption: 'WFA Session Issues table',
						pagination: true,
						dataType: 'issues',
						remote: true
					}}
					withUrlSwitcher={true}
				/>
			</EvSection>
		</EvSection>
	);
};
