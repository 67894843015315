import React, { FC, useCallback, useRef } from 'react';
import { CSVLink } from 'react-csv';

import { BUTTON_TYPES, EvButton, TableSortOption } from '@evinced-private/ui-common';

import { MfaSession } from 'src/apps/mfa/types/mfa-types';
import { CsvHeader, getCsvSeparator, getReportsCsvData } from 'src/helpers/CsvDownloadHelper';

import { MSdkTest } from '../../../apps/mobile-sdk/types/MobileSdkTypes';

interface IDownloadCSV {
	csvFileName: string;
	reportHeaders: CsvHeader[];
	data: MfaSession[] | MSdkTest[];
	sortByValue: TableSortOption;
	formatDateColumns?: string[];
}

const DownloadCSV: FC<IDownloadCSV> = ({
	csvFileName,
	reportHeaders,
	data,
	sortByValue,
	formatDateColumns
}): JSX.Element => {
	const csvLink = useRef<{ link: HTMLAnchorElement }>(null);

	const onDownloadCsvClicked = useCallback((): void => {
		if (csvLink && csvLink.current && csvLink.current.link) {
			csvLink.current.link.click();
		}
	}, [csvLink]);

	return (
		<>
			<EvButton type={BUTTON_TYPES.ACTION} onClick={onDownloadCsvClicked} title="Download CSV">
				Download CSV
			</EvButton>
			<CSVLink
				style={{ display: 'none' }}
				ref={csvLink}
				headers={reportHeaders}
				data={getReportsCsvData(data, reportHeaders, sortByValue, {
					formatDate: formatDateColumns
				})}
				separator={getCsvSeparator()}
				filename={csvFileName}
				tabIndex={-1}
			/>
		</>
	);
};

export default DownloadCSV;
