/* eslint-disable camelcase */
import { IssuesTextSearchFields } from 'src/types/graphql.generated';

import { QueryVariablesBuilder } from '../../../../../helpers/GQLRequestsHelper/QueryVariablesBuilder';
import { RUN_ID_PARAM_NAME } from '../../CommonParams';
import { WfaComponentSampleIssueQueryVariables } from '../WfaComponentSampleIssue.generated';

const COMPONENT_SEARCH_FIELD = [IssuesTextSearchFields.ElementsComponentId];

export const buildWfaComponentSampleIssueQueryVariables = (
	runId: string,
	componentId: string
): WfaComponentSampleIssueQueryVariables => {
	const variablesBuilder = new QueryVariablesBuilder<WfaComponentSampleIssueQueryVariables>({
		searchFields: COMPONENT_SEARCH_FIELD
	});

	variablesBuilder
		.addConditionParam(RUN_ID_PARAM_NAME, runId)
		// this is a workaround until the bug with elements__component_id_eq_any_item is solved
		.addTextSearchParam(componentId);

	return variablesBuilder.getVariables();
};
