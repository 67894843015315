import React, { FC, useCallback } from 'react';

import ComponentDetailsPopup from 'src/components/component-details-popup/ComponentDetailsPopup';
import { ProductType } from 'src/interfaces/Tenant';

import ShowDetailsCellFormatter from './ShowDetailsCellFormatter';

interface IShowComponentDetailsCellFormatter {
	product: ProductType;
	runId: string;
	componentId: string;
	totalIssues: number;
	totalPages: number;
	infoTitle: string;
}

export const ShowComponentDetailsCellFormatter: FC<IShowComponentDetailsCellFormatter> = ({
	product,
	runId,
	componentId,
	totalIssues,
	totalPages,
	infoTitle
}) => {
	const renderPopup = useCallback(
		(closeModal: () => void) => (
			<ComponentDetailsPopup
				runId={runId}
				componentId={componentId}
				product={product}
				totalIssues={totalIssues}
				totalPages={totalPages}
				onClose={closeModal}
			/>
		),
		[componentId, product, runId, totalIssues, totalPages]
	);

	return (
		<ShowDetailsCellFormatter
			infoTitle={infoTitle}
			renderPopup={renderPopup}
			ariaLabel="Show Component Details"
		/>
	);
};
