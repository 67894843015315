import React from 'react';

import { EvLinkTypes, EvTableColumn } from '@evinced-private/ui-common';

import DashboardTableLabel from 'src/components/common/DashboardTableLabel/DashboardTableLabel';
import renderNumberCellFormatter from 'src/components/common/ev-table/cell-formatters/numberCellFormatter';
import { IFilterOptions } from 'src/helpers/DataFilterHelper';
import { formatShortMonthDatetime } from 'src/helpers/DateFormatHelper';
import {
	allOrNoFiltersSelected,
	appliedFiltersIncludeSessionFilters
} from 'src/helpers/FiltersHelper';

import EvLinkLocal from '../../../components/common/ev-link-local/EvLinkLocal';
import RoutesHelper from '../../../helpers/RoutesHelper';
import {
	MSdkTest,
	MSdkTestCustomMetadataLabels,
	MSdkTestMetadataLabels
} from '../types/MobileSdkTypes';

export const buildTableFilters = (tests: MSdkTest[]): IFilterOptions => {
	const labelsList: string[] = [];

	// Loop over all sessions and add their apps, os and versions to the relevant lists
	tests.forEach((test: MSdkTest) => {
		labelsList.push(...test.userFiltersLabels);
	});

	// Create a set from sorted-lists to remove duplicates
	const labels = new Set<string>(labelsList.sort());

	return {
		searchValue: '',
		userLabels: Array.from(labels, (labelValue) => ({ value: labelValue, label: labelValue }))
	};
};

const createListFromLabels = (
	labels: MSdkTestCustomMetadataLabels | MSdkTestMetadataLabels = {}
): string[] => {
	const labelsList: string[] = [];
	Object.keys(labels).forEach((key) => {
		labelsList.push(`${key}: ${labels[key]}`);
	});
	return labelsList;
};

/**
 * 1. filter tests by searchValue
 * 2. loop over tests and for each test check if:
 * 3. any of its "labels" are included in labels filter
 * 4. If "Select All" option or no options are selected for specific filter - do no filter
 */
export const getFilteredSDKTest = (
	tests: MSdkTest[],
	appliedFilters: IFilterOptions
): MSdkTest[] => {
	if (!appliedFilters) {
		return tests;
	}

	const { userLabels, searchValue } = appliedFilters;
	let filteredTest: MSdkTest[] = tests;
	if (searchValue?.trim()) {
		filteredTest = tests.filter((test) =>
			test.testName?.toLowerCase().includes(searchValue.toLowerCase())
		);
	}

	return filteredTest?.filter((test) => {
		return (
			allOrNoFiltersSelected(userLabels) ||
			appliedFiltersIncludeSessionFilters(userLabels, test.userFiltersLabels)
		);
	});
};

const renderLastModifiedCell = (cell: string, row: MSdkTest): string => {
	return row.createdAt ? formatShortMonthDatetime(cell, true) : 'N/A';
};

const createUserLabels = (labels: MSdkTestCustomMetadataLabels): string[] =>
	Object.values(labels).map((label) => label.toString());

const SDK_LABELS_PARAMS = ['metadata', 'customMetadata'];

const assetUserLabelsToTest = (test: MSdkTest): MSdkTest => {
	test.userLabels = [];
	test.userFiltersLabels = [];
	SDK_LABELS_PARAMS.forEach((paramType) => {
		if (test[paramType]) {
			test.userLabels = [...test.userLabels, ...createUserLabels(test[paramType])];
			test.userFiltersLabels = [
				...test.userFiltersLabels,
				...createListFromLabels(test[paramType])
			];
		}
	});
	return test;
};

export const assertUserLabelsToData = (data: MSdkTest[]): MSdkTest[] =>
	data.map((test: MSdkTest) => assetUserLabelsToTest(test));

export const getMsdkTestsColumns = (): EvTableColumn[] => {
	return [
		{
			dataField: 'testName',
			text: 'Test',
			style: { width: '170px' },
			headerStyle: { width: '170px' },
			sort: true,
			formatter: (testName: string, test: MSdkTest) => {
				return (
					<EvLinkLocal
						className="mfa-session-name"
						id={test.testRunId}
						url={RoutesHelper.getMobileSdkReportPath(test)}
						ariaLabel={`${testName} HTML report`}
						linkText={testName}
						type={EvLinkTypes.DARK}
					/>
				);
			}
		},
		{
			dataField: 'totalIssues',
			text: 'Total Issues',
			style: { width: '90px' },
			headerStyle: { width: '90px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'criticalIssues',
			text: 'Critical Issues',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'totalScans',
			text: 'Total Scans',
			style: { width: '90px' },
			headerStyle: { width: '90px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'createdAt',
			text: 'Test Date (UTC)',
			style: { width: '145px' },
			headerStyle: { width: '145px' },
			sort: true,
			formatter: renderLastModifiedCell
		},
		{
			dataField: 'userLabels',
			text: 'Labels',
			style: { width: '215px' },
			headerStyle: { width: '215px' },
			formatter: (labels: string[]) => <DashboardTableLabel labels={labels} />
		}
	];
};
