const API_URL_PATHS = {
	PROPERTIES_URL_PATH: `${process.env.API_ENDPOINT}/properties`,
	URLSETS_URL_PATH: '/url-sets',
	SCANS_URL_SUB_PATH: 'scans',
	TENANTS_URL_PATH: '/tenants',
	TENANT_OVERVIEW_URL_PATH: '/tenant-overview',
	SCAN_RESULTS_PATH: '/scan-results',
	USER_URL_PATH: '/user',
	VALIDATORS_URL_PATH: '/validators',
	USER_INVITATIONS_URL_PATH: '/user-invitations',
	TRENDS_PATH: '/trends',
	COMPARE_SCANS_PATH: '/scans/compare',
	PROPERTY_VIEWS_PATH: '/views',
	PRODUCTS_EXPORT_PATH: 'products-export',
	TENANTS_FEATURES: 'features',
	CENTRAL_CONFIG_PATH: '/values/json'
};

export default API_URL_PATHS;
