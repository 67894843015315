import React from 'react';

import ErrorLoadPageIcon from 'src/components/icons/ErrorLoadPageIcon.svg';

import { ContactUsButton } from '../../../../components/contact-us/ContactUsButton';
import { ProductType } from '../../../../interfaces/Tenant';
import {
	DASHBOARD_ERROR_MSG,
	DASHBOARD_ERROR_TITLE
} from '../../../../pages/dashboards-container/errorMessages';
import LoadErrorMsgLayout from '../../../../pages/property/LoadErrorMsgLayout';

import './WebSdkError.scss';

interface WebSDKErrorProps {
	mainTitle?: string;
	secondaryMsg?: string;
	button?: React.ReactElement;
	icon?: SVGElement;
	hideButton?: boolean;
}
export const WebSdkError: React.FC<WebSDKErrorProps> = ({
	button,
	mainTitle = DASHBOARD_ERROR_TITLE,
	secondaryMsg = DASHBOARD_ERROR_MSG,
	icon = ErrorLoadPageIcon,
	hideButton
}) => {
	return (
		<LoadErrorMsgLayout
			mainTitle={mainTitle}
			secondaryMsg={secondaryMsg}
			icon={icon}
			className="web-sdk-dashboard-error-msg"
		>
			{!hideButton ? button || <ContactUsButton product={ProductType.WEB_SDK} /> : null}
		</LoadErrorMsgLayout>
	);
};
