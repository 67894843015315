import { ScreenshotBoundingBox } from '@evinced-private/ui-common';

import { IComponentSampleIssue } from 'src/types/ComponentSampleIssue';

import { WfaComponentSampleIssueQuery } from '../WfaComponentSampleIssue.generated';

export const transformWfaComponentSampleIssueResults = (
	componentId: string,
	rawResults: WfaComponentSampleIssueQuery
): IComponentSampleIssue => {
	const issue = rawResults.issues?.[0];

	if (!issue) {
		return null;
	}

	// if the issue has multiple elements, we need to find the one
	// that corresponds the component that we're sampling the issue for
	const elementIndex = issue.elements__component_id.findIndex(
		(elementComponentId) => elementComponentId === componentId
	);

	if (elementIndex < 0) {
		return null;
	}

	const selector = issue.elements__selector[elementIndex];
	const url = issue.elements__page_url[elementIndex];
	const domSnippet = issue.elements__dom_snippet[elementIndex];
	const boundingBox: ScreenshotBoundingBox = {
		x: issue.elements__bounding_box_left[elementIndex],
		y: issue.elements__bounding_box_top[elementIndex],
		width: issue.elements__bounding_box_width[elementIndex],
		height: issue.elements__bounding_box_height[elementIndex]
	};

	return {
		type: issue.type_name,
		severity: issue.severity_name,
		screenshotDetails: {
			boundingBoxes: [boundingBox],
			pageScreenshotUrl: issue.screenshot
		},
		selector,
		url,
		domSnippet
	};
};
