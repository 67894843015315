import React, { FC } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import * as H from 'history';

import MfaRouter from './apps/mfa/router/MfaRouter';
import MobileSdkRouter from './apps/mobile-sdk/router/MobileSdkRouter';
import WebSdkRouter from './apps/web-sdks/router/WebSdkRouter';
import PrivateRoute from './components/auth/PrivateRoute';
import AuthenticationChecker from './components/authentication-checker/AuthenticationChecker';
import { MAIN_TITLE } from './components/common/ev-main-title/EvMainTitle';
import DashboardHeader from './components/header/DashboardHeader';
import DeviceHelper from './helpers/DeviceHelper';
import RoutesHelper from './helpers/RoutesHelper';
import AuthToken from './pages/authentication/AuthToken';
import LoginPage from './pages/authentication/LoginPage';
import SignupPage from './pages/authentication/SignupPage';
import AzureAuthenticationPage from './pages/azure-authentication-page/AzureAuthenticationPage';
import { DashboardContainerHome } from './pages/dashboards-container/products/DashboardContainerHome';
import { DashboardContainerWFA } from './pages/dashboards-container/products/DashboardContainerWFA';
import InvitationRedirectionPage from './pages/invitation-redirection/InvitationRedirectionPage';
import JiraAuthenticationPage from './pages/jira-authentication-page/JiraAuthenticationPage';
import NotFound404Page from './pages/not-found-404-page/NotFound404Page';
import NotSupportedScreenSizePage from './pages/not-supported-screen-size/NotSupportedScreenSizePage';
import Unauthorized403Page from './pages/unauthorized-403-page/Unauthorized403Page';
import { WfaSingleSessionRouter } from './pages/wfa-dashboard/single-session-page/WfaSingleSessionRouter';
import { AppProvider } from './providers/AppProvider';

import './App.scss';

type IApp = {
	history: H.History;
};

const App: FC<IApp> = ({ history }) => {
	if (!DeviceHelper.isDeviceSupported()) {
		return <NotSupportedScreenSizePage />;
	}

	const isAuthenticationRequired = (pathname: string): boolean => {
		const unauthenticatedRoutes = [
			RoutesHelper.getSignupPath(),
			RoutesHelper.getConnectPath(),
			RoutesHelper.getLoginPath(),
			RoutesHelper.getJiraAuthenticationPath(),
			RoutesHelper.getAzureAuthenticationPath(),
			'/screenshots'
		];
		const isUnauthenticatedPage = unauthenticatedRoutes.some((path) => {
			return pathname.startsWith(path);
		});
		return !isUnauthenticatedPage;
	};

	return (
		<AppProvider>
			<Router history={history}>
				<div className="app new-app">
					<Route
						render={({ location }) => {
							return isAuthenticationRequired(location.pathname) ? <AuthenticationChecker /> : null;
						}}
					/>

					{/* Need to use a Route for the Header to have access
			to the path variables, otherwise useParams don't work.
			Note that the order of the items in the path list matters */}
					<Route
						path={[
							'/mfa/report/:sessionId',
							'/mobile-sdk/report/:testId',
							'/web-sdk/test/:runId/issue/:issueId',
							'/web-sdk/test/:runId',
							'/'
						]}
						render={(props) => {
							// don't show the header on unauthenticated screens
							const isAuthRequired = isAuthenticationRequired(props.location.pathname);
							document.title = 'Evinced Platform';

							if (isAuthRequired) {
								return <DashboardHeader isAuthenticatedPage />;
							}
							return null;
						}}
					/>
					<Route exact path={RoutesHelper.getConnectPath()} component={InvitationRedirectionPage} />
					<main id="main-section" className="main-section" aria-labelledby={MAIN_TITLE}>
						<Switch>
							{/* Signup related */}
							<Route exact path={RoutesHelper.getLoginPath()} render={() => <LoginPage />} />
							<Route exact path={RoutesHelper.getSignupPath()} render={() => <SignupPage />} />
							<Route exact path={RoutesHelper.getLoginSuccessPath()} render={() => <AuthToken />} />

							{/* JIRA Azure related */}
							<Route
								exact
								path={RoutesHelper.getJiraAuthenticationPath()}
								component={JiraAuthenticationPage}
							/>
							<Route
								exact
								path={RoutesHelper.getAzureAuthenticationPath()}
								component={AzureAuthenticationPage}
							/>

							{/* Inner apps */}
							<PrivateRoute
								exact
								path={RoutesHelper.getUnauthorized403Page()}
								component={Unauthorized403Page}
							/>
							<PrivateRoute
								exact
								path={RoutesHelper.getWfaDashboardPath()}
								component={DashboardContainerWFA}
							/>
							<PrivateRoute
								path={`${RoutesHelper.getWfaSessionBasePath()}/:sessionId`}
								component={WfaSingleSessionRouter}
							/>
							<PrivateRoute path={RoutesHelper.getMfaDashboardPath()} component={MfaRouter} />
							<PrivateRoute
								path={RoutesHelper.getMobileSdkDashboardPath()}
								component={MobileSdkRouter}
							/>
							<PrivateRoute path={RoutesHelper.getWebSdkDashboardPath()} component={WebSdkRouter} />
							<PrivateRoute
								exact
								path={RoutesHelper.getHomepagePath()}
								component={DashboardContainerHome}
							/>
							<PrivateRoute
								exact
								path={RoutesHelper.getNotFound404Page()}
								component={NotFound404Page}
							/>
							{/* Error handling */}
							<Route component={NotFound404Page} />
						</Switch>
					</main>
				</div>
			</Router>
		</AppProvider>
	);
};

export default App;
