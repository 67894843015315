import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { WFA_SESSION_COMPONENTS } from 'src/providers/reactQueryProvider/QueryKeys';
import { getWfaSessionComponents } from 'src/services/wfa-dashboard/WfaDashboardService';
import { TWfaSessionComponentsResult } from 'src/services/wfa-dashboard/WfaDashboardService.types';
import { ITableQueryParams } from 'src/types/TableQueryTypes';

import { IUseTableQueryParams } from '../WfaDashboardTypes';

interface IUseWfaSessionComponentsQuery extends IUseTableQueryParams {
	sessionId: string;
}

export const useWfaSessionComponentsQuery = ({
	sessionId,
	page,
	filters,
	pageSize,
	sort
}: IUseWfaSessionComponentsQuery): UseQueryResult<TWfaSessionComponentsResult> => {
	const componentsQuery = useQuery(
		[WFA_SESSION_COMPONENTS, sessionId, filters, sort, page, pageSize],
		async (): Promise<TWfaSessionComponentsResult> =>
			getWfaSessionComponents({
				sessionId,
				filterOptions: filters as ITableQueryParams['filterOptions'],
				sort,
				page,
				pageSize
			}),
		{
			keepPreviousData: true
		}
	);

	return componentsQuery;
};
