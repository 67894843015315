import React, { FC } from 'react';

import classNames from 'classnames';

import { EvSection, EvSpinner, EvTitle } from '@evinced-private/ui-common';

import SummaryPane from 'src/components/common/summary-pane/SummaryPane';
import { SummaryItem } from 'src/interfaces/SummaryItem';

import {
	WFA_DASHBOARD_PAGE_USAGE_SECTION_ID,
	WFA_DASHBOARD_PAGE_USAGE_TITLE_ID
} from '../../../WfaDashboardAutomationIds';

import './WfaSessionsUsage.scss';

interface IWfaDashboardUsageSectionProps {
	data: SummaryItem[];
	isLoading: boolean;
	selectedPeriodLabel?: string;
}

const getUsageDataTooltip = (selectedPeriodLabel: string): string =>
	`Usage within the ${selectedPeriodLabel.toLowerCase()} compared to the previous period (if applicable).`;

export const WfaSessionsUsage: FC<IWfaDashboardUsageSectionProps> = ({
	data,
	isLoading,
	selectedPeriodLabel = 'All Sessions'
}) => {
	return (
		<EvSection
			ariaLabel="Last month usage"
			skipLinkId="usage-section"
			className={classNames('dashboard-section', 'wfa-sessions-usage')}
			ariaLive="polite"
			dataAutomationId={WFA_DASHBOARD_PAGE_USAGE_SECTION_ID}
		>
			<EvTitle
				titleText="Usage"
				headingLevel={2}
				className="page-heading"
				dataAutomationId={WFA_DASHBOARD_PAGE_USAGE_TITLE_ID}
				MoreInfoContent={
					// MoreInfoContent is a React Element that doesn't accept string,
					// Copied this solution from mfa
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<>{getUsageDataTooltip(selectedPeriodLabel)}</>
				}
			/>
			{isLoading ? (
				<EvSpinner />
			) : (
				<SummaryPane
					items={data}
					shouldHaveSkipLink={false}
					className="base-summary-pane"
					ariaLabel="Last month usage summary"
				/>
			)}
		</EvSection>
	);
};
