import { OptionType } from '@evinced-private/ui-common';

import { SummaryItem } from 'src/interfaces/SummaryItem';

import { transformUsageDataResults as transformUsageDataResultsGeneric } from '../../../../../helpers/GQLRequestsHelper/QueryResultsTransformHelper';
import {
	SCANS_METRIC_LABEL,
	SESSIONS_METRIC_LABEL,
	URLS_METRIC_LABEL,
	USERS_METRIC_LABEL } from '../../../../../pages/wfa-dashboard/WfaDashboardConsts';
import { WfaUsageDataQuery } from '../WfaUsageData.generated';

export const transformWfaUsageDataResults = (
	rawResults: WfaUsageDataQuery,
	testPeriod?: OptionType
): SummaryItem[] => {
	const lastNDaysResults = rawResults?.last_n_days?.[0];
	const last2NDaysResults = rawResults?.last_2n_days?.[0];

	const lastPeriodUsersCount = lastNDaysResults?.user_name_group_uniq_array?.length ?? 0;
	const twoPeriodsUsersCount = last2NDaysResults?.user_name_group_uniq_array?.length ?? 0;

	const lastPeriodSessionsCount = lastNDaysResults?.run_id_count ?? 0;
	const twoPeriodsSessionsCount = last2NDaysResults?.run_id_count ?? 0;

	const lastPeriodScansCount = lastNDaysResults?.total_pages_sum ?? 0;
	const twoPeriodsScansCount = last2NDaysResults?.total_pages_sum ?? 0;

	const lastPeriodUrlsCount = rawResults.last_n_days_urls?.[0]?.url_group_uniq_array?.length ?? 0;
	const twoPeriodsUrlsCount = rawResults.last_2n_days_urls?.[0]?.url_group_uniq_array?.length ?? 0;

	return [
		transformUsageDataResultsGeneric(
			lastPeriodUsersCount,
			twoPeriodsUsersCount,
			USERS_METRIC_LABEL,
			testPeriod
		),
		transformUsageDataResultsGeneric(
			lastPeriodSessionsCount,
			twoPeriodsSessionsCount,
			SESSIONS_METRIC_LABEL,
			testPeriod
		),
		transformUsageDataResultsGeneric(
			lastPeriodScansCount,
			twoPeriodsScansCount,
			SCANS_METRIC_LABEL,
			testPeriod
		),
		transformUsageDataResultsGeneric(
			lastPeriodUrlsCount,
			twoPeriodsUrlsCount,
			URLS_METRIC_LABEL,
			testPeriod
		)
	];
};
