import React from 'react';
import ReactDOM from 'react-dom';

import analytics from './services/analytics/AnalyticsService';
import hubspotService from './services/analytics/HubspotService';
import AppContainer from './AppContainer';

import './index.css';

// Init analytics
analytics.initAnalytics();

// Init hubspot
hubspotService.initHubspotClient();

console.log(`Current UI Version is: ${UI_VERSION}`);

ReactDOM.render(<AppContainer />, document.getElementById('root'));
