import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { OptionType } from '@evinced-private/ui-common';

import { WFA_USER_OPTIONS } from 'src/providers/reactQueryProvider/QueryKeys';
import { getWfaUserOptions } from 'src/services/wfa-dashboard/WfaDashboardService';

export const useWfaUserOptionsQuery = (): UseQueryResult<OptionType[]> => {
	const sessionDetailsQuery = useQuery([WFA_USER_OPTIONS], () => getWfaUserOptions());

	return sessionDetailsQuery;
};
