// This file was copied from site scanner. Planned to move it to ui-common
import React, { FC, useMemo, useState } from 'react';

import classNames from 'classnames';

import { EvErrorCard, TITLE_MODES } from '@evinced-private/ui-common';

import { DASHBOARD_ERROR_MSG } from 'src/pages/dashboards-container/errorMessages';
import TogglesService, { DevelopmentToggles } from 'src/services/TogglesService';

import WebSdkDetailsBlock from '../../../apps/web-sdks/components/WebSdkDetailsBlock';
import { ProductType } from '../../../interfaces/Tenant';
import { WfaDetailsBlock } from '../../wfa/WfaDetailsBlock';
import PlatformPopup from '../platform-popup/PlatformPopup';

import './DetailsPopup.scss';

interface IDetailsPopupProps {
	product: ProductType;
	runId: string;
	issueId: string;
	issueType?: string;
	onClose?: () => void;
}

const enableAzureIntegration = TogglesService.getToggle(
	DevelopmentToggles.ENABLE_AZURE_INTEGRATION
);

const DetailsPopup: FC<IDetailsPopupProps> = ({
	product,
	runId,
	issueId,
	onClose,
	issueType = ''
}: IDetailsPopupProps) => {
	const [error, setError] = useState(null);
	const classes: string = classNames(error ? 'error-popup' : 'details-popup', {
		'azure-popup': enableAzureIntegration
	});

	const renderDetailsBlock = useMemo((): JSX.Element => {
		if (product === ProductType.WEB_SDK) {
			return <WebSdkDetailsBlock runId={runId} issueId={issueId} setError={setError} />;
		}
		return <WfaDetailsBlock runId={runId} issueId={issueId} />;
	}, [runId, issueId, product]);

	return (
		<div>
			<PlatformPopup
				isOpen
				title={error ? 'Error' : issueType}
				className={classes}
				isBgTransparent
				isCentered={!!error}
				isControlled
				onClose={() => {
					setError?.(null);
					onClose?.();
				}}
				popupWidth={1050}
				titleMode={TITLE_MODES.MAIN}
			>
				{error ? (
					<EvErrorCard title="Error" contactUsMessage={DASHBOARD_ERROR_MSG} />
				) : (
					renderDetailsBlock
				)}
			</PlatformPopup>
		</div>
	);
};

export default DetailsPopup;
