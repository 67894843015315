/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { EvLinkTypes, EvTableColumn } from '@evinced-private/ui-common';

import EvLinkLocal from 'src/components/common/ev-link-local/EvLinkLocal';
import renderNumberCellFormatter from 'src/components/common/ev-table/cell-formatters/numberCellFormatter';
import { IFilterOptions } from 'src/helpers/DataFilterHelper';
import { formatShortMonthDatetime } from 'src/helpers/DateFormatHelper';
import {
	allOrNoFiltersSelected,
	appliedFiltersIncludeSessionFilters
} from 'src/helpers/FiltersHelper';
import RoutesHelper from 'src/helpers/RoutesHelper';

import DashboardTableLabel from '../../../components/common/DashboardTableLabel/DashboardTableLabel';
import { MfaSession } from '../types/mfa-types';

/**
 * 1. filter sessions by searchValue
 * 2. loop over sessions and for each session check if:
 * 3. any of its "apps"/"os"/"versions" are included in apps/os/versions filter
 * 4. If "Select All" option or no options are selected for specific filter - do no filter
 */
export const getFilteredMFASessions = (
	sessions: MfaSession[],
	appliedFilters: IFilterOptions
): MfaSession[] => {
	if (!appliedFilters) {
		return sessions;
	}

	const { os, apps, versions, searchValue } = appliedFilters;
	let filteredSession: MfaSession[] = sessions;
	if (searchValue?.trim()) {
		filteredSession = sessions.filter((session) =>
			session.searchStr?.toLowerCase().includes(searchValue.toLowerCase())
		);
	}

	return filteredSession?.filter((session) => {
		return (
			(allOrNoFiltersSelected(os) || appliedFiltersIncludeSessionFilters(os, session.os)) &&
			(allOrNoFiltersSelected(apps) || appliedFiltersIncludeSessionFilters(apps, session.apps)) &&
			(allOrNoFiltersSelected(versions) ||
				appliedFiltersIncludeSessionFilters(versions, session.versions))
		);
	});
};

const renderLastModifiedCell = (cell: string, row: MfaSession): string => {
	return row.lastModified ? formatShortMonthDatetime(cell, true) : 'N/A';
};

export const getMfaSessionsColumns = (withBestPractices = false): EvTableColumn[] => {
	return [
		{
			dataField: 'sessionName',
			text: 'Session',
			style: { width: '170px' },
			headerStyle: { width: '170px' },
			sort: true,
			formatter: (sessionName: string, session: MfaSession) => {
				return (
					<EvLinkLocal
						className="mfa-session-name"
						id={sessionName}
						url={RoutesHelper.getMfaSessionReportPath(session)}
						ariaLabel={`${sessionName} HTML report`}
						linkText={sessionName}
						type={EvLinkTypes.DARK}
					/>
				);
			}
		},
		{
			dataField: withBestPractices ? 'totalIssues' : 'totalIssuesWithoutBestPractices',
			text: 'Total Issues',
			style: { width: '90px' },
			headerStyle: { width: '90px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'criticalIssues',
			text: 'Critical Issues',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,

			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'totalScans',
			text: 'Total Scans',
			style: { width: '90px' },
			headerStyle: { width: '90px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'lastModified',
			text: 'Last Modified (UTC)',
			style: { width: '145px' },
			headerStyle: { width: '145px' },
			sort: true,
			formatter: renderLastModifiedCell
		},

		{
			dataField: 'owner',
			text: 'Owner',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true
		},
		{
			dataField: 'labels',
			text: 'Labels',
			style: { width: '135px' },
			headerStyle: { width: '135px' },
			formatter: (labels: string[]) => <DashboardTableLabel labels={labels} />
		}
	];
};
