import { EVINCED_SEVERITIES } from '@evinced-private/ui-common';

const severitiesOrder = [
	EVINCED_SEVERITIES.CRITICAL,
	EVINCED_SEVERITIES.SERIOUS,
	EVINCED_SEVERITIES.MODERATE,
	EVINCED_SEVERITIES.MINOR,
	EVINCED_SEVERITIES.NEEDS_REVIEW,
	EVINCED_SEVERITIES.BEST_PRACTICE
];

export const sortSeverities = (severities: string[]): string[] => {
	const newSeveritites = [];
	// add all known severities by order
	severitiesOrder.forEach((severity) => {
		if (severities.includes(severity)) {
			newSeveritites.push(severity);
		}
	});
	// add all unknown severities in the end
	severities.forEach((severity) => {
		if (!newSeveritites.includes(severity)) {
			newSeveritites.push(severity);
		}
	});
	return newSeveritites;
};
