import { IEvBreadcrumb } from 'src/components/header-navigation/EvBreadcrumbs';
import RoutesHelper from 'src/helpers/RoutesHelper';

import { MAIN_PAGE_NAV_LABEL } from './WfaDashboardConsts';

export const getWfaBreadcrumbRoutes = (sessionId: string, sessionName: string): IEvBreadcrumb[] => [
	{
		id: MAIN_PAGE_NAV_LABEL,
		url: RoutesHelper.getWfaDashboardPath(),
		title: MAIN_PAGE_NAV_LABEL
	},
	{
		id: sessionId,
		url: '',
		title: sessionName
	}
];
