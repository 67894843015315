import { returnNonNull } from '../../../../../helpers/ArraysHelper';
import { IWfaSessionRow, TWfaSessionsResult } from '../../../WfaDashboardService.types';
import { WfaSessionsQuery } from '../WfaSessions.generated';

export const transformSessionsResults = (rawResults: WfaSessionsQuery): TWfaSessionsResult => {
	const rows: IWfaSessionRow[] =
		rawResults.wfa_session_runs?.filter(returnNonNull).map((session) => ({
			sessionId: session.run_id,
			totalIssues: session.total_issues,
			criticalIssues: session.critical_issues,
			runTime: session.run_time,
			sessionName: session.session_name,
			createdBy: session.user_name
		})) ?? [];

	let total = 0;
	if (rawResults.total?.length) {
		total = rawResults.total[0]?.run_id_count ?? 0;
	}

	let unfilteredTotal = 0;
	if (rawResults.unfiltered_total?.length) {
		unfilteredTotal = rawResults.unfiltered_total[0]?.run_id_count ?? 0;
	}

	return {
		rows,
		total,
		unfilteredTotal
	};
};
