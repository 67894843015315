import { OptionType } from '@evinced-private/ui-common';

import { SELECT_ALL_VALUE } from 'src/consts/app_consts';

export const appliedFiltersIncludeSessionFilters = (
	appliedfilters: OptionType[],
	rawValues: string[]
): boolean => {
	return appliedfilters.some((filter) => rawValues.includes(filter.label));
};

export const allOrNoFiltersSelected = (appliedfilters: OptionType[]): boolean => {
	return (
		!appliedfilters?.length || appliedfilters.some((filter) => filter.value === SELECT_ALL_VALUE)
	);
};
