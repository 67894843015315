import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';

import { ITenant, ProductType, TenantProduct } from '../../interfaces/Tenant';
import { IUser, ROLE_TYPE_ENTITY } from '../../interfaces/User';
import AuthenticationService from '../../services/AuthenticationService';
import { getUserTenant } from '../../services/TenantsService';
import { getUserMetadata } from '../../services/UserService';

interface IUserTenant {
	user: IUser;
	tenant: ITenant;
	hasAdminPermissions: () => boolean;
	updateUserTenant: (force?: boolean) => Promise<void>;
	getProductIdByType: (type: ProductType) => string;
}

const UserTenantContext = createContext<IUserTenant>(null);

const UserTenantProvider: FC = ({ children }) => {
	const [user, setUser] = useState<IUser>(null);
	const [tenant, setTenant] = useState<ITenant>(null);

	const getProductIdByType = useCallback(
		(productType: ProductType): string => {
			const product = tenant?.products?.find(
				(product: TenantProduct) => product.type === productType
			);
			return product?.id;
		},
		[tenant]
	);

	const updateUserTenant = useCallback(
		async (force = false): Promise<void> => {
			if (user && tenant && !force) {
				// if user and tenant are already set, no need to fetch them again
				return;
			}
			if (AuthenticationService.isLoggedIn()) {
				if (!tenant || force) {
					const currentTenant = await getUserTenant();
					if (currentTenant) {
						setTenant(currentTenant);
					}
				}

				if (!user || force) {
					const currentUser = await getUserMetadata();
					if (currentUser) {
						setUser(currentUser);
					}
				}
			}
		},
		[user, tenant]
	);

	const hasAdminPermissions = useCallback(
		(): boolean => user && user.role?.type === ROLE_TYPE_ENTITY.TENANT_ADMIN,
		[user]
	);

	useEffect(() => {
		updateUserTenant();
	}, [updateUserTenant]);

	const value = React.useMemo(
		() => ({ user, tenant, hasAdminPermissions, updateUserTenant, getProductIdByType }),
		[user, tenant, hasAdminPermissions, updateUserTenant, getProductIdByType]
	);
	return <UserTenantContext.Provider value={value}>{children}</UserTenantContext.Provider>;
};

const useUserTenant = (): IUserTenant => useContext(UserTenantContext);

export { UserTenantProvider, useUserTenant };
