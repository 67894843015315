import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';

import HtmlReport from 'src/apps/mfa/pages/html-report/HtmlReport';

import RoutesHelper from '../../../helpers/RoutesHelper';
import { ProductType } from '../../../interfaces/Tenant';
import NotFound404Page from '../../../pages/not-found-404-page/NotFound404Page';
import Unauthorized403Page from '../../../pages/unauthorized-403-page/Unauthorized403Page';
import { useUserTenant } from '../../../providers/userTenantProvider/UserTenantProvider';
import { hasLicenseForProduct } from '../../../services/CommonDashboardService';
import { getMsdkReportIdParam } from '../helpers/MSdkDashboardHelper';
import { DashboardContainerMobileSDK } from '../pages/dashboard-container/DashboardContainerMobileSdk';

const productType = ProductType.MOBILE_FLOW_ANALYZER;

const MobileSdkRouter: FC = () => {
	const { tenant } = useUserTenant();
	const hasLicense = hasLicenseForProduct(tenant, productType);

	const history = useHistory();
	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={RoutesHelper.getMobileSdkDashboardPath()}
					component={DashboardContainerMobileSDK}
				/>
				<Route
					exact
					path={RoutesHelper.getMobileSdkTestReportPathname()}
					render={({ location }) => {
						return hasLicense ? (
							<HtmlReport sessionId={getMsdkReportIdParam(location)} msdkReport />
						) : (
							<Unauthorized403Page />
						);
					}}
				/>
				<Route
					render={() => <NotFound404Page redirectTo={RoutesHelper.getMobileSdkDashboardPath()} />}
				/>
			</Switch>
		</Router>
	);
};

export default MobileSdkRouter;
