import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';

import {
	BUTTON_TYPES,
	EvButton,
	EvSpinner,
	EvTitle,
	TOAST_STATUS,
	useSkipLinks
} from '@evinced-private/ui-common';

import CopyToClipboard from 'src/components/common/copy-to-clipboard/CopyToClipboard';
import { formatShortMonthDatetime } from 'src/helpers/DateFormatHelper';
import FileHelper from 'src/helpers/FilesHelper';
import RoutesHelper from 'src/helpers/RoutesHelper';
import { ProductType } from 'src/interfaces/Tenant';
import NotFound404Page from 'src/pages/not-found-404-page/NotFound404Page';
import { useNotifications } from 'src/providers/notificationsProvider/NotificationsConsumer';
import { useUserTenant } from 'src/providers/userTenantProvider/UserTenantProvider';
import Logger from 'src/services/Logger';

import MfaDashboardService from '../../services/MfaDashboardService';

import DeleteSessionConfirmationPopup from './components/DeleteSessionConfirmationPopup/DeleteSessionConfirmationPopup';
import LabelsPanel from './components/LabelsPanel/LabelsPanel';

import './HtmlReport.scss';

interface IHtmlReportProps {
	sessionId: string;
	msdkReport?: boolean;
}
const getTestInfo = (searchParams: URLSearchParams): { testName: string; createdAt: string } => {
	const testName = searchParams.get('testName');
	const createdAt = searchParams.get('createdAt');
	return { testName, createdAt };
};

const getTestLabels = (searchParams: URLSearchParams): string => {
	return searchParams.get('labels');
};

const hideReportHeaderParam = 'hideEvincedHeader=1';
const MSDK_TEST_REPORT = 'MSDK TEST REPORT';
const MFA_SESSION_REPORT = 'MFA SESSION REPORT';

const HtmlReport: FC<IHtmlReportProps> = ({ sessionId, msdkReport = false }) => {
	const notificationsContext = useNotifications();
	const { tenant, getProductIdByType } = useUserTenant();
	const productId = msdkReport
		? getProductIdByType(ProductType.MOBILE_SDK)
		: getProductIdByType(ProductType.MOBILE_FLOW_ANALYZER);

	const [htmlReportUrl, setHtmlReportUrl] = useState<string>();
	const [error, setError] = useState<string>(null);
	const [isDownloadingHTML, setIsDownloadingHTML] = useState<boolean>(false);
	const [isDeletingSession, setIsDeletingSession] = useState<boolean>(false);

	useSkipLinks(!isDownloadingHTML);
	const searchParams = new URLSearchParams(window.location.search);
	const { testName, createdAt } = getTestInfo(searchParams);
	const testLabels = getTestLabels(searchParams)?.split(',') || null;
	const exportHtmlReport = async (): Promise<void> => {
		setIsDownloadingHTML(true);
		try {
			const fetchedHtml = await fetch(htmlReportUrl);
			const html: string = await fetchedHtml.text();
			FileHelper.createFileAndMakeBrowserDownloadIt(`${sessionId}-report.html`, html, 'text/html');
		} catch (err) {
			Logger.error('Failed to download html report', err);
			notificationsContext.toast({
				show: true,
				status: TOAST_STATUS.FAIL,
				announcement: `Failed to export HTML. ${err}`
			});
		}
		setIsDownloadingHTML(false);
	};

	const onDeletingSession = async (): Promise<void> => {
		setIsDeletingSession(true);
		try {
			await MfaDashboardService.deleteSessionById(tenant.id, productId, sessionId);
			window.location.replace(RoutesHelper.getMfaDashboardPath());
		} catch (err) {
			Logger.error(`Failed to delete session id ${sessionId}`, err);
			notificationsContext.toast({
				show: true,
				status: TOAST_STATUS.FAIL,
				announcement: 'Deletion failed. Please retry.'
			});
			setIsDeletingSession(false);
		}
	};

	useEffect(() => {
		const fetchHtmlReport = async (): Promise<void> => {
			try {
				// TODO: move to global Platform Dashboard service
				const htmlRepoortUrl = await MfaDashboardService.getHTMLReport({
					tenantId: tenant.id,
					productId,
					sessionId
				});
				setHtmlReportUrl(
					msdkReport ? `${htmlRepoortUrl}&${hideReportHeaderParam}` : htmlRepoortUrl
				);
				setError(null);
			} catch (err) {
				Logger.error('Failed to fetch html report', err);
				setError('Failed to fetch html report');
			}
		};

		fetchHtmlReport();
	}, [productId, sessionId, tenant.id, msdkReport]);

	/**
	 * This is used to add classname to main element (.main-section)
	 * In App, in order to remove the default margins when routing to
	 * This HTML report page
	 */

	useEffect(() => {
		const { body } = document;
		const mainAppElement: HTMLElement = document.getElementById('main-section');
		mainAppElement.classList.add('html-report');
		body.classList.add('html-report-body');

		// Remove class when component unmounts
		return () => {
			mainAppElement.classList.remove('html-report');
			body.removeAttribute('class');
		};
	}, []);

	if (error) {
		return (
			<NotFound404Page
				redirectTo={
					msdkReport ? RoutesHelper.getMobileSdkDashboardPath() : RoutesHelper.getHomepagePath()
				}
			/>
		);
	}

	if (isDeletingSession) {
		return <EvSpinner />;
	}

	return (
		<div className="html-report">
			{htmlReportUrl ? (
				<>
					<div className={classNames('html-report-actions-header', { 'with-title': !!testName })}>
						{testName && (
							<div className="report-header">
								<EvTitle titleText={testName} className="test-title" />
								<span className="report-subtitle">
									{`Created on ${formatShortMonthDatetime(createdAt, true)} (UTC)`}
								</span>
							</div>
						)}
						<div className="html-report-action-buttons">
							<EvButton
								disabled={isDownloadingHTML}
								title={`${isDownloadingHTML ? ' Downloading' : 'Export'} report to HTML file`}
								className="html-report-export-btn"
								type={BUTTON_TYPES.ACTION}
								onClick={exportHtmlReport}
							>
								{isDownloadingHTML ? <EvSpinner small /> : 'Export'}
							</EvButton>
							<CopyToClipboard
								textToCopy={window.location.href}
								copyButtonText="Copy Session Link"
								className="html-report-copy-btn"
							/>
							<DeleteSessionConfirmationPopup onConfirm={onDeletingSession} />
						</div>
					</div>
					{msdkReport && testLabels && <LabelsPanel labels={testLabels} />}
					<iframe
						className="html-report-iframe"
						src={htmlReportUrl}
						title={msdkReport ? MSDK_TEST_REPORT : MFA_SESSION_REPORT}
					/>
				</>
			) : (
				<EvSpinner />
			)}
		</div>
	);
};

export default HtmlReport;
