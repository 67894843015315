import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { IWebSdkTestIssuesFilterOption } from 'src/helpers/DataFilterHelper';
import { WEB_SDK_ISSUES_FILTER_OPTIONS } from 'src/providers/reactQueryProvider/QueryKeys';

import { getWebSdkTestIssuesFilterOptions } from '../services/WebSdkDashboardService';

type TWebSdkTestIssuesFilterOptionResult = Omit<IWebSdkTestIssuesFilterOption, 'searchValue'>;

export const useWebSdkIssuesFilterOptionsQuery = (
	runId: string
): UseQueryResult<TWebSdkTestIssuesFilterOptionResult> =>
	useQuery(
		[WEB_SDK_ISSUES_FILTER_OPTIONS, runId],
		async (): Promise<TWebSdkTestIssuesFilterOptionResult> =>
			getWebSdkTestIssuesFilterOptions(runId)
	);
