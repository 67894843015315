import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { WFA_SESSION_DETAILS } from 'src/providers/reactQueryProvider/QueryKeys';
import { getWfaSessionDetails } from 'src/services/wfa-dashboard/WfaDashboardService';
import { IWfaSessionDetails } from 'src/services/wfa-dashboard/WfaDashboardService.types';

export const useWfaSessionDetailsQuery = (
	sessionId: string
): UseQueryResult<IWfaSessionDetails> => {
	const sessionDetailsQuery = useQuery([WFA_SESSION_DETAILS, sessionId], () =>
		getWfaSessionDetails(sessionId)
	);

	return sessionDetailsQuery;
};
