import { useCallback } from 'react';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { REFETCH_INTERVAL } from 'src/consts/QueryConfigs';
import { IUseTableQueryParams } from 'src/pages/wfa-dashboard/WfaDashboardTypes';
import { WEB_SDK_TESTS } from 'src/providers/reactQueryProvider/QueryKeys';
import { queryClient } from 'src/providers/reactQueryProvider/ReactQueryProvider';

import { getWebSdkTests } from '../services/WebSdkDashboardService';
import { TWebSdkTestsResult } from '../types/WebSdkDashboardServiceTypes';

interface IUseWebSdkTestsQueryParams extends IUseTableQueryParams {
	showRecentTest: boolean;
	showTestPath: boolean;
}

export const useWebSdkTestsQuery = ({
	showRecentTest,
	filters,
	sort,
	page,
	pageSize,
	showTestPath
}: IUseWebSdkTestsQueryParams): [UseQueryResult<TWebSdkTestsResult>, () => void] => {
	const queryResult = useQuery(
		[WEB_SDK_TESTS, showRecentTest, filters, sort, page, pageSize],
		async (): Promise<TWebSdkTestsResult> =>
			getWebSdkTests({
				isRecentTests: showRecentTest,
				filterOptions: filters,
				sort,
				page,
				pageSize,
				isShowTestPath: showTestPath
			}),
		{
			keepPreviousData: true,
			refetchInterval: REFETCH_INTERVAL,
			staleTime: REFETCH_INTERVAL
		}
	);

	const invalidate = useCallback(
		() => queryClient.invalidateQueries({ queryKey: [WEB_SDK_TESTS], refetchType: 'none' }),
		[]
	);

	return [queryResult, invalidate];
};
