/* eslint-disable camelcase */
import { OptionType, SORT_ORDER } from '@evinced-private/ui-common';

import {
	Wsdk_Test_RunsSortFields,
	Wsdk_Test_RunsSortOrder,
	Wsdk_Test_RunsTextSearchFields
} from 'src/types/graphql.generated';

import { QueryVariablesBuilder } from '../../../../../../helpers/GQLRequestsHelper/QueryVariablesBuilder';
import TogglesService, { DevelopmentToggles } from '../../../../../../services/TogglesService';
import { IGetWebSdkTestsParams } from '../../../../types/WebSdkDashboardServiceTypes';
import {
	ENVIRONMENT_FILTER_NAME,
	FLOW_FILTER_NAME
} from '../../TestLabels/helpers/transformResults';
import { TestsQueryVariables } from '../Tests.generated';

const DAYS_AMOUNT_FILTER_PARAM_KEY = 'daysAmountFilter';
const PROPERTIES_FILTER_PARAM_KEY = 'propertiesFilter';

const enableWebSdkProperties = TogglesService.getToggle(
	DevelopmentToggles.ENABLE_WEB_SDK_PROPERTIES
);

const testsSortFieldNamesMap: Record<string, Wsdk_Test_RunsSortFields> = {
	testName: Wsdk_Test_RunsSortFields.TestName,
	runTime: Wsdk_Test_RunsSortFields.RunTime,
	criticalIssues: Wsdk_Test_RunsSortFields.CriticalIssues,
	totalIssues: Wsdk_Test_RunsSortFields.TotalIssues,
	testPath: Wsdk_Test_RunsSortFields.TestPath,
	testDisplayName: Wsdk_Test_RunsSortFields.TestName
};

const testsSortOrderMap: Record<SORT_ORDER, Wsdk_Test_RunsSortOrder> = {
	[SORT_ORDER.ASC]: Wsdk_Test_RunsSortOrder.Ascending,
	[SORT_ORDER.DESC]: Wsdk_Test_RunsSortOrder.Descending
};

export const testsSearchFields = [
	Wsdk_Test_RunsTextSearchFields.TestName,
	Wsdk_Test_RunsTextSearchFields.TotalIssues,
	Wsdk_Test_RunsTextSearchFields.CriticalIssues,
	Wsdk_Test_RunsTextSearchFields.RunTime
];

const labelsSearchFields = [
	Wsdk_Test_RunsTextSearchFields.Environments,
	Wsdk_Test_RunsTextSearchFields.Flows,
	Wsdk_Test_RunsTextSearchFields.CustomFields
];

export const buildTupleString = (name: string, value: string): string => `('${name}','${value}')`;

export const buildLabelsSearchValues = (labels: OptionType[]): string[] =>
	labels.map(({ value: filterValue }) => {
		const [labelName, labelValue] = (filterValue as string).split(': ');
		switch (labelName) {
			case ENVIRONMENT_FILTER_NAME:
			case FLOW_FILTER_NAME:
				return labelValue;
			default:
				return buildTupleString(labelName, labelValue);
		}
	});

export const buildTestsQueryVariables = ({
	pageSize,
	page,
	filterOptions,
	sort,
	isShowTestPath
}: IGetWebSdkTestsParams): TestsQueryVariables => {
	const variablesBuilder = new QueryVariablesBuilder<TestsQueryVariables>({
		searchFields: testsSearchFields,
		sortFieldsMap: testsSortFieldNamesMap,
		sortOrderMap: testsSortOrderMap
	});

	let sortFields;
	if (sort?.dataField === 'testDisplayName') {
		sortFields = isShowTestPath ? ['testPath', 'testName'] : ['testName'];
	}

	variablesBuilder
		.addPaginationParams(page, pageSize)
		.addSortParam(sort, sortFields)
		.addTextSearchParam(
			filterOptions?.searchValue ?? '',
			isShowTestPath ? [...testsSearchFields, Wsdk_Test_RunsTextSearchFields.TestPath] : undefined
		);

	if (filterOptions?.testPeriod?.length && filterOptions.testPeriod[0].value !== 'all') {
		variablesBuilder.addConditionParam(
			DAYS_AMOUNT_FILTER_PARAM_KEY,
			filterOptions.testPeriod[0].value
		);
	}

	if (filterOptions?.labels?.length) {
		variablesBuilder.addTextSearchParam(
			buildLabelsSearchValues(filterOptions.labels),
			labelsSearchFields,
			true
		);
	}
	if (enableWebSdkProperties) {
		variablesBuilder.addFilterParam(PROPERTIES_FILTER_PARAM_KEY, filterOptions?.properties);
	}

	return variablesBuilder.getVariables();
};
