export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
	[_ in K]?: never;
};
export type Incremental<T> =
	| T
	| { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	DateTime: { input: string; output: string };
};

export type ConditionBoolean = {
	value: Array<InputMaybe<Scalars['Boolean']['input']>>;
};

export type ConditionDateTime = {
	value: Array<InputMaybe<Scalars['DateTime']['input']>>;
};

export type ConditionFloat = {
	value: Array<InputMaybe<Scalars['Float']['input']>>;
};

export type ConditionId = {
	value: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type ConditionInt = {
	value: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type ConditionString = {
	value: Array<InputMaybe<Scalars['String']['input']>>;
};

export type Query = {
	__typename?: 'Query';
	issues?: Maybe<Array<Maybe<Issues>>>;
	pages?: Maybe<Array<Maybe<Pages>>>;
	wfa_session_components_runs?: Maybe<Array<Maybe<Wfa_Session_Components_Runs>>>;
	wfa_session_runs?: Maybe<Array<Maybe<Wfa_Session_Runs>>>;
	wfa_session_type_runs?: Maybe<Array<Maybe<Wfa_Session_Type_Runs>>>;
	wfa_top_critical_components?: Maybe<Array<Maybe<Wfa_Top_Critical_Components>>>;
	wsdk_latest_test_runs?: Maybe<Array<Maybe<Wsdk_Latest_Test_Runs>>>;
	wsdk_test_labels?: Maybe<Array<Maybe<Wsdk_Test_Labels>>>;
	wsdk_test_runs?: Maybe<Array<Maybe<Wsdk_Test_Runs>>>;
};

export type QueryIssuesArgs = {
	analysis_root_selector_eq?: InputMaybe<ConditionString>;
	analysis_root_selector_ge?: InputMaybe<ConditionString>;
	analysis_root_selector_gt?: InputMaybe<ConditionString>;
	analysis_root_selector_le?: InputMaybe<ConditionString>;
	analysis_root_selector_lt?: InputMaybe<ConditionString>;
	analysis_root_selector_ne?: InputMaybe<ConditionString>;
	analysis_time_eq?: InputMaybe<ConditionDateTime>;
	analysis_time_ge?: InputMaybe<ConditionDateTime>;
	analysis_time_gt?: InputMaybe<ConditionDateTime>;
	analysis_time_last_n_days?: InputMaybe<ConditionInt>;
	analysis_time_le?: InputMaybe<ConditionDateTime>;
	analysis_time_lt?: InputMaybe<ConditionDateTime>;
	analysis_time_ne?: InputMaybe<ConditionDateTime>;
	axe_additional_information_eq?: InputMaybe<ConditionString>;
	axe_additional_information_ge?: InputMaybe<ConditionString>;
	axe_additional_information_gt?: InputMaybe<ConditionString>;
	axe_additional_information_le?: InputMaybe<ConditionString>;
	axe_additional_information_lt?: InputMaybe<ConditionString>;
	axe_additional_information_ne?: InputMaybe<ConditionString>;
	canonical_url_eq?: InputMaybe<ConditionString>;
	canonical_url_ge?: InputMaybe<ConditionString>;
	canonical_url_gt?: InputMaybe<ConditionString>;
	canonical_url_le?: InputMaybe<ConditionString>;
	canonical_url_lt?: InputMaybe<ConditionString>;
	canonical_url_ne?: InputMaybe<ConditionString>;
	client_version_eq?: InputMaybe<ConditionString>;
	client_version_ge?: InputMaybe<ConditionString>;
	client_version_gt?: InputMaybe<ConditionString>;
	client_version_le?: InputMaybe<ConditionString>;
	client_version_lt?: InputMaybe<ConditionString>;
	client_version_ne?: InputMaybe<ConditionString>;
	conformance_eq_any_item?: InputMaybe<ConditionString>;
	conformance_ge_any_item?: InputMaybe<ConditionString>;
	conformance_gt_any_item?: InputMaybe<ConditionString>;
	conformance_le_any_item?: InputMaybe<ConditionString>;
	conformance_lt_any_item?: InputMaybe<ConditionString>;
	conformance_ne_any_item?: InputMaybe<ConditionString>;
	custom_fields_eq_any_item?: InputMaybe<ConditionString>;
	custom_fields_ge_any_item?: InputMaybe<ConditionString>;
	custom_fields_gt_any_item?: InputMaybe<ConditionString>;
	custom_fields_le_any_item?: InputMaybe<ConditionString>;
	custom_fields_lt_any_item?: InputMaybe<ConditionString>;
	custom_fields_ne_any_item?: InputMaybe<ConditionString>;
	deleted_eq?: InputMaybe<ConditionBoolean>;
	deleted_ge?: InputMaybe<ConditionBoolean>;
	deleted_gt?: InputMaybe<ConditionBoolean>;
	deleted_le?: InputMaybe<ConditionBoolean>;
	deleted_lt?: InputMaybe<ConditionBoolean>;
	deleted_ne?: InputMaybe<ConditionBoolean>;
	description_eq?: InputMaybe<ConditionString>;
	description_ge?: InputMaybe<ConditionString>;
	description_gt?: InputMaybe<ConditionString>;
	description_le?: InputMaybe<ConditionString>;
	description_lt?: InputMaybe<ConditionString>;
	description_ne?: InputMaybe<ConditionString>;
	detection_types_eq_any_item?: InputMaybe<ConditionString>;
	detection_types_ge_any_item?: InputMaybe<ConditionString>;
	detection_types_gt_any_item?: InputMaybe<ConditionString>;
	detection_types_le_any_item?: InputMaybe<ConditionString>;
	detection_types_lt_any_item?: InputMaybe<ConditionString>;
	detection_types_ne_any_item?: InputMaybe<ConditionString>;
	domain_eq?: InputMaybe<ConditionString>;
	domain_ge?: InputMaybe<ConditionString>;
	domain_gt?: InputMaybe<ConditionString>;
	domain_le?: InputMaybe<ConditionString>;
	domain_lt?: InputMaybe<ConditionString>;
	domain_ne?: InputMaybe<ConditionString>;
	duplicates_eq?: InputMaybe<ConditionString>;
	duplicates_ge?: InputMaybe<ConditionString>;
	duplicates_gt?: InputMaybe<ConditionString>;
	duplicates_le?: InputMaybe<ConditionString>;
	duplicates_lt?: InputMaybe<ConditionString>;
	duplicates_ne?: InputMaybe<ConditionString>;
	elements__bounding_box_bottom_eq_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_bottom_ge_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_bottom_gt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_bottom_le_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_bottom_lt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_bottom_ne_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_height_eq_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_height_ge_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_height_gt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_height_le_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_height_lt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_height_ne_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_left_eq_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_left_ge_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_left_gt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_left_le_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_left_lt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_left_ne_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_right_eq_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_right_ge_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_right_gt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_right_le_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_right_lt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_right_ne_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_top_eq_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_top_ge_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_top_gt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_top_le_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_top_lt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_top_ne_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_width_eq_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_width_ge_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_width_gt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_width_le_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_width_lt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_width_ne_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_x_eq_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_x_ge_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_x_gt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_x_le_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_x_lt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_x_ne_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_y_eq_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_y_ge_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_y_gt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_y_le_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_y_lt_any_item?: InputMaybe<ConditionFloat>;
	elements__bounding_box_y_ne_any_item?: InputMaybe<ConditionFloat>;
	elements__canonical_url_eq_any_item?: InputMaybe<ConditionString>;
	elements__canonical_url_ge_any_item?: InputMaybe<ConditionString>;
	elements__canonical_url_gt_any_item?: InputMaybe<ConditionString>;
	elements__canonical_url_le_any_item?: InputMaybe<ConditionString>;
	elements__canonical_url_lt_any_item?: InputMaybe<ConditionString>;
	elements__canonical_url_ne_any_item?: InputMaybe<ConditionString>;
	elements__component_id_eq_any_item?: InputMaybe<ConditionString>;
	elements__component_id_ge_any_item?: InputMaybe<ConditionString>;
	elements__component_id_gt_any_item?: InputMaybe<ConditionString>;
	elements__component_id_le_any_item?: InputMaybe<ConditionString>;
	elements__component_id_lt_any_item?: InputMaybe<ConditionString>;
	elements__component_id_ne_any_item?: InputMaybe<ConditionString>;
	elements__dom_snippet_eq_any_item?: InputMaybe<ConditionString>;
	elements__dom_snippet_ge_any_item?: InputMaybe<ConditionString>;
	elements__dom_snippet_gt_any_item?: InputMaybe<ConditionString>;
	elements__dom_snippet_le_any_item?: InputMaybe<ConditionString>;
	elements__dom_snippet_lt_any_item?: InputMaybe<ConditionString>;
	elements__dom_snippet_ne_any_item?: InputMaybe<ConditionString>;
	elements__id_eq_any_item?: InputMaybe<ConditionId>;
	elements__id_ge_any_item?: InputMaybe<ConditionId>;
	elements__id_gt_any_item?: InputMaybe<ConditionId>;
	elements__id_le_any_item?: InputMaybe<ConditionId>;
	elements__id_lt_any_item?: InputMaybe<ConditionId>;
	elements__id_ne_any_item?: InputMaybe<ConditionId>;
	elements__index_eq_any_item?: InputMaybe<ConditionString>;
	elements__index_ge_any_item?: InputMaybe<ConditionString>;
	elements__index_gt_any_item?: InputMaybe<ConditionString>;
	elements__index_le_any_item?: InputMaybe<ConditionString>;
	elements__index_lt_any_item?: InputMaybe<ConditionString>;
	elements__index_ne_any_item?: InputMaybe<ConditionString>;
	elements__page_title_eq_any_item?: InputMaybe<ConditionString>;
	elements__page_title_ge_any_item?: InputMaybe<ConditionString>;
	elements__page_title_gt_any_item?: InputMaybe<ConditionString>;
	elements__page_title_le_any_item?: InputMaybe<ConditionString>;
	elements__page_title_lt_any_item?: InputMaybe<ConditionString>;
	elements__page_title_ne_any_item?: InputMaybe<ConditionString>;
	elements__page_url_eq_any_item?: InputMaybe<ConditionString>;
	elements__page_url_ge_any_item?: InputMaybe<ConditionString>;
	elements__page_url_gt_any_item?: InputMaybe<ConditionString>;
	elements__page_url_le_any_item?: InputMaybe<ConditionString>;
	elements__page_url_lt_any_item?: InputMaybe<ConditionString>;
	elements__page_url_ne_any_item?: InputMaybe<ConditionString>;
	elements__selector_eq_any_item?: InputMaybe<ConditionString>;
	elements__selector_ge_any_item?: InputMaybe<ConditionString>;
	elements__selector_gt_any_item?: InputMaybe<ConditionString>;
	elements__selector_le_any_item?: InputMaybe<ConditionString>;
	elements__selector_lt_any_item?: InputMaybe<ConditionString>;
	elements__selector_ne_any_item?: InputMaybe<ConditionString>;
	engines_version_eq?: InputMaybe<ConditionString>;
	engines_version_ge?: InputMaybe<ConditionString>;
	engines_version_gt?: InputMaybe<ConditionString>;
	engines_version_le?: InputMaybe<ConditionString>;
	engines_version_lt?: InputMaybe<ConditionString>;
	engines_version_ne?: InputMaybe<ConditionString>;
	environment_eq?: InputMaybe<ConditionString>;
	environment_ge?: InputMaybe<ConditionString>;
	environment_gt?: InputMaybe<ConditionString>;
	environment_le?: InputMaybe<ConditionString>;
	environment_lt?: InputMaybe<ConditionString>;
	environment_ne?: InputMaybe<ConditionString>;
	flow_eq?: InputMaybe<ConditionString>;
	flow_ge?: InputMaybe<ConditionString>;
	flow_gt?: InputMaybe<ConditionString>;
	flow_le?: InputMaybe<ConditionString>;
	flow_lt?: InputMaybe<ConditionString>;
	flow_ne?: InputMaybe<ConditionString>;
	group?: InputMaybe<Array<InputMaybe<IssuesGroup>>>;
	ingestion_time_eq?: InputMaybe<ConditionDateTime>;
	ingestion_time_ge?: InputMaybe<ConditionDateTime>;
	ingestion_time_gt?: InputMaybe<ConditionDateTime>;
	ingestion_time_last_n_days?: InputMaybe<ConditionInt>;
	ingestion_time_le?: InputMaybe<ConditionDateTime>;
	ingestion_time_lt?: InputMaybe<ConditionDateTime>;
	ingestion_time_ne?: InputMaybe<ConditionDateTime>;
	issue_id_eq?: InputMaybe<ConditionId>;
	issue_id_ge?: InputMaybe<ConditionId>;
	issue_id_gt?: InputMaybe<ConditionId>;
	issue_id_le?: InputMaybe<ConditionId>;
	issue_id_lt?: InputMaybe<ConditionId>;
	issue_id_ne?: InputMaybe<ConditionId>;
	issue_index_eq?: InputMaybe<ConditionString>;
	issue_index_ge?: InputMaybe<ConditionString>;
	issue_index_gt?: InputMaybe<ConditionString>;
	issue_index_le?: InputMaybe<ConditionString>;
	issue_index_lt?: InputMaybe<ConditionString>;
	issue_index_ne?: InputMaybe<ConditionString>;
	knowledge_base_link_eq?: InputMaybe<ConditionString>;
	knowledge_base_link_ge?: InputMaybe<ConditionString>;
	knowledge_base_link_gt?: InputMaybe<ConditionString>;
	knowledge_base_link_le?: InputMaybe<ConditionString>;
	knowledge_base_link_lt?: InputMaybe<ConditionString>;
	knowledge_base_link_ne?: InputMaybe<ConditionString>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	note_eq?: InputMaybe<ConditionString>;
	note_ge?: InputMaybe<ConditionString>;
	note_gt?: InputMaybe<ConditionString>;
	note_le?: InputMaybe<ConditionString>;
	note_lt?: InputMaybe<ConditionString>;
	note_ne?: InputMaybe<ConditionString>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	owner_id_eq?: InputMaybe<ConditionId>;
	owner_id_ge?: InputMaybe<ConditionId>;
	owner_id_gt?: InputMaybe<ConditionId>;
	owner_id_le?: InputMaybe<ConditionId>;
	owner_id_lt?: InputMaybe<ConditionId>;
	owner_id_ne?: InputMaybe<ConditionId>;
	owner_type_eq?: InputMaybe<ConditionString>;
	owner_type_ge?: InputMaybe<ConditionString>;
	owner_type_gt?: InputMaybe<ConditionString>;
	owner_type_le?: InputMaybe<ConditionString>;
	owner_type_lt?: InputMaybe<ConditionString>;
	owner_type_ne?: InputMaybe<ConditionString>;
	page_title_eq?: InputMaybe<ConditionString>;
	page_title_ge?: InputMaybe<ConditionString>;
	page_title_gt?: InputMaybe<ConditionString>;
	page_title_le?: InputMaybe<ConditionString>;
	page_title_lt?: InputMaybe<ConditionString>;
	page_title_ne?: InputMaybe<ConditionString>;
	product_id_eq?: InputMaybe<ConditionId>;
	product_id_ge?: InputMaybe<ConditionId>;
	product_id_gt?: InputMaybe<ConditionId>;
	product_id_le?: InputMaybe<ConditionId>;
	product_id_lt?: InputMaybe<ConditionId>;
	product_id_ne?: InputMaybe<ConditionId>;
	product_name_eq?: InputMaybe<ConditionString>;
	product_name_ge?: InputMaybe<ConditionString>;
	product_name_gt?: InputMaybe<ConditionString>;
	product_name_le?: InputMaybe<ConditionString>;
	product_name_lt?: InputMaybe<ConditionString>;
	product_name_ne?: InputMaybe<ConditionString>;
	product_version_eq?: InputMaybe<ConditionString>;
	product_version_ge?: InputMaybe<ConditionString>;
	product_version_gt?: InputMaybe<ConditionString>;
	product_version_le?: InputMaybe<ConditionString>;
	product_version_lt?: InputMaybe<ConditionString>;
	product_version_ne?: InputMaybe<ConditionString>;
	property_ids_eq_any_item?: InputMaybe<ConditionId>;
	property_ids_ge_any_item?: InputMaybe<ConditionId>;
	property_ids_gt_any_item?: InputMaybe<ConditionId>;
	property_ids_le_any_item?: InputMaybe<ConditionId>;
	property_ids_lt_any_item?: InputMaybe<ConditionId>;
	property_ids_ne_any_item?: InputMaybe<ConditionId>;
	run_id_eq?: InputMaybe<ConditionId>;
	run_id_ge?: InputMaybe<ConditionId>;
	run_id_gt?: InputMaybe<ConditionId>;
	run_id_le?: InputMaybe<ConditionId>;
	run_id_lt?: InputMaybe<ConditionId>;
	run_id_ne?: InputMaybe<ConditionId>;
	run_metadata_eq?: InputMaybe<ConditionString>;
	run_metadata_ge?: InputMaybe<ConditionString>;
	run_metadata_gt?: InputMaybe<ConditionString>;
	run_metadata_le?: InputMaybe<ConditionString>;
	run_metadata_lt?: InputMaybe<ConditionString>;
	run_metadata_ne?: InputMaybe<ConditionString>;
	run_time_eq?: InputMaybe<ConditionDateTime>;
	run_time_ge?: InputMaybe<ConditionDateTime>;
	run_time_gt?: InputMaybe<ConditionDateTime>;
	run_time_last_n_days?: InputMaybe<ConditionInt>;
	run_time_le?: InputMaybe<ConditionDateTime>;
	run_time_lt?: InputMaybe<ConditionDateTime>;
	run_time_ne?: InputMaybe<ConditionDateTime>;
	screenshot_eq?: InputMaybe<ConditionString>;
	screenshot_ge?: InputMaybe<ConditionString>;
	screenshot_gt?: InputMaybe<ConditionString>;
	screenshot_le?: InputMaybe<ConditionString>;
	screenshot_lt?: InputMaybe<ConditionString>;
	screenshot_ne?: InputMaybe<ConditionString>;
	session_name_eq?: InputMaybe<ConditionString>;
	session_name_ge?: InputMaybe<ConditionString>;
	session_name_gt?: InputMaybe<ConditionString>;
	session_name_le?: InputMaybe<ConditionString>;
	session_name_lt?: InputMaybe<ConditionString>;
	session_name_ne?: InputMaybe<ConditionString>;
	severity_id_eq?: InputMaybe<ConditionString>;
	severity_id_ge?: InputMaybe<ConditionString>;
	severity_id_gt?: InputMaybe<ConditionString>;
	severity_id_le?: InputMaybe<ConditionString>;
	severity_id_lt?: InputMaybe<ConditionString>;
	severity_id_ne?: InputMaybe<ConditionString>;
	severity_name_eq?: InputMaybe<ConditionString>;
	severity_name_ge?: InputMaybe<ConditionString>;
	severity_name_gt?: InputMaybe<ConditionString>;
	severity_name_le?: InputMaybe<ConditionString>;
	severity_name_lt?: InputMaybe<ConditionString>;
	severity_name_ne?: InputMaybe<ConditionString>;
	signature_eq?: InputMaybe<ConditionString>;
	signature_ge?: InputMaybe<ConditionString>;
	signature_gt?: InputMaybe<ConditionString>;
	signature_le?: InputMaybe<ConditionString>;
	signature_lt?: InputMaybe<ConditionString>;
	signature_ne?: InputMaybe<ConditionString>;
	sort?: InputMaybe<IssuesSort>;
	summary_eq?: InputMaybe<ConditionString>;
	summary_ge?: InputMaybe<ConditionString>;
	summary_gt?: InputMaybe<ConditionString>;
	summary_le?: InputMaybe<ConditionString>;
	summary_lt?: InputMaybe<ConditionString>;
	summary_ne?: InputMaybe<ConditionString>;
	tags__description_eq_any_item?: InputMaybe<ConditionString>;
	tags__description_ge_any_item?: InputMaybe<ConditionString>;
	tags__description_gt_any_item?: InputMaybe<ConditionString>;
	tags__description_le_any_item?: InputMaybe<ConditionString>;
	tags__description_lt_any_item?: InputMaybe<ConditionString>;
	tags__description_ne_any_item?: InputMaybe<ConditionString>;
	tags__id_eq_any_item?: InputMaybe<ConditionString>;
	tags__id_ge_any_item?: InputMaybe<ConditionString>;
	tags__id_gt_any_item?: InputMaybe<ConditionString>;
	tags__id_le_any_item?: InputMaybe<ConditionString>;
	tags__id_lt_any_item?: InputMaybe<ConditionString>;
	tags__id_ne_any_item?: InputMaybe<ConditionString>;
	tags__link_eq_any_item?: InputMaybe<ConditionString>;
	tags__link_ge_any_item?: InputMaybe<ConditionString>;
	tags__link_gt_any_item?: InputMaybe<ConditionString>;
	tags__link_le_any_item?: InputMaybe<ConditionString>;
	tags__link_lt_any_item?: InputMaybe<ConditionString>;
	tags__link_ne_any_item?: InputMaybe<ConditionString>;
	tenant_id_eq?: InputMaybe<ConditionId>;
	tenant_id_ge?: InputMaybe<ConditionId>;
	tenant_id_gt?: InputMaybe<ConditionId>;
	tenant_id_le?: InputMaybe<ConditionId>;
	tenant_id_lt?: InputMaybe<ConditionId>;
	tenant_id_ne?: InputMaybe<ConditionId>;
	test_id_eq?: InputMaybe<ConditionId>;
	test_id_ge?: InputMaybe<ConditionId>;
	test_id_gt?: InputMaybe<ConditionId>;
	test_id_le?: InputMaybe<ConditionId>;
	test_id_lt?: InputMaybe<ConditionId>;
	test_id_ne?: InputMaybe<ConditionId>;
	test_name_eq?: InputMaybe<ConditionString>;
	test_name_ge?: InputMaybe<ConditionString>;
	test_name_gt?: InputMaybe<ConditionString>;
	test_name_le?: InputMaybe<ConditionString>;
	test_name_lt?: InputMaybe<ConditionString>;
	test_name_ne?: InputMaybe<ConditionString>;
	test_path_eq?: InputMaybe<ConditionString>;
	test_path_ge?: InputMaybe<ConditionString>;
	test_path_gt?: InputMaybe<ConditionString>;
	test_path_le?: InputMaybe<ConditionString>;
	test_path_lt?: InputMaybe<ConditionString>;
	test_path_ne?: InputMaybe<ConditionString>;
	text_contains?: InputMaybe<IssuesSearchInsensitive>;
	text_ends_with?: InputMaybe<IssuesSearchInsensitive>;
	text_equals?: InputMaybe<IssuesSearchInsensitive>;
	text_regex_match?: InputMaybe<IssuesSearch>;
	text_starts_with?: InputMaybe<IssuesSearchInsensitive>;
	type_id_eq?: InputMaybe<ConditionString>;
	type_id_ge?: InputMaybe<ConditionString>;
	type_id_gt?: InputMaybe<ConditionString>;
	type_id_le?: InputMaybe<ConditionString>;
	type_id_lt?: InputMaybe<ConditionString>;
	type_id_ne?: InputMaybe<ConditionString>;
	type_name_eq?: InputMaybe<ConditionString>;
	type_name_ge?: InputMaybe<ConditionString>;
	type_name_gt?: InputMaybe<ConditionString>;
	type_name_le?: InputMaybe<ConditionString>;
	type_name_lt?: InputMaybe<ConditionString>;
	type_name_ne?: InputMaybe<ConditionString>;
	unique_id_eq?: InputMaybe<ConditionId>;
	unique_id_ge?: InputMaybe<ConditionId>;
	unique_id_gt?: InputMaybe<ConditionId>;
	unique_id_le?: InputMaybe<ConditionId>;
	unique_id_lt?: InputMaybe<ConditionId>;
	unique_id_ne?: InputMaybe<ConditionId>;
	update_time_eq?: InputMaybe<ConditionDateTime>;
	update_time_ge?: InputMaybe<ConditionDateTime>;
	update_time_gt?: InputMaybe<ConditionDateTime>;
	update_time_last_n_days?: InputMaybe<ConditionInt>;
	update_time_le?: InputMaybe<ConditionDateTime>;
	update_time_lt?: InputMaybe<ConditionDateTime>;
	update_time_ne?: InputMaybe<ConditionDateTime>;
	url_eq?: InputMaybe<ConditionString>;
	url_ge?: InputMaybe<ConditionString>;
	url_gt?: InputMaybe<ConditionString>;
	url_le?: InputMaybe<ConditionString>;
	url_lt?: InputMaybe<ConditionString>;
	url_ne?: InputMaybe<ConditionString>;
	user_agent_eq?: InputMaybe<ConditionString>;
	user_agent_ge?: InputMaybe<ConditionString>;
	user_agent_gt?: InputMaybe<ConditionString>;
	user_agent_le?: InputMaybe<ConditionString>;
	user_agent_lt?: InputMaybe<ConditionString>;
	user_agent_ne?: InputMaybe<ConditionString>;
	validation_id_eq?: InputMaybe<ConditionId>;
	validation_id_ge?: InputMaybe<ConditionId>;
	validation_id_gt?: InputMaybe<ConditionId>;
	validation_id_le?: InputMaybe<ConditionId>;
	validation_id_lt?: InputMaybe<ConditionId>;
	validation_id_ne?: InputMaybe<ConditionId>;
	viewport_height_eq?: InputMaybe<ConditionFloat>;
	viewport_height_ge?: InputMaybe<ConditionFloat>;
	viewport_height_gt?: InputMaybe<ConditionFloat>;
	viewport_height_le?: InputMaybe<ConditionFloat>;
	viewport_height_lt?: InputMaybe<ConditionFloat>;
	viewport_height_ne?: InputMaybe<ConditionFloat>;
	viewport_width_eq?: InputMaybe<ConditionFloat>;
	viewport_width_ge?: InputMaybe<ConditionFloat>;
	viewport_width_gt?: InputMaybe<ConditionFloat>;
	viewport_width_le?: InputMaybe<ConditionFloat>;
	viewport_width_lt?: InputMaybe<ConditionFloat>;
	viewport_width_ne?: InputMaybe<ConditionFloat>;
};

export type QueryPagesArgs = {
	analysis_root_selector_eq?: InputMaybe<ConditionString>;
	analysis_root_selector_ge?: InputMaybe<ConditionString>;
	analysis_root_selector_gt?: InputMaybe<ConditionString>;
	analysis_root_selector_le?: InputMaybe<ConditionString>;
	analysis_root_selector_lt?: InputMaybe<ConditionString>;
	analysis_root_selector_ne?: InputMaybe<ConditionString>;
	canonical_url_eq?: InputMaybe<ConditionString>;
	canonical_url_ge?: InputMaybe<ConditionString>;
	canonical_url_gt?: InputMaybe<ConditionString>;
	canonical_url_le?: InputMaybe<ConditionString>;
	canonical_url_lt?: InputMaybe<ConditionString>;
	canonical_url_ne?: InputMaybe<ConditionString>;
	client_version_eq?: InputMaybe<ConditionString>;
	client_version_ge?: InputMaybe<ConditionString>;
	client_version_gt?: InputMaybe<ConditionString>;
	client_version_le?: InputMaybe<ConditionString>;
	client_version_lt?: InputMaybe<ConditionString>;
	client_version_ne?: InputMaybe<ConditionString>;
	custom_fields_eq_any_item?: InputMaybe<ConditionString>;
	custom_fields_ge_any_item?: InputMaybe<ConditionString>;
	custom_fields_gt_any_item?: InputMaybe<ConditionString>;
	custom_fields_le_any_item?: InputMaybe<ConditionString>;
	custom_fields_lt_any_item?: InputMaybe<ConditionString>;
	custom_fields_ne_any_item?: InputMaybe<ConditionString>;
	deleted_eq?: InputMaybe<ConditionBoolean>;
	deleted_ge?: InputMaybe<ConditionBoolean>;
	deleted_gt?: InputMaybe<ConditionBoolean>;
	deleted_le?: InputMaybe<ConditionBoolean>;
	deleted_lt?: InputMaybe<ConditionBoolean>;
	deleted_ne?: InputMaybe<ConditionBoolean>;
	detection_types_eq_any_item?: InputMaybe<ConditionString>;
	detection_types_ge_any_item?: InputMaybe<ConditionString>;
	detection_types_gt_any_item?: InputMaybe<ConditionString>;
	detection_types_le_any_item?: InputMaybe<ConditionString>;
	detection_types_lt_any_item?: InputMaybe<ConditionString>;
	detection_types_ne_any_item?: InputMaybe<ConditionString>;
	domain_eq?: InputMaybe<ConditionString>;
	domain_ge?: InputMaybe<ConditionString>;
	domain_gt?: InputMaybe<ConditionString>;
	domain_le?: InputMaybe<ConditionString>;
	domain_lt?: InputMaybe<ConditionString>;
	domain_ne?: InputMaybe<ConditionString>;
	engines_version_eq?: InputMaybe<ConditionString>;
	engines_version_ge?: InputMaybe<ConditionString>;
	engines_version_gt?: InputMaybe<ConditionString>;
	engines_version_le?: InputMaybe<ConditionString>;
	engines_version_lt?: InputMaybe<ConditionString>;
	engines_version_ne?: InputMaybe<ConditionString>;
	environment_eq?: InputMaybe<ConditionString>;
	environment_ge?: InputMaybe<ConditionString>;
	environment_gt?: InputMaybe<ConditionString>;
	environment_le?: InputMaybe<ConditionString>;
	environment_lt?: InputMaybe<ConditionString>;
	environment_ne?: InputMaybe<ConditionString>;
	flow_eq?: InputMaybe<ConditionString>;
	flow_ge?: InputMaybe<ConditionString>;
	flow_gt?: InputMaybe<ConditionString>;
	flow_le?: InputMaybe<ConditionString>;
	flow_lt?: InputMaybe<ConditionString>;
	flow_ne?: InputMaybe<ConditionString>;
	group?: InputMaybe<Array<InputMaybe<PagesGroup>>>;
	ingestion_time_eq?: InputMaybe<ConditionDateTime>;
	ingestion_time_ge?: InputMaybe<ConditionDateTime>;
	ingestion_time_gt?: InputMaybe<ConditionDateTime>;
	ingestion_time_last_n_days?: InputMaybe<ConditionInt>;
	ingestion_time_le?: InputMaybe<ConditionDateTime>;
	ingestion_time_lt?: InputMaybe<ConditionDateTime>;
	ingestion_time_ne?: InputMaybe<ConditionDateTime>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	owner_id_eq?: InputMaybe<ConditionId>;
	owner_id_ge?: InputMaybe<ConditionId>;
	owner_id_gt?: InputMaybe<ConditionId>;
	owner_id_le?: InputMaybe<ConditionId>;
	owner_id_lt?: InputMaybe<ConditionId>;
	owner_id_ne?: InputMaybe<ConditionId>;
	owner_type_eq?: InputMaybe<ConditionString>;
	owner_type_ge?: InputMaybe<ConditionString>;
	owner_type_gt?: InputMaybe<ConditionString>;
	owner_type_le?: InputMaybe<ConditionString>;
	owner_type_lt?: InputMaybe<ConditionString>;
	owner_type_ne?: InputMaybe<ConditionString>;
	page_title_eq?: InputMaybe<ConditionString>;
	page_title_ge?: InputMaybe<ConditionString>;
	page_title_gt?: InputMaybe<ConditionString>;
	page_title_le?: InputMaybe<ConditionString>;
	page_title_lt?: InputMaybe<ConditionString>;
	page_title_ne?: InputMaybe<ConditionString>;
	product_id_eq?: InputMaybe<ConditionId>;
	product_id_ge?: InputMaybe<ConditionId>;
	product_id_gt?: InputMaybe<ConditionId>;
	product_id_le?: InputMaybe<ConditionId>;
	product_id_lt?: InputMaybe<ConditionId>;
	product_id_ne?: InputMaybe<ConditionId>;
	product_name_eq?: InputMaybe<ConditionString>;
	product_name_ge?: InputMaybe<ConditionString>;
	product_name_gt?: InputMaybe<ConditionString>;
	product_name_le?: InputMaybe<ConditionString>;
	product_name_lt?: InputMaybe<ConditionString>;
	product_name_ne?: InputMaybe<ConditionString>;
	product_version_eq?: InputMaybe<ConditionString>;
	product_version_ge?: InputMaybe<ConditionString>;
	product_version_gt?: InputMaybe<ConditionString>;
	product_version_le?: InputMaybe<ConditionString>;
	product_version_lt?: InputMaybe<ConditionString>;
	product_version_ne?: InputMaybe<ConditionString>;
	property_ids_eq_any_item?: InputMaybe<ConditionId>;
	property_ids_ge_any_item?: InputMaybe<ConditionId>;
	property_ids_gt_any_item?: InputMaybe<ConditionId>;
	property_ids_le_any_item?: InputMaybe<ConditionId>;
	property_ids_lt_any_item?: InputMaybe<ConditionId>;
	property_ids_ne_any_item?: InputMaybe<ConditionId>;
	run_id_eq?: InputMaybe<ConditionId>;
	run_id_ge?: InputMaybe<ConditionId>;
	run_id_gt?: InputMaybe<ConditionId>;
	run_id_le?: InputMaybe<ConditionId>;
	run_id_lt?: InputMaybe<ConditionId>;
	run_id_ne?: InputMaybe<ConditionId>;
	run_time_eq?: InputMaybe<ConditionDateTime>;
	run_time_ge?: InputMaybe<ConditionDateTime>;
	run_time_gt?: InputMaybe<ConditionDateTime>;
	run_time_last_n_days?: InputMaybe<ConditionInt>;
	run_time_le?: InputMaybe<ConditionDateTime>;
	run_time_lt?: InputMaybe<ConditionDateTime>;
	run_time_ne?: InputMaybe<ConditionDateTime>;
	session_name_eq?: InputMaybe<ConditionString>;
	session_name_ge?: InputMaybe<ConditionString>;
	session_name_gt?: InputMaybe<ConditionString>;
	session_name_le?: InputMaybe<ConditionString>;
	session_name_lt?: InputMaybe<ConditionString>;
	session_name_ne?: InputMaybe<ConditionString>;
	sort?: InputMaybe<PagesSort>;
	tenant_id_eq?: InputMaybe<ConditionId>;
	tenant_id_ge?: InputMaybe<ConditionId>;
	tenant_id_gt?: InputMaybe<ConditionId>;
	tenant_id_le?: InputMaybe<ConditionId>;
	tenant_id_lt?: InputMaybe<ConditionId>;
	tenant_id_ne?: InputMaybe<ConditionId>;
	test_id_eq?: InputMaybe<ConditionId>;
	test_id_ge?: InputMaybe<ConditionId>;
	test_id_gt?: InputMaybe<ConditionId>;
	test_id_le?: InputMaybe<ConditionId>;
	test_id_lt?: InputMaybe<ConditionId>;
	test_id_ne?: InputMaybe<ConditionId>;
	test_name_eq?: InputMaybe<ConditionString>;
	test_name_ge?: InputMaybe<ConditionString>;
	test_name_gt?: InputMaybe<ConditionString>;
	test_name_le?: InputMaybe<ConditionString>;
	test_name_lt?: InputMaybe<ConditionString>;
	test_name_ne?: InputMaybe<ConditionString>;
	test_path_eq?: InputMaybe<ConditionString>;
	test_path_ge?: InputMaybe<ConditionString>;
	test_path_gt?: InputMaybe<ConditionString>;
	test_path_le?: InputMaybe<ConditionString>;
	test_path_lt?: InputMaybe<ConditionString>;
	test_path_ne?: InputMaybe<ConditionString>;
	text_contains?: InputMaybe<PagesSearchInsensitive>;
	text_ends_with?: InputMaybe<PagesSearchInsensitive>;
	text_equals?: InputMaybe<PagesSearchInsensitive>;
	text_regex_match?: InputMaybe<PagesSearch>;
	text_starts_with?: InputMaybe<PagesSearchInsensitive>;
	update_time_eq?: InputMaybe<ConditionDateTime>;
	update_time_ge?: InputMaybe<ConditionDateTime>;
	update_time_gt?: InputMaybe<ConditionDateTime>;
	update_time_last_n_days?: InputMaybe<ConditionInt>;
	update_time_le?: InputMaybe<ConditionDateTime>;
	update_time_lt?: InputMaybe<ConditionDateTime>;
	update_time_ne?: InputMaybe<ConditionDateTime>;
	url_eq?: InputMaybe<ConditionString>;
	url_ge?: InputMaybe<ConditionString>;
	url_gt?: InputMaybe<ConditionString>;
	url_le?: InputMaybe<ConditionString>;
	url_lt?: InputMaybe<ConditionString>;
	url_ne?: InputMaybe<ConditionString>;
	user_agent_eq?: InputMaybe<ConditionString>;
	user_agent_ge?: InputMaybe<ConditionString>;
	user_agent_gt?: InputMaybe<ConditionString>;
	user_agent_le?: InputMaybe<ConditionString>;
	user_agent_lt?: InputMaybe<ConditionString>;
	user_agent_ne?: InputMaybe<ConditionString>;
	viewport_height_eq?: InputMaybe<ConditionFloat>;
	viewport_height_ge?: InputMaybe<ConditionFloat>;
	viewport_height_gt?: InputMaybe<ConditionFloat>;
	viewport_height_le?: InputMaybe<ConditionFloat>;
	viewport_height_lt?: InputMaybe<ConditionFloat>;
	viewport_height_ne?: InputMaybe<ConditionFloat>;
	viewport_width_eq?: InputMaybe<ConditionFloat>;
	viewport_width_ge?: InputMaybe<ConditionFloat>;
	viewport_width_gt?: InputMaybe<ConditionFloat>;
	viewport_width_le?: InputMaybe<ConditionFloat>;
	viewport_width_lt?: InputMaybe<ConditionFloat>;
	viewport_width_ne?: InputMaybe<ConditionFloat>;
};

export type QueryWfa_Session_Components_RunsArgs = {
	component_id_eq?: InputMaybe<ConditionString>;
	component_id_ge?: InputMaybe<ConditionString>;
	component_id_gt?: InputMaybe<ConditionString>;
	component_id_le?: InputMaybe<ConditionString>;
	component_id_lt?: InputMaybe<ConditionString>;
	component_id_ne?: InputMaybe<ConditionString>;
	conformance_eq_any_item?: InputMaybe<ConditionString>;
	conformance_ge_any_item?: InputMaybe<ConditionString>;
	conformance_gt_any_item?: InputMaybe<ConditionString>;
	conformance_le_any_item?: InputMaybe<ConditionString>;
	conformance_lt_any_item?: InputMaybe<ConditionString>;
	conformance_ne_any_item?: InputMaybe<ConditionString>;
	group?: InputMaybe<Array<InputMaybe<Wfa_Session_Components_RunsGroup>>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	run_id_eq?: InputMaybe<ConditionId>;
	run_id_ge?: InputMaybe<ConditionId>;
	run_id_gt?: InputMaybe<ConditionId>;
	run_id_le?: InputMaybe<ConditionId>;
	run_id_lt?: InputMaybe<ConditionId>;
	run_id_ne?: InputMaybe<ConditionId>;
	run_time_eq?: InputMaybe<ConditionDateTime>;
	run_time_ge?: InputMaybe<ConditionDateTime>;
	run_time_gt?: InputMaybe<ConditionDateTime>;
	run_time_last_n_days?: InputMaybe<ConditionInt>;
	run_time_le?: InputMaybe<ConditionDateTime>;
	run_time_lt?: InputMaybe<ConditionDateTime>;
	run_time_ne?: InputMaybe<ConditionDateTime>;
	severity_ids_eq_any_item?: InputMaybe<ConditionString>;
	severity_ids_ge_any_item?: InputMaybe<ConditionString>;
	severity_ids_gt_any_item?: InputMaybe<ConditionString>;
	severity_ids_le_any_item?: InputMaybe<ConditionString>;
	severity_ids_lt_any_item?: InputMaybe<ConditionString>;
	severity_ids_ne_any_item?: InputMaybe<ConditionString>;
	severity_names_eq_any_item?: InputMaybe<ConditionString>;
	severity_names_ge_any_item?: InputMaybe<ConditionString>;
	severity_names_gt_any_item?: InputMaybe<ConditionString>;
	severity_names_le_any_item?: InputMaybe<ConditionString>;
	severity_names_lt_any_item?: InputMaybe<ConditionString>;
	severity_names_ne_any_item?: InputMaybe<ConditionString>;
	sort?: InputMaybe<Wfa_Session_Components_RunsSort>;
	tenant_id_eq?: InputMaybe<ConditionId>;
	tenant_id_ge?: InputMaybe<ConditionId>;
	tenant_id_gt?: InputMaybe<ConditionId>;
	tenant_id_le?: InputMaybe<ConditionId>;
	tenant_id_lt?: InputMaybe<ConditionId>;
	tenant_id_ne?: InputMaybe<ConditionId>;
	text_contains?: InputMaybe<Wfa_Session_Components_RunsSearchInsensitive>;
	text_ends_with?: InputMaybe<Wfa_Session_Components_RunsSearchInsensitive>;
	text_equals?: InputMaybe<Wfa_Session_Components_RunsSearchInsensitive>;
	text_regex_match?: InputMaybe<Wfa_Session_Components_RunsSearch>;
	text_starts_with?: InputMaybe<Wfa_Session_Components_RunsSearchInsensitive>;
	total_issues_eq?: InputMaybe<ConditionInt>;
	total_issues_ge?: InputMaybe<ConditionInt>;
	total_issues_gt?: InputMaybe<ConditionInt>;
	total_issues_le?: InputMaybe<ConditionInt>;
	total_issues_lt?: InputMaybe<ConditionInt>;
	total_issues_ne?: InputMaybe<ConditionInt>;
	total_pages_eq?: InputMaybe<ConditionInt>;
	total_pages_ge?: InputMaybe<ConditionInt>;
	total_pages_gt?: InputMaybe<ConditionInt>;
	total_pages_le?: InputMaybe<ConditionInt>;
	total_pages_lt?: InputMaybe<ConditionInt>;
	total_pages_ne?: InputMaybe<ConditionInt>;
	type_ids_eq_any_item?: InputMaybe<ConditionString>;
	type_ids_ge_any_item?: InputMaybe<ConditionString>;
	type_ids_gt_any_item?: InputMaybe<ConditionString>;
	type_ids_le_any_item?: InputMaybe<ConditionString>;
	type_ids_lt_any_item?: InputMaybe<ConditionString>;
	type_ids_ne_any_item?: InputMaybe<ConditionString>;
	type_names_eq_any_item?: InputMaybe<ConditionString>;
	type_names_ge_any_item?: InputMaybe<ConditionString>;
	type_names_gt_any_item?: InputMaybe<ConditionString>;
	type_names_le_any_item?: InputMaybe<ConditionString>;
	type_names_lt_any_item?: InputMaybe<ConditionString>;
	type_names_ne_any_item?: InputMaybe<ConditionString>;
	urls_eq_any_item?: InputMaybe<ConditionString>;
	urls_ge_any_item?: InputMaybe<ConditionString>;
	urls_gt_any_item?: InputMaybe<ConditionString>;
	urls_le_any_item?: InputMaybe<ConditionString>;
	urls_lt_any_item?: InputMaybe<ConditionString>;
	urls_ne_any_item?: InputMaybe<ConditionString>;
};

export type QueryWfa_Session_RunsArgs = {
	analysis_root_selector_eq?: InputMaybe<ConditionString>;
	analysis_root_selector_ge?: InputMaybe<ConditionString>;
	analysis_root_selector_gt?: InputMaybe<ConditionString>;
	analysis_root_selector_le?: InputMaybe<ConditionString>;
	analysis_root_selector_lt?: InputMaybe<ConditionString>;
	analysis_root_selector_ne?: InputMaybe<ConditionString>;
	best_practice_issues_eq?: InputMaybe<ConditionInt>;
	best_practice_issues_ge?: InputMaybe<ConditionInt>;
	best_practice_issues_gt?: InputMaybe<ConditionInt>;
	best_practice_issues_le?: InputMaybe<ConditionInt>;
	best_practice_issues_lt?: InputMaybe<ConditionInt>;
	best_practice_issues_ne?: InputMaybe<ConditionInt>;
	critical_issues_eq?: InputMaybe<ConditionInt>;
	critical_issues_ge?: InputMaybe<ConditionInt>;
	critical_issues_gt?: InputMaybe<ConditionInt>;
	critical_issues_le?: InputMaybe<ConditionInt>;
	critical_issues_lt?: InputMaybe<ConditionInt>;
	critical_issues_ne?: InputMaybe<ConditionInt>;
	custom_fields_eq_any_item?: InputMaybe<ConditionString>;
	custom_fields_ge_any_item?: InputMaybe<ConditionString>;
	custom_fields_gt_any_item?: InputMaybe<ConditionString>;
	custom_fields_le_any_item?: InputMaybe<ConditionString>;
	custom_fields_lt_any_item?: InputMaybe<ConditionString>;
	custom_fields_ne_any_item?: InputMaybe<ConditionString>;
	detection_types_eq_any_item?: InputMaybe<ConditionString>;
	detection_types_ge_any_item?: InputMaybe<ConditionString>;
	detection_types_gt_any_item?: InputMaybe<ConditionString>;
	detection_types_le_any_item?: InputMaybe<ConditionString>;
	detection_types_lt_any_item?: InputMaybe<ConditionString>;
	detection_types_ne_any_item?: InputMaybe<ConditionString>;
	domain_eq?: InputMaybe<ConditionString>;
	domain_ge?: InputMaybe<ConditionString>;
	domain_gt?: InputMaybe<ConditionString>;
	domain_le?: InputMaybe<ConditionString>;
	domain_lt?: InputMaybe<ConditionString>;
	domain_ne?: InputMaybe<ConditionString>;
	environments_eq_any_item?: InputMaybe<ConditionString>;
	environments_ge_any_item?: InputMaybe<ConditionString>;
	environments_gt_any_item?: InputMaybe<ConditionString>;
	environments_le_any_item?: InputMaybe<ConditionString>;
	environments_lt_any_item?: InputMaybe<ConditionString>;
	environments_ne_any_item?: InputMaybe<ConditionString>;
	flows_eq_any_item?: InputMaybe<ConditionString>;
	flows_ge_any_item?: InputMaybe<ConditionString>;
	flows_gt_any_item?: InputMaybe<ConditionString>;
	flows_le_any_item?: InputMaybe<ConditionString>;
	flows_lt_any_item?: InputMaybe<ConditionString>;
	flows_ne_any_item?: InputMaybe<ConditionString>;
	group?: InputMaybe<Array<InputMaybe<Wfa_Session_RunsGroup>>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	minor_issues_eq?: InputMaybe<ConditionInt>;
	minor_issues_ge?: InputMaybe<ConditionInt>;
	minor_issues_gt?: InputMaybe<ConditionInt>;
	minor_issues_le?: InputMaybe<ConditionInt>;
	minor_issues_lt?: InputMaybe<ConditionInt>;
	minor_issues_ne?: InputMaybe<ConditionInt>;
	moderate_issues_eq?: InputMaybe<ConditionInt>;
	moderate_issues_ge?: InputMaybe<ConditionInt>;
	moderate_issues_gt?: InputMaybe<ConditionInt>;
	moderate_issues_le?: InputMaybe<ConditionInt>;
	moderate_issues_lt?: InputMaybe<ConditionInt>;
	moderate_issues_ne?: InputMaybe<ConditionInt>;
	needs_review_issues_eq?: InputMaybe<ConditionInt>;
	needs_review_issues_ge?: InputMaybe<ConditionInt>;
	needs_review_issues_gt?: InputMaybe<ConditionInt>;
	needs_review_issues_le?: InputMaybe<ConditionInt>;
	needs_review_issues_lt?: InputMaybe<ConditionInt>;
	needs_review_issues_ne?: InputMaybe<ConditionInt>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	property_ids_eq_any_item?: InputMaybe<ConditionId>;
	property_ids_ge_any_item?: InputMaybe<ConditionId>;
	property_ids_gt_any_item?: InputMaybe<ConditionId>;
	property_ids_le_any_item?: InputMaybe<ConditionId>;
	property_ids_lt_any_item?: InputMaybe<ConditionId>;
	property_ids_ne_any_item?: InputMaybe<ConditionId>;
	run_id_eq?: InputMaybe<ConditionId>;
	run_id_ge?: InputMaybe<ConditionId>;
	run_id_gt?: InputMaybe<ConditionId>;
	run_id_le?: InputMaybe<ConditionId>;
	run_id_lt?: InputMaybe<ConditionId>;
	run_id_ne?: InputMaybe<ConditionId>;
	run_time_eq?: InputMaybe<ConditionDateTime>;
	run_time_ge?: InputMaybe<ConditionDateTime>;
	run_time_gt?: InputMaybe<ConditionDateTime>;
	run_time_last_n_days?: InputMaybe<ConditionInt>;
	run_time_le?: InputMaybe<ConditionDateTime>;
	run_time_lt?: InputMaybe<ConditionDateTime>;
	run_time_ne?: InputMaybe<ConditionDateTime>;
	serious_issues_eq?: InputMaybe<ConditionInt>;
	serious_issues_ge?: InputMaybe<ConditionInt>;
	serious_issues_gt?: InputMaybe<ConditionInt>;
	serious_issues_le?: InputMaybe<ConditionInt>;
	serious_issues_lt?: InputMaybe<ConditionInt>;
	serious_issues_ne?: InputMaybe<ConditionInt>;
	session_name_eq?: InputMaybe<ConditionString>;
	session_name_ge?: InputMaybe<ConditionString>;
	session_name_gt?: InputMaybe<ConditionString>;
	session_name_le?: InputMaybe<ConditionString>;
	session_name_lt?: InputMaybe<ConditionString>;
	session_name_ne?: InputMaybe<ConditionString>;
	sort?: InputMaybe<Wfa_Session_RunsSort>;
	tenant_id_eq?: InputMaybe<ConditionId>;
	tenant_id_ge?: InputMaybe<ConditionId>;
	tenant_id_gt?: InputMaybe<ConditionId>;
	tenant_id_le?: InputMaybe<ConditionId>;
	tenant_id_lt?: InputMaybe<ConditionId>;
	tenant_id_ne?: InputMaybe<ConditionId>;
	text_contains?: InputMaybe<Wfa_Session_RunsSearchInsensitive>;
	text_ends_with?: InputMaybe<Wfa_Session_RunsSearchInsensitive>;
	text_equals?: InputMaybe<Wfa_Session_RunsSearchInsensitive>;
	text_regex_match?: InputMaybe<Wfa_Session_RunsSearch>;
	text_starts_with?: InputMaybe<Wfa_Session_RunsSearchInsensitive>;
	total_issues_eq?: InputMaybe<ConditionInt>;
	total_issues_ge?: InputMaybe<ConditionInt>;
	total_issues_gt?: InputMaybe<ConditionInt>;
	total_issues_le?: InputMaybe<ConditionInt>;
	total_issues_lt?: InputMaybe<ConditionInt>;
	total_issues_ne?: InputMaybe<ConditionInt>;
	total_pages_eq?: InputMaybe<ConditionInt>;
	total_pages_ge?: InputMaybe<ConditionInt>;
	total_pages_gt?: InputMaybe<ConditionInt>;
	total_pages_le?: InputMaybe<ConditionInt>;
	total_pages_lt?: InputMaybe<ConditionInt>;
	total_pages_ne?: InputMaybe<ConditionInt>;
	update_time_eq?: InputMaybe<ConditionDateTime>;
	update_time_ge?: InputMaybe<ConditionDateTime>;
	update_time_gt?: InputMaybe<ConditionDateTime>;
	update_time_last_n_days?: InputMaybe<ConditionInt>;
	update_time_le?: InputMaybe<ConditionDateTime>;
	update_time_lt?: InputMaybe<ConditionDateTime>;
	update_time_ne?: InputMaybe<ConditionDateTime>;
	user_name_eq?: InputMaybe<ConditionString>;
	user_name_ge?: InputMaybe<ConditionString>;
	user_name_gt?: InputMaybe<ConditionString>;
	user_name_le?: InputMaybe<ConditionString>;
	user_name_lt?: InputMaybe<ConditionString>;
	user_name_ne?: InputMaybe<ConditionString>;
};

export type QueryWfa_Session_Type_RunsArgs = {
	group?: InputMaybe<Array<InputMaybe<Wfa_Session_Type_RunsGroup>>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	run_id_eq?: InputMaybe<ConditionId>;
	run_id_ge?: InputMaybe<ConditionId>;
	run_id_gt?: InputMaybe<ConditionId>;
	run_id_le?: InputMaybe<ConditionId>;
	run_id_lt?: InputMaybe<ConditionId>;
	run_id_ne?: InputMaybe<ConditionId>;
	run_time_eq?: InputMaybe<ConditionDateTime>;
	run_time_ge?: InputMaybe<ConditionDateTime>;
	run_time_gt?: InputMaybe<ConditionDateTime>;
	run_time_last_n_days?: InputMaybe<ConditionInt>;
	run_time_le?: InputMaybe<ConditionDateTime>;
	run_time_lt?: InputMaybe<ConditionDateTime>;
	run_time_ne?: InputMaybe<ConditionDateTime>;
	severity_id_eq?: InputMaybe<ConditionString>;
	severity_id_ge?: InputMaybe<ConditionString>;
	severity_id_gt?: InputMaybe<ConditionString>;
	severity_id_le?: InputMaybe<ConditionString>;
	severity_id_lt?: InputMaybe<ConditionString>;
	severity_id_ne?: InputMaybe<ConditionString>;
	severity_name_eq?: InputMaybe<ConditionString>;
	severity_name_ge?: InputMaybe<ConditionString>;
	severity_name_gt?: InputMaybe<ConditionString>;
	severity_name_le?: InputMaybe<ConditionString>;
	severity_name_lt?: InputMaybe<ConditionString>;
	severity_name_ne?: InputMaybe<ConditionString>;
	sort?: InputMaybe<Wfa_Session_Type_RunsSort>;
	tenant_id_eq?: InputMaybe<ConditionId>;
	tenant_id_ge?: InputMaybe<ConditionId>;
	tenant_id_gt?: InputMaybe<ConditionId>;
	tenant_id_le?: InputMaybe<ConditionId>;
	tenant_id_lt?: InputMaybe<ConditionId>;
	tenant_id_ne?: InputMaybe<ConditionId>;
	text_contains?: InputMaybe<Wfa_Session_Type_RunsSearchInsensitive>;
	text_ends_with?: InputMaybe<Wfa_Session_Type_RunsSearchInsensitive>;
	text_equals?: InputMaybe<Wfa_Session_Type_RunsSearchInsensitive>;
	text_regex_match?: InputMaybe<Wfa_Session_Type_RunsSearch>;
	text_starts_with?: InputMaybe<Wfa_Session_Type_RunsSearchInsensitive>;
	total_issues_eq?: InputMaybe<ConditionInt>;
	total_issues_ge?: InputMaybe<ConditionInt>;
	total_issues_gt?: InputMaybe<ConditionInt>;
	total_issues_le?: InputMaybe<ConditionInt>;
	total_issues_lt?: InputMaybe<ConditionInt>;
	total_issues_ne?: InputMaybe<ConditionInt>;
	type_id_eq?: InputMaybe<ConditionString>;
	type_id_ge?: InputMaybe<ConditionString>;
	type_id_gt?: InputMaybe<ConditionString>;
	type_id_le?: InputMaybe<ConditionString>;
	type_id_lt?: InputMaybe<ConditionString>;
	type_id_ne?: InputMaybe<ConditionString>;
	type_name_eq?: InputMaybe<ConditionString>;
	type_name_ge?: InputMaybe<ConditionString>;
	type_name_gt?: InputMaybe<ConditionString>;
	type_name_le?: InputMaybe<ConditionString>;
	type_name_lt?: InputMaybe<ConditionString>;
	type_name_ne?: InputMaybe<ConditionString>;
};

export type QueryWfa_Top_Critical_ComponentsArgs = {
	critical_issues_count_eq?: InputMaybe<ConditionInt>;
	critical_issues_count_ge?: InputMaybe<ConditionInt>;
	critical_issues_count_gt?: InputMaybe<ConditionInt>;
	critical_issues_count_le?: InputMaybe<ConditionInt>;
	critical_issues_count_lt?: InputMaybe<ConditionInt>;
	critical_issues_count_ne?: InputMaybe<ConditionInt>;
	critical_percentage_eq?: InputMaybe<ConditionFloat>;
	critical_percentage_ge?: InputMaybe<ConditionFloat>;
	critical_percentage_gt?: InputMaybe<ConditionFloat>;
	critical_percentage_le?: InputMaybe<ConditionFloat>;
	critical_percentage_lt?: InputMaybe<ConditionFloat>;
	critical_percentage_ne?: InputMaybe<ConditionFloat>;
	elements__component_id_eq?: InputMaybe<ConditionString>;
	elements__component_id_ge?: InputMaybe<ConditionString>;
	elements__component_id_gt?: InputMaybe<ConditionString>;
	elements__component_id_le?: InputMaybe<ConditionString>;
	elements__component_id_lt?: InputMaybe<ConditionString>;
	elements__component_id_ne?: InputMaybe<ConditionString>;
	group?: InputMaybe<Array<InputMaybe<Wfa_Top_Critical_ComponentsGroup>>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	run_id_eq?: InputMaybe<ConditionId>;
	run_id_ge?: InputMaybe<ConditionId>;
	run_id_gt?: InputMaybe<ConditionId>;
	run_id_le?: InputMaybe<ConditionId>;
	run_id_lt?: InputMaybe<ConditionId>;
	run_id_ne?: InputMaybe<ConditionId>;
	sort?: InputMaybe<Wfa_Top_Critical_ComponentsSort>;
	tenant_id_eq?: InputMaybe<ConditionId>;
	tenant_id_ge?: InputMaybe<ConditionId>;
	tenant_id_gt?: InputMaybe<ConditionId>;
	tenant_id_le?: InputMaybe<ConditionId>;
	tenant_id_lt?: InputMaybe<ConditionId>;
	tenant_id_ne?: InputMaybe<ConditionId>;
	text_contains?: InputMaybe<Wfa_Top_Critical_ComponentsSearchInsensitive>;
	text_ends_with?: InputMaybe<Wfa_Top_Critical_ComponentsSearchInsensitive>;
	text_equals?: InputMaybe<Wfa_Top_Critical_ComponentsSearchInsensitive>;
	text_regex_match?: InputMaybe<Wfa_Top_Critical_ComponentsSearch>;
	text_starts_with?: InputMaybe<Wfa_Top_Critical_ComponentsSearchInsensitive>;
};

export type QueryWsdk_Latest_Test_RunsArgs = {
	best_practice_issues_eq?: InputMaybe<ConditionInt>;
	best_practice_issues_ge?: InputMaybe<ConditionInt>;
	best_practice_issues_gt?: InputMaybe<ConditionInt>;
	best_practice_issues_le?: InputMaybe<ConditionInt>;
	best_practice_issues_lt?: InputMaybe<ConditionInt>;
	best_practice_issues_ne?: InputMaybe<ConditionInt>;
	critical_issues_eq?: InputMaybe<ConditionInt>;
	critical_issues_ge?: InputMaybe<ConditionInt>;
	critical_issues_gt?: InputMaybe<ConditionInt>;
	critical_issues_le?: InputMaybe<ConditionInt>;
	critical_issues_lt?: InputMaybe<ConditionInt>;
	critical_issues_ne?: InputMaybe<ConditionInt>;
	custom_fields_eq_any_item?: InputMaybe<ConditionString>;
	custom_fields_ge_any_item?: InputMaybe<ConditionString>;
	custom_fields_gt_any_item?: InputMaybe<ConditionString>;
	custom_fields_le_any_item?: InputMaybe<ConditionString>;
	custom_fields_lt_any_item?: InputMaybe<ConditionString>;
	custom_fields_ne_any_item?: InputMaybe<ConditionString>;
	environments_eq_any_item?: InputMaybe<ConditionString>;
	environments_ge_any_item?: InputMaybe<ConditionString>;
	environments_gt_any_item?: InputMaybe<ConditionString>;
	environments_le_any_item?: InputMaybe<ConditionString>;
	environments_lt_any_item?: InputMaybe<ConditionString>;
	environments_ne_any_item?: InputMaybe<ConditionString>;
	flows_eq_any_item?: InputMaybe<ConditionString>;
	flows_ge_any_item?: InputMaybe<ConditionString>;
	flows_gt_any_item?: InputMaybe<ConditionString>;
	flows_le_any_item?: InputMaybe<ConditionString>;
	flows_lt_any_item?: InputMaybe<ConditionString>;
	flows_ne_any_item?: InputMaybe<ConditionString>;
	group?: InputMaybe<Array<InputMaybe<Wsdk_Latest_Test_RunsGroup>>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	minor_issues_eq?: InputMaybe<ConditionInt>;
	minor_issues_ge?: InputMaybe<ConditionInt>;
	minor_issues_gt?: InputMaybe<ConditionInt>;
	minor_issues_le?: InputMaybe<ConditionInt>;
	minor_issues_lt?: InputMaybe<ConditionInt>;
	minor_issues_ne?: InputMaybe<ConditionInt>;
	moderate_issues_eq?: InputMaybe<ConditionInt>;
	moderate_issues_ge?: InputMaybe<ConditionInt>;
	moderate_issues_gt?: InputMaybe<ConditionInt>;
	moderate_issues_le?: InputMaybe<ConditionInt>;
	moderate_issues_lt?: InputMaybe<ConditionInt>;
	moderate_issues_ne?: InputMaybe<ConditionInt>;
	needs_review_issues_eq?: InputMaybe<ConditionInt>;
	needs_review_issues_ge?: InputMaybe<ConditionInt>;
	needs_review_issues_gt?: InputMaybe<ConditionInt>;
	needs_review_issues_le?: InputMaybe<ConditionInt>;
	needs_review_issues_lt?: InputMaybe<ConditionInt>;
	needs_review_issues_ne?: InputMaybe<ConditionInt>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	property_ids_eq_any_item?: InputMaybe<ConditionId>;
	property_ids_ge_any_item?: InputMaybe<ConditionId>;
	property_ids_gt_any_item?: InputMaybe<ConditionId>;
	property_ids_le_any_item?: InputMaybe<ConditionId>;
	property_ids_lt_any_item?: InputMaybe<ConditionId>;
	property_ids_ne_any_item?: InputMaybe<ConditionId>;
	run_id_eq?: InputMaybe<ConditionId>;
	run_id_ge?: InputMaybe<ConditionId>;
	run_id_gt?: InputMaybe<ConditionId>;
	run_id_le?: InputMaybe<ConditionId>;
	run_id_lt?: InputMaybe<ConditionId>;
	run_id_ne?: InputMaybe<ConditionId>;
	run_time_eq?: InputMaybe<ConditionDateTime>;
	run_time_ge?: InputMaybe<ConditionDateTime>;
	run_time_gt?: InputMaybe<ConditionDateTime>;
	run_time_last_n_days?: InputMaybe<ConditionInt>;
	run_time_le?: InputMaybe<ConditionDateTime>;
	run_time_lt?: InputMaybe<ConditionDateTime>;
	run_time_ne?: InputMaybe<ConditionDateTime>;
	serious_issues_eq?: InputMaybe<ConditionInt>;
	serious_issues_ge?: InputMaybe<ConditionInt>;
	serious_issues_gt?: InputMaybe<ConditionInt>;
	serious_issues_le?: InputMaybe<ConditionInt>;
	serious_issues_lt?: InputMaybe<ConditionInt>;
	serious_issues_ne?: InputMaybe<ConditionInt>;
	sort?: InputMaybe<Wsdk_Latest_Test_RunsSort>;
	tenant_id_eq?: InputMaybe<ConditionId>;
	tenant_id_ge?: InputMaybe<ConditionId>;
	tenant_id_gt?: InputMaybe<ConditionId>;
	tenant_id_le?: InputMaybe<ConditionId>;
	tenant_id_lt?: InputMaybe<ConditionId>;
	tenant_id_ne?: InputMaybe<ConditionId>;
	test_name_eq?: InputMaybe<ConditionString>;
	test_name_ge?: InputMaybe<ConditionString>;
	test_name_gt?: InputMaybe<ConditionString>;
	test_name_le?: InputMaybe<ConditionString>;
	test_name_lt?: InputMaybe<ConditionString>;
	test_name_ne?: InputMaybe<ConditionString>;
	test_path_eq?: InputMaybe<ConditionString>;
	test_path_ge?: InputMaybe<ConditionString>;
	test_path_gt?: InputMaybe<ConditionString>;
	test_path_le?: InputMaybe<ConditionString>;
	test_path_lt?: InputMaybe<ConditionString>;
	test_path_ne?: InputMaybe<ConditionString>;
	text_contains?: InputMaybe<Wsdk_Latest_Test_RunsSearchInsensitive>;
	text_ends_with?: InputMaybe<Wsdk_Latest_Test_RunsSearchInsensitive>;
	text_equals?: InputMaybe<Wsdk_Latest_Test_RunsSearchInsensitive>;
	text_regex_match?: InputMaybe<Wsdk_Latest_Test_RunsSearch>;
	text_starts_with?: InputMaybe<Wsdk_Latest_Test_RunsSearchInsensitive>;
	total_issues_eq?: InputMaybe<ConditionInt>;
	total_issues_ge?: InputMaybe<ConditionInt>;
	total_issues_gt?: InputMaybe<ConditionInt>;
	total_issues_le?: InputMaybe<ConditionInt>;
	total_issues_lt?: InputMaybe<ConditionInt>;
	total_issues_ne?: InputMaybe<ConditionInt>;
	total_pages_eq?: InputMaybe<ConditionInt>;
	total_pages_ge?: InputMaybe<ConditionInt>;
	total_pages_gt?: InputMaybe<ConditionInt>;
	total_pages_le?: InputMaybe<ConditionInt>;
	total_pages_lt?: InputMaybe<ConditionInt>;
	total_pages_ne?: InputMaybe<ConditionInt>;
};

export type QueryWsdk_Test_LabelsArgs = {
	custom_fields_eq_any_item?: InputMaybe<ConditionString>;
	custom_fields_ge_any_item?: InputMaybe<ConditionString>;
	custom_fields_gt_any_item?: InputMaybe<ConditionString>;
	custom_fields_le_any_item?: InputMaybe<ConditionString>;
	custom_fields_lt_any_item?: InputMaybe<ConditionString>;
	custom_fields_ne_any_item?: InputMaybe<ConditionString>;
	environments_eq_any_item?: InputMaybe<ConditionString>;
	environments_ge_any_item?: InputMaybe<ConditionString>;
	environments_gt_any_item?: InputMaybe<ConditionString>;
	environments_le_any_item?: InputMaybe<ConditionString>;
	environments_lt_any_item?: InputMaybe<ConditionString>;
	environments_ne_any_item?: InputMaybe<ConditionString>;
	flows_eq_any_item?: InputMaybe<ConditionString>;
	flows_ge_any_item?: InputMaybe<ConditionString>;
	flows_gt_any_item?: InputMaybe<ConditionString>;
	flows_le_any_item?: InputMaybe<ConditionString>;
	flows_lt_any_item?: InputMaybe<ConditionString>;
	flows_ne_any_item?: InputMaybe<ConditionString>;
	group?: InputMaybe<Array<InputMaybe<Wsdk_Test_LabelsGroup>>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	sort?: InputMaybe<Wsdk_Test_LabelsSort>;
	tenant_id_eq?: InputMaybe<ConditionId>;
	tenant_id_ge?: InputMaybe<ConditionId>;
	tenant_id_gt?: InputMaybe<ConditionId>;
	tenant_id_le?: InputMaybe<ConditionId>;
	tenant_id_lt?: InputMaybe<ConditionId>;
	tenant_id_ne?: InputMaybe<ConditionId>;
	text_contains?: InputMaybe<Wsdk_Test_LabelsSearchInsensitive>;
	text_ends_with?: InputMaybe<Wsdk_Test_LabelsSearchInsensitive>;
	text_equals?: InputMaybe<Wsdk_Test_LabelsSearchInsensitive>;
	text_regex_match?: InputMaybe<Wsdk_Test_LabelsSearch>;
	text_starts_with?: InputMaybe<Wsdk_Test_LabelsSearchInsensitive>;
};

export type QueryWsdk_Test_RunsArgs = {
	best_practice_issues_eq?: InputMaybe<ConditionInt>;
	best_practice_issues_ge?: InputMaybe<ConditionInt>;
	best_practice_issues_gt?: InputMaybe<ConditionInt>;
	best_practice_issues_le?: InputMaybe<ConditionInt>;
	best_practice_issues_lt?: InputMaybe<ConditionInt>;
	best_practice_issues_ne?: InputMaybe<ConditionInt>;
	critical_issues_eq?: InputMaybe<ConditionInt>;
	critical_issues_ge?: InputMaybe<ConditionInt>;
	critical_issues_gt?: InputMaybe<ConditionInt>;
	critical_issues_le?: InputMaybe<ConditionInt>;
	critical_issues_lt?: InputMaybe<ConditionInt>;
	critical_issues_ne?: InputMaybe<ConditionInt>;
	custom_fields_eq_any_item?: InputMaybe<ConditionString>;
	custom_fields_ge_any_item?: InputMaybe<ConditionString>;
	custom_fields_gt_any_item?: InputMaybe<ConditionString>;
	custom_fields_le_any_item?: InputMaybe<ConditionString>;
	custom_fields_lt_any_item?: InputMaybe<ConditionString>;
	custom_fields_ne_any_item?: InputMaybe<ConditionString>;
	environments_eq_any_item?: InputMaybe<ConditionString>;
	environments_ge_any_item?: InputMaybe<ConditionString>;
	environments_gt_any_item?: InputMaybe<ConditionString>;
	environments_le_any_item?: InputMaybe<ConditionString>;
	environments_lt_any_item?: InputMaybe<ConditionString>;
	environments_ne_any_item?: InputMaybe<ConditionString>;
	flows_eq_any_item?: InputMaybe<ConditionString>;
	flows_ge_any_item?: InputMaybe<ConditionString>;
	flows_gt_any_item?: InputMaybe<ConditionString>;
	flows_le_any_item?: InputMaybe<ConditionString>;
	flows_lt_any_item?: InputMaybe<ConditionString>;
	flows_ne_any_item?: InputMaybe<ConditionString>;
	group?: InputMaybe<Array<InputMaybe<Wsdk_Test_RunsGroup>>>;
	limit?: InputMaybe<Scalars['Int']['input']>;
	minor_issues_eq?: InputMaybe<ConditionInt>;
	minor_issues_ge?: InputMaybe<ConditionInt>;
	minor_issues_gt?: InputMaybe<ConditionInt>;
	minor_issues_le?: InputMaybe<ConditionInt>;
	minor_issues_lt?: InputMaybe<ConditionInt>;
	minor_issues_ne?: InputMaybe<ConditionInt>;
	moderate_issues_eq?: InputMaybe<ConditionInt>;
	moderate_issues_ge?: InputMaybe<ConditionInt>;
	moderate_issues_gt?: InputMaybe<ConditionInt>;
	moderate_issues_le?: InputMaybe<ConditionInt>;
	moderate_issues_lt?: InputMaybe<ConditionInt>;
	moderate_issues_ne?: InputMaybe<ConditionInt>;
	needs_review_issues_eq?: InputMaybe<ConditionInt>;
	needs_review_issues_ge?: InputMaybe<ConditionInt>;
	needs_review_issues_gt?: InputMaybe<ConditionInt>;
	needs_review_issues_le?: InputMaybe<ConditionInt>;
	needs_review_issues_lt?: InputMaybe<ConditionInt>;
	needs_review_issues_ne?: InputMaybe<ConditionInt>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	property_ids_eq_any_item?: InputMaybe<ConditionId>;
	property_ids_ge_any_item?: InputMaybe<ConditionId>;
	property_ids_gt_any_item?: InputMaybe<ConditionId>;
	property_ids_le_any_item?: InputMaybe<ConditionId>;
	property_ids_lt_any_item?: InputMaybe<ConditionId>;
	property_ids_ne_any_item?: InputMaybe<ConditionId>;
	run_id_eq?: InputMaybe<ConditionId>;
	run_id_ge?: InputMaybe<ConditionId>;
	run_id_gt?: InputMaybe<ConditionId>;
	run_id_le?: InputMaybe<ConditionId>;
	run_id_lt?: InputMaybe<ConditionId>;
	run_id_ne?: InputMaybe<ConditionId>;
	run_time_eq?: InputMaybe<ConditionDateTime>;
	run_time_ge?: InputMaybe<ConditionDateTime>;
	run_time_gt?: InputMaybe<ConditionDateTime>;
	run_time_last_n_days?: InputMaybe<ConditionInt>;
	run_time_le?: InputMaybe<ConditionDateTime>;
	run_time_lt?: InputMaybe<ConditionDateTime>;
	run_time_ne?: InputMaybe<ConditionDateTime>;
	serious_issues_eq?: InputMaybe<ConditionInt>;
	serious_issues_ge?: InputMaybe<ConditionInt>;
	serious_issues_gt?: InputMaybe<ConditionInt>;
	serious_issues_le?: InputMaybe<ConditionInt>;
	serious_issues_lt?: InputMaybe<ConditionInt>;
	serious_issues_ne?: InputMaybe<ConditionInt>;
	sort?: InputMaybe<Wsdk_Test_RunsSort>;
	tenant_id_eq?: InputMaybe<ConditionId>;
	tenant_id_ge?: InputMaybe<ConditionId>;
	tenant_id_gt?: InputMaybe<ConditionId>;
	tenant_id_le?: InputMaybe<ConditionId>;
	tenant_id_lt?: InputMaybe<ConditionId>;
	tenant_id_ne?: InputMaybe<ConditionId>;
	test_name_eq?: InputMaybe<ConditionString>;
	test_name_ge?: InputMaybe<ConditionString>;
	test_name_gt?: InputMaybe<ConditionString>;
	test_name_le?: InputMaybe<ConditionString>;
	test_name_lt?: InputMaybe<ConditionString>;
	test_name_ne?: InputMaybe<ConditionString>;
	test_path_eq?: InputMaybe<ConditionString>;
	test_path_ge?: InputMaybe<ConditionString>;
	test_path_gt?: InputMaybe<ConditionString>;
	test_path_le?: InputMaybe<ConditionString>;
	test_path_lt?: InputMaybe<ConditionString>;
	test_path_ne?: InputMaybe<ConditionString>;
	text_contains?: InputMaybe<Wsdk_Test_RunsSearchInsensitive>;
	text_ends_with?: InputMaybe<Wsdk_Test_RunsSearchInsensitive>;
	text_equals?: InputMaybe<Wsdk_Test_RunsSearchInsensitive>;
	text_regex_match?: InputMaybe<Wsdk_Test_RunsSearch>;
	text_starts_with?: InputMaybe<Wsdk_Test_RunsSearchInsensitive>;
	total_issues_eq?: InputMaybe<ConditionInt>;
	total_issues_ge?: InputMaybe<ConditionInt>;
	total_issues_gt?: InputMaybe<ConditionInt>;
	total_issues_le?: InputMaybe<ConditionInt>;
	total_issues_lt?: InputMaybe<ConditionInt>;
	total_issues_ne?: InputMaybe<ConditionInt>;
	total_pages_eq?: InputMaybe<ConditionInt>;
	total_pages_ge?: InputMaybe<ConditionInt>;
	total_pages_gt?: InputMaybe<ConditionInt>;
	total_pages_le?: InputMaybe<ConditionInt>;
	total_pages_lt?: InputMaybe<ConditionInt>;
	total_pages_ne?: InputMaybe<ConditionInt>;
};

export type Issues = {
	__typename?: 'issues';
	analysis_root_selector: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	analysis_root_selector_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_min: Scalars['String']['output'];
	analysis_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	analysis_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	analysis_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_time_min: Scalars['DateTime']['output'];
	axe_additional_information: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	axe_additional_information_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	axe_additional_information_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	axe_additional_information_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	axe_additional_information_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	axe_additional_information_min: Scalars['String']['output'];
	canonical_url: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	canonical_url_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	canonical_url_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	canonical_url_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	canonical_url_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	canonical_url_min: Scalars['String']['output'];
	client_version: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	client_version_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	client_version_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	client_version_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	client_version_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	client_version_min: Scalars['String']['output'];
	conformance: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	conformance_any: Array<Scalars['String']['output']>;
	custom_fields: Array<Array<Maybe<Scalars['String']['output']>>>;
	/** simple aggregate function which returns a scalar value */
	custom_fields_any: Array<Array<Maybe<Scalars['String']['output']>>>;
	deleted: Scalars['Boolean']['output'];
	description: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	description_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	description_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	description_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	description_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	description_min: Scalars['String']['output'];
	detection_types: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	detection_types_any: Array<Scalars['String']['output']>;
	domain: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	domain_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	domain_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	domain_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	domain_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	domain_min: Scalars['String']['output'];
	duplicates: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	duplicates_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	duplicates_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	duplicates_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	duplicates_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	duplicates_min: Scalars['String']['output'];
	elements__bounding_box_bottom: Array<Scalars['Float']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__bounding_box_bottom_any: Array<Scalars['Float']['output']>;
	elements__bounding_box_height: Array<Scalars['Float']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__bounding_box_height_any: Array<Scalars['Float']['output']>;
	elements__bounding_box_left: Array<Scalars['Float']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__bounding_box_left_any: Array<Scalars['Float']['output']>;
	elements__bounding_box_right: Array<Scalars['Float']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__bounding_box_right_any: Array<Scalars['Float']['output']>;
	elements__bounding_box_top: Array<Scalars['Float']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__bounding_box_top_any: Array<Scalars['Float']['output']>;
	elements__bounding_box_width: Array<Scalars['Float']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__bounding_box_width_any: Array<Scalars['Float']['output']>;
	elements__bounding_box_x: Array<Scalars['Float']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__bounding_box_x_any: Array<Scalars['Float']['output']>;
	elements__bounding_box_y: Array<Scalars['Float']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__bounding_box_y_any: Array<Scalars['Float']['output']>;
	elements__canonical_url: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__canonical_url_any: Array<Scalars['String']['output']>;
	elements__component_id: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__component_id_any: Array<Scalars['String']['output']>;
	elements__dom_snippet: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__dom_snippet_any: Array<Scalars['String']['output']>;
	elements__id: Array<Scalars['ID']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__id_any: Array<Scalars['ID']['output']>;
	elements__index: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__index_any: Array<Scalars['String']['output']>;
	elements__page_title: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__page_title_any: Array<Scalars['String']['output']>;
	elements__page_url: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__page_url_any: Array<Scalars['String']['output']>;
	elements__selector: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	elements__selector_any: Array<Scalars['String']['output']>;
	engines_version: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	engines_version_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	engines_version_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	engines_version_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	engines_version_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	engines_version_min: Scalars['String']['output'];
	environment: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	environment_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	environment_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	environment_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	environment_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	environment_min: Scalars['String']['output'];
	flow: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	flow_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	flow_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	flow_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	flow_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	flow_min: Scalars['String']['output'];
	ingestion_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	ingestion_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	ingestion_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	ingestion_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	ingestion_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	ingestion_time_min: Scalars['DateTime']['output'];
	issue_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	issue_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	issue_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	issue_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	issue_index: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	issue_index_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	issue_index_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	issue_index_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	issue_index_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	issue_index_min: Scalars['String']['output'];
	knowledge_base_link: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	knowledge_base_link_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	knowledge_base_link_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	knowledge_base_link_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	knowledge_base_link_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	knowledge_base_link_min: Scalars['String']['output'];
	note: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	note_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	note_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	note_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	note_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	note_min: Scalars['String']['output'];
	owner_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	owner_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	owner_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	owner_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	owner_type: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	owner_type_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	owner_type_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	owner_type_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	owner_type_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	owner_type_min: Scalars['String']['output'];
	page_title: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	page_title_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	page_title_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	page_title_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	page_title_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	page_title_min: Scalars['String']['output'];
	product_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	product_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	product_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	product_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	product_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	product_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	product_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_name_min: Scalars['String']['output'];
	product_version: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_version_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_version_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	product_version_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	product_version_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_version_min: Scalars['String']['output'];
	property_ids: Array<Scalars['ID']['output']>;
	/** simple aggregate function which returns a scalar value */
	property_ids_any: Array<Scalars['ID']['output']>;
	run_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	run_metadata: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	run_metadata_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	run_metadata_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_metadata_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	run_metadata_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	run_metadata_min: Scalars['String']['output'];
	run_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	run_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_min: Scalars['DateTime']['output'];
	screenshot: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	screenshot_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	screenshot_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	screenshot_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	screenshot_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	screenshot_min: Scalars['String']['output'];
	session_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	session_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	session_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	session_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	session_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	session_name_min: Scalars['String']['output'];
	severity_id: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_id_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	severity_id_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	severity_id_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_id_min: Scalars['String']['output'];
	severity_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	severity_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	severity_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_name_min: Scalars['String']['output'];
	signature: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	signature_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	signature_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	signature_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	signature_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	signature_min: Scalars['String']['output'];
	summary: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	summary_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	summary_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	summary_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	summary_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	summary_min: Scalars['String']['output'];
	tags__description: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	tags__description_any: Array<Scalars['String']['output']>;
	tags__id: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	tags__id_any: Array<Scalars['String']['output']>;
	tags__link: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	tags__link_any: Array<Scalars['String']['output']>;
	tenant_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	tenant_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	test_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	test_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	test_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	test_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	test_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	test_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	test_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_min: Scalars['String']['output'];
	test_path: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	test_path_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	test_path_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_min: Scalars['String']['output'];
	type_id: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_id_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	type_id_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	type_id_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_id_min: Scalars['String']['output'];
	type_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	type_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	type_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_name_min: Scalars['String']['output'];
	unique_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	unique_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	unique_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	unique_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	update_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	update_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	update_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	update_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	update_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	update_time_min: Scalars['DateTime']['output'];
	url: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	url_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	url_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	url_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	url_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	url_min: Scalars['String']['output'];
	user_agent: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	user_agent_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	user_agent_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	user_agent_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	user_agent_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	user_agent_min: Scalars['String']['output'];
	validation_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	validation_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	validation_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	validation_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	viewport_height: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_height_any: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_height_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	viewport_height_group_uniq_array?: Maybe<Array<Scalars['Float']['output']>>;
	/** simple aggregate function which returns a scalar value */
	viewport_height_max: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_height_min: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_height_sum: Scalars['Int']['output'];
	viewport_width: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_width_any: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_width_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	viewport_width_group_uniq_array?: Maybe<Array<Scalars['Float']['output']>>;
	/** simple aggregate function which returns a scalar value */
	viewport_width_max: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_width_min: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_width_sum: Scalars['Int']['output'];
};

export type IssuesAnalysis_Root_Selector_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesAnalysis_Root_Selector_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesAnalysis_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IssuesAnalysis_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesAxe_Additional_Information_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesAxe_Additional_Information_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesCanonical_Url_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesCanonical_Url_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesClient_Version_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesClient_Version_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesDescription_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesDescription_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesDomain_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesDomain_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesDuplicates_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesDuplicates_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesEngines_Version_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesEngines_Version_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesEnvironment_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesEnvironment_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesFlow_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesFlow_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesIngestion_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IssuesIngestion_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesIssue_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type IssuesIssue_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesIssue_Index_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesIssue_Index_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesKnowledge_Base_Link_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesKnowledge_Base_Link_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesNote_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesNote_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesOwner_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type IssuesOwner_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesOwner_Type_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesOwner_Type_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesPage_Title_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesPage_Title_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesProduct_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type IssuesProduct_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesProduct_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesProduct_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesProduct_Version_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesProduct_Version_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesRun_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type IssuesRun_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesRun_Metadata_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesRun_Metadata_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesRun_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IssuesRun_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesScreenshot_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesScreenshot_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesSession_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesSession_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesSeverity_Id_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesSeverity_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesSeverity_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesSeverity_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesSignature_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesSignature_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesSummary_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesSummary_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesTenant_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type IssuesTenant_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesTest_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type IssuesTest_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesTest_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesTest_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesTest_Path_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesTest_Path_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesType_Id_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesType_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesType_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesType_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesUnique_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type IssuesUnique_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesUpdate_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IssuesUpdate_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesUrl_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesUrl_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesUser_Agent_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type IssuesUser_Agent_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesValidation_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type IssuesValidation_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesViewport_Height_CountArgs = {
	value?: InputMaybe<Scalars['Float']['input']>;
};

export type IssuesViewport_Height_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type IssuesViewport_Width_CountArgs = {
	value?: InputMaybe<Scalars['Float']['input']>;
};

export type IssuesViewport_Width_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum IssuesGroup {
	AnalysisRootSelector = 'analysis_root_selector',
	AnalysisTime = 'analysis_time',
	AxeAdditionalInformation = 'axe_additional_information',
	CanonicalUrl = 'canonical_url',
	ClientVersion = 'client_version',
	Deleted = 'deleted',
	Description = 'description',
	Domain = 'domain',
	Duplicates = 'duplicates',
	EnginesVersion = 'engines_version',
	Environment = 'environment',
	Flow = 'flow',
	IngestionTime = 'ingestion_time',
	IssueId = 'issue_id',
	IssueIndex = 'issue_index',
	KnowledgeBaseLink = 'knowledge_base_link',
	Note = 'note',
	OwnerId = 'owner_id',
	OwnerType = 'owner_type',
	PageTitle = 'page_title',
	ProductId = 'product_id',
	ProductName = 'product_name',
	ProductVersion = 'product_version',
	RunId = 'run_id',
	RunMetadata = 'run_metadata',
	RunTime = 'run_time',
	Screenshot = 'screenshot',
	SessionName = 'session_name',
	SeverityId = 'severity_id',
	SeverityName = 'severity_name',
	Signature = 'signature',
	Summary = 'summary',
	TenantId = 'tenant_id',
	TestId = 'test_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	TypeId = 'type_id',
	TypeName = 'type_name',
	UniqueId = 'unique_id',
	UpdateTime = 'update_time',
	Url = 'url',
	UserAgent = 'user_agent',
	ValidationId = 'validation_id',
	ViewportHeight = 'viewport_height',
	ViewportWidth = 'viewport_width'
}

export type IssuesSearch = {
	searchFields: Array<IssuesTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type IssuesSearchInsensitive = {
	insensitive?: InputMaybe<Scalars['Boolean']['input']>;
	searchFields: Array<IssuesTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type IssuesSort = {
	fields: Array<IssuesSortFields>;
	order?: InputMaybe<IssuesSortOrder>;
};

export enum IssuesSortFields {
	AnalysisRootSelector = 'analysis_root_selector',
	AnalysisTime = 'analysis_time',
	AxeAdditionalInformation = 'axe_additional_information',
	CanonicalUrl = 'canonical_url',
	ClientVersion = 'client_version',
	Deleted = 'deleted',
	Description = 'description',
	Domain = 'domain',
	Duplicates = 'duplicates',
	EnginesVersion = 'engines_version',
	Environment = 'environment',
	Flow = 'flow',
	IngestionTime = 'ingestion_time',
	IssueId = 'issue_id',
	IssueIndex = 'issue_index',
	KnowledgeBaseLink = 'knowledge_base_link',
	Note = 'note',
	OwnerId = 'owner_id',
	OwnerType = 'owner_type',
	PageTitle = 'page_title',
	ProductId = 'product_id',
	ProductName = 'product_name',
	ProductVersion = 'product_version',
	RunId = 'run_id',
	RunMetadata = 'run_metadata',
	RunTime = 'run_time',
	Screenshot = 'screenshot',
	SessionName = 'session_name',
	SeverityId = 'severity_id',
	SeverityName = 'severity_name',
	Signature = 'signature',
	Summary = 'summary',
	TenantId = 'tenant_id',
	TestId = 'test_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	TypeId = 'type_id',
	TypeName = 'type_name',
	UniqueId = 'unique_id',
	UpdateTime = 'update_time',
	Url = 'url',
	UserAgent = 'user_agent',
	ValidationId = 'validation_id',
	ViewportHeight = 'viewport_height',
	ViewportWidth = 'viewport_width'
}

/** An example enum type */
export enum IssuesSortOrder {
	Ascending = 'Ascending',
	Descending = 'Descending'
}

export enum IssuesTextSearchFields {
	AnalysisRootSelector = 'analysis_root_selector',
	AnalysisTime = 'analysis_time',
	AxeAdditionalInformation = 'axe_additional_information',
	CanonicalUrl = 'canonical_url',
	ClientVersion = 'client_version',
	Conformance = 'conformance',
	CustomFields = 'custom_fields',
	Deleted = 'deleted',
	Description = 'description',
	DetectionTypes = 'detection_types',
	Domain = 'domain',
	Duplicates = 'duplicates',
	ElementsBoundingBoxBottom = 'elements__bounding_box_bottom',
	ElementsBoundingBoxHeight = 'elements__bounding_box_height',
	ElementsBoundingBoxLeft = 'elements__bounding_box_left',
	ElementsBoundingBoxRight = 'elements__bounding_box_right',
	ElementsBoundingBoxTop = 'elements__bounding_box_top',
	ElementsBoundingBoxWidth = 'elements__bounding_box_width',
	ElementsBoundingBoxX = 'elements__bounding_box_x',
	ElementsBoundingBoxY = 'elements__bounding_box_y',
	ElementsCanonicalUrl = 'elements__canonical_url',
	ElementsComponentId = 'elements__component_id',
	ElementsDomSnippet = 'elements__dom_snippet',
	ElementsId = 'elements__id',
	ElementsIndex = 'elements__index',
	ElementsPageTitle = 'elements__page_title',
	ElementsPageUrl = 'elements__page_url',
	ElementsSelector = 'elements__selector',
	EnginesVersion = 'engines_version',
	Environment = 'environment',
	Flow = 'flow',
	IngestionTime = 'ingestion_time',
	IssueId = 'issue_id',
	IssueIndex = 'issue_index',
	KnowledgeBaseLink = 'knowledge_base_link',
	Note = 'note',
	OwnerId = 'owner_id',
	OwnerType = 'owner_type',
	PageTitle = 'page_title',
	ProductId = 'product_id',
	ProductName = 'product_name',
	ProductVersion = 'product_version',
	PropertyIds = 'property_ids',
	RunId = 'run_id',
	RunMetadata = 'run_metadata',
	RunTime = 'run_time',
	Screenshot = 'screenshot',
	SessionName = 'session_name',
	SeverityId = 'severity_id',
	SeverityName = 'severity_name',
	Signature = 'signature',
	Summary = 'summary',
	TagsDescription = 'tags__description',
	TagsId = 'tags__id',
	TagsLink = 'tags__link',
	TenantId = 'tenant_id',
	TestId = 'test_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	TypeId = 'type_id',
	TypeName = 'type_name',
	UniqueId = 'unique_id',
	UpdateTime = 'update_time',
	Url = 'url',
	UserAgent = 'user_agent',
	ValidationId = 'validation_id',
	ViewportHeight = 'viewport_height',
	ViewportWidth = 'viewport_width'
}

export type Pages = {
	__typename?: 'pages';
	analysis_root_selector: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	analysis_root_selector_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_min: Scalars['String']['output'];
	canonical_url: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	canonical_url_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	canonical_url_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	canonical_url_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	canonical_url_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	canonical_url_min: Scalars['String']['output'];
	client_version: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	client_version_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	client_version_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	client_version_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	client_version_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	client_version_min: Scalars['String']['output'];
	custom_fields: Array<Array<Maybe<Scalars['String']['output']>>>;
	/** simple aggregate function which returns a scalar value */
	custom_fields_any: Array<Array<Maybe<Scalars['String']['output']>>>;
	deleted: Scalars['Boolean']['output'];
	detection_types: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	detection_types_any: Array<Scalars['String']['output']>;
	domain: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	domain_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	domain_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	domain_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	domain_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	domain_min: Scalars['String']['output'];
	engines_version: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	engines_version_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	engines_version_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	engines_version_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	engines_version_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	engines_version_min: Scalars['String']['output'];
	environment: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	environment_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	environment_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	environment_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	environment_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	environment_min: Scalars['String']['output'];
	flow: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	flow_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	flow_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	flow_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	flow_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	flow_min: Scalars['String']['output'];
	ingestion_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	ingestion_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	ingestion_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	ingestion_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	ingestion_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	ingestion_time_min: Scalars['DateTime']['output'];
	owner_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	owner_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	owner_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	owner_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	owner_type: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	owner_type_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	owner_type_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	owner_type_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	owner_type_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	owner_type_min: Scalars['String']['output'];
	page_title: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	page_title_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	page_title_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	page_title_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	page_title_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	page_title_min: Scalars['String']['output'];
	product_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	product_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	product_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	product_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	product_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	product_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	product_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_name_min: Scalars['String']['output'];
	product_version: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_version_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_version_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	product_version_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	product_version_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	product_version_min: Scalars['String']['output'];
	property_ids: Array<Scalars['ID']['output']>;
	/** simple aggregate function which returns a scalar value */
	property_ids_any: Array<Scalars['ID']['output']>;
	run_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	run_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	run_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_min: Scalars['DateTime']['output'];
	session_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	session_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	session_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	session_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	session_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	session_name_min: Scalars['String']['output'];
	tenant_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	tenant_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	test_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	test_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	test_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	test_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	test_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	test_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	test_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_min: Scalars['String']['output'];
	test_path: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	test_path_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	test_path_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_min: Scalars['String']['output'];
	update_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	update_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	update_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	update_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	update_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	update_time_min: Scalars['DateTime']['output'];
	url: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	url_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	url_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	url_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	url_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	url_min: Scalars['String']['output'];
	user_agent: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	user_agent_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	user_agent_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	user_agent_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	user_agent_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	user_agent_min: Scalars['String']['output'];
	viewport_height: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_height_any: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_height_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	viewport_height_group_uniq_array?: Maybe<Array<Scalars['Float']['output']>>;
	/** simple aggregate function which returns a scalar value */
	viewport_height_max: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_height_min: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_height_sum: Scalars['Int']['output'];
	viewport_width: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_width_any: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_width_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	viewport_width_group_uniq_array?: Maybe<Array<Scalars['Float']['output']>>;
	/** simple aggregate function which returns a scalar value */
	viewport_width_max: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_width_min: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	viewport_width_sum: Scalars['Int']['output'];
};

export type PagesAnalysis_Root_Selector_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesAnalysis_Root_Selector_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesCanonical_Url_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesCanonical_Url_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesClient_Version_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesClient_Version_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesDomain_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesDomain_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesEngines_Version_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesEngines_Version_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesEnvironment_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesEnvironment_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesFlow_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesFlow_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesIngestion_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PagesIngestion_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesOwner_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type PagesOwner_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesOwner_Type_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesOwner_Type_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesPage_Title_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesPage_Title_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesProduct_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type PagesProduct_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesProduct_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesProduct_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesProduct_Version_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesProduct_Version_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesRun_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type PagesRun_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesRun_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PagesRun_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesSession_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesSession_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesTenant_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type PagesTenant_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesTest_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type PagesTest_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesTest_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesTest_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesTest_Path_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesTest_Path_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesUpdate_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PagesUpdate_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesUrl_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesUrl_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesUser_Agent_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type PagesUser_Agent_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesViewport_Height_CountArgs = {
	value?: InputMaybe<Scalars['Float']['input']>;
};

export type PagesViewport_Height_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesViewport_Width_CountArgs = {
	value?: InputMaybe<Scalars['Float']['input']>;
};

export type PagesViewport_Width_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum PagesGroup {
	AnalysisRootSelector = 'analysis_root_selector',
	CanonicalUrl = 'canonical_url',
	ClientVersion = 'client_version',
	Deleted = 'deleted',
	Domain = 'domain',
	EnginesVersion = 'engines_version',
	Environment = 'environment',
	Flow = 'flow',
	IngestionTime = 'ingestion_time',
	OwnerId = 'owner_id',
	OwnerType = 'owner_type',
	PageTitle = 'page_title',
	ProductId = 'product_id',
	ProductName = 'product_name',
	ProductVersion = 'product_version',
	RunId = 'run_id',
	RunTime = 'run_time',
	SessionName = 'session_name',
	TenantId = 'tenant_id',
	TestId = 'test_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	UpdateTime = 'update_time',
	Url = 'url',
	UserAgent = 'user_agent',
	ViewportHeight = 'viewport_height',
	ViewportWidth = 'viewport_width'
}

export type PagesSearch = {
	searchFields: Array<PagesTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type PagesSearchInsensitive = {
	insensitive?: InputMaybe<Scalars['Boolean']['input']>;
	searchFields: Array<PagesTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type PagesSort = {
	fields: Array<PagesSortFields>;
	order?: InputMaybe<PagesSortOrder>;
};

export enum PagesSortFields {
	AnalysisRootSelector = 'analysis_root_selector',
	CanonicalUrl = 'canonical_url',
	ClientVersion = 'client_version',
	Deleted = 'deleted',
	Domain = 'domain',
	EnginesVersion = 'engines_version',
	Environment = 'environment',
	Flow = 'flow',
	IngestionTime = 'ingestion_time',
	OwnerId = 'owner_id',
	OwnerType = 'owner_type',
	PageTitle = 'page_title',
	ProductId = 'product_id',
	ProductName = 'product_name',
	ProductVersion = 'product_version',
	RunId = 'run_id',
	RunTime = 'run_time',
	SessionName = 'session_name',
	TenantId = 'tenant_id',
	TestId = 'test_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	UpdateTime = 'update_time',
	Url = 'url',
	UserAgent = 'user_agent',
	ViewportHeight = 'viewport_height',
	ViewportWidth = 'viewport_width'
}

/** An example enum type */
export enum PagesSortOrder {
	Ascending = 'Ascending',
	Descending = 'Descending'
}

export enum PagesTextSearchFields {
	AnalysisRootSelector = 'analysis_root_selector',
	CanonicalUrl = 'canonical_url',
	ClientVersion = 'client_version',
	CustomFields = 'custom_fields',
	Deleted = 'deleted',
	DetectionTypes = 'detection_types',
	Domain = 'domain',
	EnginesVersion = 'engines_version',
	Environment = 'environment',
	Flow = 'flow',
	IngestionTime = 'ingestion_time',
	OwnerId = 'owner_id',
	OwnerType = 'owner_type',
	PageTitle = 'page_title',
	ProductId = 'product_id',
	ProductName = 'product_name',
	ProductVersion = 'product_version',
	PropertyIds = 'property_ids',
	RunId = 'run_id',
	RunTime = 'run_time',
	SessionName = 'session_name',
	TenantId = 'tenant_id',
	TestId = 'test_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	UpdateTime = 'update_time',
	Url = 'url',
	UserAgent = 'user_agent',
	ViewportHeight = 'viewport_height',
	ViewportWidth = 'viewport_width'
}

export type Wfa_Session_Components_Runs = {
	__typename?: 'wfa_session_components_runs';
	component_id: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	component_id_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	component_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	component_id_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	component_id_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	component_id_min: Scalars['String']['output'];
	conformance: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	conformance_any: Array<Scalars['String']['output']>;
	run_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	run_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	run_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_min: Scalars['DateTime']['output'];
	severity_ids: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	severity_ids_any: Array<Scalars['String']['output']>;
	severity_names: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	severity_names_any: Array<Scalars['String']['output']>;
	tenant_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	tenant_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	total_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	total_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	total_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_sum: Scalars['Int']['output'];
	total_pages: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	total_pages_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	total_pages_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_sum: Scalars['Int']['output'];
	type_ids: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	type_ids_any: Array<Scalars['String']['output']>;
	type_names: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	type_names_any: Array<Scalars['String']['output']>;
	urls: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	urls_any: Array<Scalars['String']['output']>;
};

export type Wfa_Session_Components_RunsComponent_Id_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wfa_Session_Components_RunsComponent_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Components_RunsRun_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wfa_Session_Components_RunsRun_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Components_RunsRun_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Wfa_Session_Components_RunsRun_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Components_RunsTenant_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wfa_Session_Components_RunsTenant_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Components_RunsTotal_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Components_RunsTotal_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Components_RunsTotal_Pages_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Components_RunsTotal_Pages_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum Wfa_Session_Components_RunsGroup {
	ComponentId = 'component_id',
	RunId = 'run_id',
	RunTime = 'run_time',
	TenantId = 'tenant_id',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages'
}

export type Wfa_Session_Components_RunsSearch = {
	searchFields: Array<Wfa_Session_Components_RunsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wfa_Session_Components_RunsSearchInsensitive = {
	insensitive?: InputMaybe<Scalars['Boolean']['input']>;
	searchFields: Array<Wfa_Session_Components_RunsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wfa_Session_Components_RunsSort = {
	fields: Array<Wfa_Session_Components_RunsSortFields>;
	order?: InputMaybe<Wfa_Session_Components_RunsSortOrder>;
};

export enum Wfa_Session_Components_RunsSortFields {
	ComponentId = 'component_id',
	RunId = 'run_id',
	RunTime = 'run_time',
	TenantId = 'tenant_id',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages'
}

/** An example enum type */
export enum Wfa_Session_Components_RunsSortOrder {
	Ascending = 'Ascending',
	Descending = 'Descending'
}

export enum Wfa_Session_Components_RunsTextSearchFields {
	ComponentId = 'component_id',
	Conformance = 'conformance',
	RunId = 'run_id',
	RunTime = 'run_time',
	SeverityIds = 'severity_ids',
	SeverityNames = 'severity_names',
	TenantId = 'tenant_id',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages',
	TypeIds = 'type_ids',
	TypeNames = 'type_names',
	Urls = 'urls'
}

export type Wfa_Session_Runs = {
	__typename?: 'wfa_session_runs';
	analysis_root_selector: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	analysis_root_selector_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	analysis_root_selector_min: Scalars['String']['output'];
	best_practice_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	best_practice_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_sum: Scalars['Int']['output'];
	critical_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	critical_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	critical_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_sum: Scalars['Int']['output'];
	custom_fields: Array<Array<Maybe<Scalars['String']['output']>>>;
	/** simple aggregate function which returns a scalar value */
	custom_fields_any: Array<Array<Maybe<Scalars['String']['output']>>>;
	detection_types: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	detection_types_any: Array<Scalars['String']['output']>;
	domain: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	domain_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	domain_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	domain_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	domain_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	domain_min: Scalars['String']['output'];
	environments: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	environments_any: Array<Scalars['String']['output']>;
	flows: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	flows_any: Array<Scalars['String']['output']>;
	minor_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	minor_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	minor_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_sum: Scalars['Int']['output'];
	moderate_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	moderate_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	moderate_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_sum: Scalars['Int']['output'];
	needs_review_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	needs_review_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_sum: Scalars['Int']['output'];
	property_ids: Array<Scalars['ID']['output']>;
	/** simple aggregate function which returns a scalar value */
	property_ids_any: Array<Scalars['ID']['output']>;
	run_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	run_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	run_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_min: Scalars['DateTime']['output'];
	serious_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	serious_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	serious_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_sum: Scalars['Int']['output'];
	session_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	session_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	session_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	session_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	session_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	session_name_min: Scalars['String']['output'];
	tenant_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	tenant_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	total_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	total_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	total_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_sum: Scalars['Int']['output'];
	total_pages: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	total_pages_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	total_pages_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_sum: Scalars['Int']['output'];
	update_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	update_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	update_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	update_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	update_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	update_time_min: Scalars['DateTime']['output'];
	user_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	user_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	user_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	user_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	user_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	user_name_min: Scalars['String']['output'];
};

export type Wfa_Session_RunsAnalysis_Root_Selector_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wfa_Session_RunsAnalysis_Root_Selector_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsBest_Practice_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsBest_Practice_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsCritical_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsCritical_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsDomain_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wfa_Session_RunsDomain_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsMinor_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsMinor_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsModerate_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsModerate_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsNeeds_Review_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsNeeds_Review_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsRun_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wfa_Session_RunsRun_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsRun_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Wfa_Session_RunsRun_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsSerious_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsSerious_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsSession_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wfa_Session_RunsSession_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsTenant_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wfa_Session_RunsTenant_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsTotal_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsTotal_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsTotal_Pages_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsTotal_Pages_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsUpdate_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Wfa_Session_RunsUpdate_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_RunsUser_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wfa_Session_RunsUser_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum Wfa_Session_RunsGroup {
	AnalysisRootSelector = 'analysis_root_selector',
	BestPracticeIssues = 'best_practice_issues',
	CriticalIssues = 'critical_issues',
	Domain = 'domain',
	MinorIssues = 'minor_issues',
	ModerateIssues = 'moderate_issues',
	NeedsReviewIssues = 'needs_review_issues',
	RunId = 'run_id',
	RunTime = 'run_time',
	SeriousIssues = 'serious_issues',
	SessionName = 'session_name',
	TenantId = 'tenant_id',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages',
	UpdateTime = 'update_time',
	UserName = 'user_name'
}

export type Wfa_Session_RunsSearch = {
	searchFields: Array<Wfa_Session_RunsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wfa_Session_RunsSearchInsensitive = {
	insensitive?: InputMaybe<Scalars['Boolean']['input']>;
	searchFields: Array<Wfa_Session_RunsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wfa_Session_RunsSort = {
	fields: Array<Wfa_Session_RunsSortFields>;
	order?: InputMaybe<Wfa_Session_RunsSortOrder>;
};

export enum Wfa_Session_RunsSortFields {
	AnalysisRootSelector = 'analysis_root_selector',
	BestPracticeIssues = 'best_practice_issues',
	CriticalIssues = 'critical_issues',
	Domain = 'domain',
	MinorIssues = 'minor_issues',
	ModerateIssues = 'moderate_issues',
	NeedsReviewIssues = 'needs_review_issues',
	RunId = 'run_id',
	RunTime = 'run_time',
	SeriousIssues = 'serious_issues',
	SessionName = 'session_name',
	TenantId = 'tenant_id',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages',
	UpdateTime = 'update_time',
	UserName = 'user_name'
}

/** An example enum type */
export enum Wfa_Session_RunsSortOrder {
	Ascending = 'Ascending',
	Descending = 'Descending'
}

export enum Wfa_Session_RunsTextSearchFields {
	AnalysisRootSelector = 'analysis_root_selector',
	BestPracticeIssues = 'best_practice_issues',
	CriticalIssues = 'critical_issues',
	CustomFields = 'custom_fields',
	DetectionTypes = 'detection_types',
	Domain = 'domain',
	Environments = 'environments',
	Flows = 'flows',
	MinorIssues = 'minor_issues',
	ModerateIssues = 'moderate_issues',
	NeedsReviewIssues = 'needs_review_issues',
	PropertyIds = 'property_ids',
	RunId = 'run_id',
	RunTime = 'run_time',
	SeriousIssues = 'serious_issues',
	SessionName = 'session_name',
	TenantId = 'tenant_id',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages',
	UpdateTime = 'update_time',
	UserName = 'user_name'
}

export type Wfa_Session_Type_Runs = {
	__typename?: 'wfa_session_type_runs';
	run_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	run_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	run_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_min: Scalars['DateTime']['output'];
	severity_id: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_id_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	severity_id_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	severity_id_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_id_min: Scalars['String']['output'];
	severity_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	severity_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	severity_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	severity_name_min: Scalars['String']['output'];
	tenant_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	tenant_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	total_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	total_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	total_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_sum: Scalars['Int']['output'];
	type_id: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_id_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	type_id_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	type_id_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_id_min: Scalars['String']['output'];
	type_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	type_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	type_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	type_name_min: Scalars['String']['output'];
};

export type Wfa_Session_Type_RunsRun_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wfa_Session_Type_RunsRun_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Type_RunsRun_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Wfa_Session_Type_RunsRun_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Type_RunsSeverity_Id_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wfa_Session_Type_RunsSeverity_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Type_RunsSeverity_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wfa_Session_Type_RunsSeverity_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Type_RunsTenant_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wfa_Session_Type_RunsTenant_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Type_RunsTotal_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Type_RunsTotal_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Type_RunsType_Id_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wfa_Session_Type_RunsType_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Session_Type_RunsType_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wfa_Session_Type_RunsType_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum Wfa_Session_Type_RunsGroup {
	RunId = 'run_id',
	RunTime = 'run_time',
	SeverityId = 'severity_id',
	SeverityName = 'severity_name',
	TenantId = 'tenant_id',
	TotalIssues = 'total_issues',
	TypeId = 'type_id',
	TypeName = 'type_name'
}

export type Wfa_Session_Type_RunsSearch = {
	searchFields: Array<Wfa_Session_Type_RunsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wfa_Session_Type_RunsSearchInsensitive = {
	insensitive?: InputMaybe<Scalars['Boolean']['input']>;
	searchFields: Array<Wfa_Session_Type_RunsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wfa_Session_Type_RunsSort = {
	fields: Array<Wfa_Session_Type_RunsSortFields>;
	order?: InputMaybe<Wfa_Session_Type_RunsSortOrder>;
};

export enum Wfa_Session_Type_RunsSortFields {
	RunId = 'run_id',
	RunTime = 'run_time',
	SeverityId = 'severity_id',
	SeverityName = 'severity_name',
	TenantId = 'tenant_id',
	TotalIssues = 'total_issues',
	TypeId = 'type_id',
	TypeName = 'type_name'
}

/** An example enum type */
export enum Wfa_Session_Type_RunsSortOrder {
	Ascending = 'Ascending',
	Descending = 'Descending'
}

export enum Wfa_Session_Type_RunsTextSearchFields {
	RunId = 'run_id',
	RunTime = 'run_time',
	SeverityId = 'severity_id',
	SeverityName = 'severity_name',
	TenantId = 'tenant_id',
	TotalIssues = 'total_issues',
	TypeId = 'type_id',
	TypeName = 'type_name'
}

export type Wfa_Top_Critical_Components = {
	__typename?: 'wfa_top_critical_components';
	critical_issues_count: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_count_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_count_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	critical_issues_count_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	critical_issues_count_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_count_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_count_sum: Scalars['Int']['output'];
	critical_percentage: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_percentage_any: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_percentage_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	critical_percentage_group_uniq_array?: Maybe<Array<Scalars['Float']['output']>>;
	/** simple aggregate function which returns a scalar value */
	critical_percentage_max: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_percentage_min: Scalars['Float']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_percentage_sum: Scalars['Int']['output'];
	elements__component_id: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	elements__component_id_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	elements__component_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	elements__component_id_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	elements__component_id_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	elements__component_id_min: Scalars['String']['output'];
	run_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	tenant_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	tenant_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
};

export type Wfa_Top_Critical_ComponentsCritical_Issues_Count_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Top_Critical_ComponentsCritical_Issues_Count_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Top_Critical_ComponentsCritical_Percentage_CountArgs = {
	value?: InputMaybe<Scalars['Float']['input']>;
};

export type Wfa_Top_Critical_ComponentsCritical_Percentage_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Top_Critical_ComponentsElements__Component_Id_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wfa_Top_Critical_ComponentsElements__Component_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Top_Critical_ComponentsRun_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wfa_Top_Critical_ComponentsRun_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wfa_Top_Critical_ComponentsTenant_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wfa_Top_Critical_ComponentsTenant_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum Wfa_Top_Critical_ComponentsGroup {
	CriticalIssuesCount = 'critical_issues_count',
	CriticalPercentage = 'critical_percentage',
	ElementsComponentId = 'elements__component_id',
	RunId = 'run_id',
	TenantId = 'tenant_id'
}

export type Wfa_Top_Critical_ComponentsSearch = {
	searchFields: Array<Wfa_Top_Critical_ComponentsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wfa_Top_Critical_ComponentsSearchInsensitive = {
	insensitive?: InputMaybe<Scalars['Boolean']['input']>;
	searchFields: Array<Wfa_Top_Critical_ComponentsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wfa_Top_Critical_ComponentsSort = {
	fields: Array<Wfa_Top_Critical_ComponentsSortFields>;
	order?: InputMaybe<Wfa_Top_Critical_ComponentsSortOrder>;
};

export enum Wfa_Top_Critical_ComponentsSortFields {
	CriticalIssuesCount = 'critical_issues_count',
	CriticalPercentage = 'critical_percentage',
	ElementsComponentId = 'elements__component_id',
	RunId = 'run_id',
	TenantId = 'tenant_id'
}

/** An example enum type */
export enum Wfa_Top_Critical_ComponentsSortOrder {
	Ascending = 'Ascending',
	Descending = 'Descending'
}

export enum Wfa_Top_Critical_ComponentsTextSearchFields {
	CriticalIssuesCount = 'critical_issues_count',
	CriticalPercentage = 'critical_percentage',
	ElementsComponentId = 'elements__component_id',
	RunId = 'run_id',
	TenantId = 'tenant_id'
}

export type Wsdk_Latest_Test_Runs = {
	__typename?: 'wsdk_latest_test_runs';
	best_practice_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	best_practice_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_sum: Scalars['Int']['output'];
	critical_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	critical_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	critical_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_sum: Scalars['Int']['output'];
	custom_fields: Array<Array<Maybe<Scalars['String']['output']>>>;
	/** simple aggregate function which returns a scalar value */
	custom_fields_any: Array<Array<Maybe<Scalars['String']['output']>>>;
	environments: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	environments_any: Array<Scalars['String']['output']>;
	flows: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	flows_any: Array<Scalars['String']['output']>;
	minor_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	minor_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	minor_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_sum: Scalars['Int']['output'];
	moderate_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	moderate_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	moderate_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_sum: Scalars['Int']['output'];
	needs_review_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	needs_review_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_sum: Scalars['Int']['output'];
	property_ids: Array<Scalars['ID']['output']>;
	/** simple aggregate function which returns a scalar value */
	property_ids_any: Array<Scalars['ID']['output']>;
	run_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	run_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	run_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_min: Scalars['DateTime']['output'];
	serious_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	serious_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	serious_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_sum: Scalars['Int']['output'];
	tenant_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	tenant_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	test_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	test_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	test_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_min: Scalars['String']['output'];
	test_path: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	test_path_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	test_path_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_min: Scalars['String']['output'];
	total_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	total_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	total_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_sum: Scalars['Int']['output'];
	total_pages: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	total_pages_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	total_pages_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_sum: Scalars['Int']['output'];
};

export type Wsdk_Latest_Test_RunsBest_Practice_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsBest_Practice_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsCritical_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsCritical_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsMinor_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsMinor_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsModerate_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsModerate_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsNeeds_Review_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsNeeds_Review_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsRun_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wsdk_Latest_Test_RunsRun_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsRun_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Wsdk_Latest_Test_RunsRun_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsSerious_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsSerious_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsTenant_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wsdk_Latest_Test_RunsTenant_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsTest_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wsdk_Latest_Test_RunsTest_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsTest_Path_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wsdk_Latest_Test_RunsTest_Path_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsTotal_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsTotal_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsTotal_Pages_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Latest_Test_RunsTotal_Pages_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum Wsdk_Latest_Test_RunsGroup {
	BestPracticeIssues = 'best_practice_issues',
	CriticalIssues = 'critical_issues',
	MinorIssues = 'minor_issues',
	ModerateIssues = 'moderate_issues',
	NeedsReviewIssues = 'needs_review_issues',
	RunId = 'run_id',
	RunTime = 'run_time',
	SeriousIssues = 'serious_issues',
	TenantId = 'tenant_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages'
}

export type Wsdk_Latest_Test_RunsSearch = {
	searchFields: Array<Wsdk_Latest_Test_RunsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wsdk_Latest_Test_RunsSearchInsensitive = {
	insensitive?: InputMaybe<Scalars['Boolean']['input']>;
	searchFields: Array<Wsdk_Latest_Test_RunsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wsdk_Latest_Test_RunsSort = {
	fields: Array<Wsdk_Latest_Test_RunsSortFields>;
	order?: InputMaybe<Wsdk_Latest_Test_RunsSortOrder>;
};

export enum Wsdk_Latest_Test_RunsSortFields {
	BestPracticeIssues = 'best_practice_issues',
	CriticalIssues = 'critical_issues',
	MinorIssues = 'minor_issues',
	ModerateIssues = 'moderate_issues',
	NeedsReviewIssues = 'needs_review_issues',
	RunId = 'run_id',
	RunTime = 'run_time',
	SeriousIssues = 'serious_issues',
	TenantId = 'tenant_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages'
}

/** An example enum type */
export enum Wsdk_Latest_Test_RunsSortOrder {
	Ascending = 'Ascending',
	Descending = 'Descending'
}

export enum Wsdk_Latest_Test_RunsTextSearchFields {
	BestPracticeIssues = 'best_practice_issues',
	CriticalIssues = 'critical_issues',
	CustomFields = 'custom_fields',
	Environments = 'environments',
	Flows = 'flows',
	MinorIssues = 'minor_issues',
	ModerateIssues = 'moderate_issues',
	NeedsReviewIssues = 'needs_review_issues',
	PropertyIds = 'property_ids',
	RunId = 'run_id',
	RunTime = 'run_time',
	SeriousIssues = 'serious_issues',
	TenantId = 'tenant_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages'
}

export type Wsdk_Test_Labels = {
	__typename?: 'wsdk_test_labels';
	custom_fields: Array<Array<Maybe<Scalars['String']['output']>>>;
	/** simple aggregate function which returns a scalar value */
	custom_fields_any: Array<Array<Maybe<Scalars['String']['output']>>>;
	environments: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	environments_any: Array<Scalars['String']['output']>;
	flows: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	flows_any: Array<Scalars['String']['output']>;
	tenant_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	tenant_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
};

export type Wsdk_Test_LabelsTenant_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wsdk_Test_LabelsTenant_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum Wsdk_Test_LabelsGroup {
	TenantId = 'tenant_id'
}

export type Wsdk_Test_LabelsSearch = {
	searchFields: Array<Wsdk_Test_LabelsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wsdk_Test_LabelsSearchInsensitive = {
	insensitive?: InputMaybe<Scalars['Boolean']['input']>;
	searchFields: Array<Wsdk_Test_LabelsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wsdk_Test_LabelsSort = {
	fields: Array<Wsdk_Test_LabelsSortFields>;
	order?: InputMaybe<Wsdk_Test_LabelsSortOrder>;
};

export enum Wsdk_Test_LabelsSortFields {
	TenantId = 'tenant_id'
}

/** An example enum type */
export enum Wsdk_Test_LabelsSortOrder {
	Ascending = 'Ascending',
	Descending = 'Descending'
}

export enum Wsdk_Test_LabelsTextSearchFields {
	CustomFields = 'custom_fields',
	Environments = 'environments',
	Flows = 'flows',
	TenantId = 'tenant_id'
}

export type Wsdk_Test_Runs = {
	__typename?: 'wsdk_test_runs';
	best_practice_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	best_practice_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	best_practice_issues_sum: Scalars['Int']['output'];
	critical_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	critical_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	critical_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	critical_issues_sum: Scalars['Int']['output'];
	custom_fields: Array<Array<Maybe<Scalars['String']['output']>>>;
	/** simple aggregate function which returns a scalar value */
	custom_fields_any: Array<Array<Maybe<Scalars['String']['output']>>>;
	environments: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	environments_any: Array<Scalars['String']['output']>;
	flows: Array<Scalars['String']['output']>;
	/** simple aggregate function which returns a scalar value */
	flows_any: Array<Scalars['String']['output']>;
	minor_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	minor_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	minor_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	minor_issues_sum: Scalars['Int']['output'];
	moderate_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	moderate_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	moderate_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	moderate_issues_sum: Scalars['Int']['output'];
	needs_review_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	needs_review_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	needs_review_issues_sum: Scalars['Int']['output'];
	property_ids: Array<Scalars['ID']['output']>;
	/** simple aggregate function which returns a scalar value */
	property_ids_any: Array<Scalars['ID']['output']>;
	run_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	run_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	run_time: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_any: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	run_time_group_uniq_array?: Maybe<Array<Scalars['DateTime']['output']>>;
	/** simple aggregate function which returns a scalar value */
	run_time_max: Scalars['DateTime']['output'];
	/** simple aggregate function which returns a scalar value */
	run_time_min: Scalars['DateTime']['output'];
	serious_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	serious_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	serious_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	serious_issues_sum: Scalars['Int']['output'];
	tenant_id: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_any: Scalars['ID']['output'];
	/** simple aggregate function which returns a scalar value */
	tenant_id_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	tenant_id_group_uniq_array?: Maybe<Array<Scalars['ID']['output']>>;
	test_name: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	test_name_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	test_name_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_name_min: Scalars['String']['output'];
	test_path: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_any: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	test_path_group_uniq_array?: Maybe<Array<Scalars['String']['output']>>;
	/** simple aggregate function which returns a scalar value */
	test_path_max: Scalars['String']['output'];
	/** simple aggregate function which returns a scalar value */
	test_path_min: Scalars['String']['output'];
	total_issues: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	total_issues_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	total_issues_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_issues_sum: Scalars['Int']['output'];
	total_pages: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_any: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_count: Scalars['Int']['output'];
	/** group unique array aggregate function */
	total_pages_group_uniq_array?: Maybe<Array<Scalars['Int']['output']>>;
	/** simple aggregate function which returns a scalar value */
	total_pages_max: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_min: Scalars['Int']['output'];
	/** simple aggregate function which returns a scalar value */
	total_pages_sum: Scalars['Int']['output'];
};

export type Wsdk_Test_RunsBest_Practice_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsBest_Practice_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsCritical_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsCritical_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsMinor_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsMinor_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsModerate_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsModerate_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsNeeds_Review_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsNeeds_Review_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsRun_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wsdk_Test_RunsRun_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsRun_Time_CountArgs = {
	value?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Wsdk_Test_RunsRun_Time_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsSerious_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsSerious_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsTenant_Id_CountArgs = {
	value?: InputMaybe<Scalars['ID']['input']>;
};

export type Wsdk_Test_RunsTenant_Id_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsTest_Name_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wsdk_Test_RunsTest_Name_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsTest_Path_CountArgs = {
	value?: InputMaybe<Scalars['String']['input']>;
};

export type Wsdk_Test_RunsTest_Path_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsTotal_Issues_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsTotal_Issues_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsTotal_Pages_CountArgs = {
	value?: InputMaybe<Scalars['Int']['input']>;
};

export type Wsdk_Test_RunsTotal_Pages_Group_Uniq_ArrayArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum Wsdk_Test_RunsGroup {
	BestPracticeIssues = 'best_practice_issues',
	CriticalIssues = 'critical_issues',
	MinorIssues = 'minor_issues',
	ModerateIssues = 'moderate_issues',
	NeedsReviewIssues = 'needs_review_issues',
	RunId = 'run_id',
	RunTime = 'run_time',
	SeriousIssues = 'serious_issues',
	TenantId = 'tenant_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages'
}

export type Wsdk_Test_RunsSearch = {
	searchFields: Array<Wsdk_Test_RunsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wsdk_Test_RunsSearchInsensitive = {
	insensitive?: InputMaybe<Scalars['Boolean']['input']>;
	searchFields: Array<Wsdk_Test_RunsTextSearchFields>;
	searchValues: Array<Scalars['String']['input']>;
};

export type Wsdk_Test_RunsSort = {
	fields: Array<Wsdk_Test_RunsSortFields>;
	order?: InputMaybe<Wsdk_Test_RunsSortOrder>;
};

export enum Wsdk_Test_RunsSortFields {
	BestPracticeIssues = 'best_practice_issues',
	CriticalIssues = 'critical_issues',
	MinorIssues = 'minor_issues',
	ModerateIssues = 'moderate_issues',
	NeedsReviewIssues = 'needs_review_issues',
	RunId = 'run_id',
	RunTime = 'run_time',
	SeriousIssues = 'serious_issues',
	TenantId = 'tenant_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages'
}

/** An example enum type */
export enum Wsdk_Test_RunsSortOrder {
	Ascending = 'Ascending',
	Descending = 'Descending'
}

export enum Wsdk_Test_RunsTextSearchFields {
	BestPracticeIssues = 'best_practice_issues',
	CriticalIssues = 'critical_issues',
	CustomFields = 'custom_fields',
	Environments = 'environments',
	Flows = 'flows',
	MinorIssues = 'minor_issues',
	ModerateIssues = 'moderate_issues',
	NeedsReviewIssues = 'needs_review_issues',
	PropertyIds = 'property_ids',
	RunId = 'run_id',
	RunTime = 'run_time',
	SeriousIssues = 'serious_issues',
	TenantId = 'tenant_id',
	TestName = 'test_name',
	TestPath = 'test_path',
	TotalIssues = 'total_issues',
	TotalPages = 'total_pages'
}
