import { DropdownOptionType, IDropdownMenuOption } from '@evinced-private/ui-common';

import RoutesHelper from '../../../helpers/RoutesHelper';

type getEvincedAppsMenuOptionsHelperArgs = {
	hasMfaLicense: boolean;
	hasMsdkLicense: boolean;
	hasWebSdkLicense: boolean;
	hasSiteScannerLicense: boolean;
	hasWfaLicense: boolean;
};

export const getEvincedAppsDropdownMenuOptions = (
	licenses: getEvincedAppsMenuOptionsHelperArgs
): IDropdownMenuOption[] => {
	const { hasMfaLicense, hasMsdkLicense, hasWebSdkLicense, hasSiteScannerLicense, hasWfaLicense } =
		licenses;
	const isMFADashboard = window.location.href.includes(RoutesHelper.getMfaDashboardPath());
	const isWFADashboard = window.location.href.includes(RoutesHelper.getWfaDashboardPath());
	const isMobileSDK = window.location.href.includes(RoutesHelper.getMobileSdkDashboardPath());
	const isWebSDK = window.location.href.includes(RoutesHelper.getWebSdkDashboardPath());
	const isSiteScanner = window.location.href.includes(process.env.SITE_SCANNER_DASHBOARD_URL);
	const isProductHub = window.location.href.includes(process.env.PRODUCT_HUB_URL);
	const defaultProps = {
		type: DropdownOptionType.LINK,
		hasBottomBorder: false,
		openInNewTab: true
	};

	const appsList = [
		{
			id: 'mfa-dashboard-url',
			url: RoutesHelper.getMfaDashboardPath(),
			title: 'Mobile Flow Analyzer',
			active: isMFADashboard,
			hasLicense: hasMfaLicense,
			...defaultProps
		},
		{
			id: 'mobile-sdk-dashboard-url',
			url: RoutesHelper.getMobileSdkDashboardPath(),
			title: 'Mobile SDKs',
			active: isMobileSDK,
			hasLicense: hasMsdkLicense,
			...defaultProps
		},
		{
			id: 'web-sdk-dashboard-url',
			url: RoutesHelper.getWebSdkDashboardPath(),
			title: 'Web SDKs',
			active: isWebSDK,
			hasLicense: hasWebSdkLicense,
			...defaultProps
		},
		{
			id: 'wfa-dashboard-url',
			url: RoutesHelper.getWfaDashboardPath(),
			title: 'Web Flow Analyzer',
			active: isWFADashboard,
			hasLicense: hasWfaLicense,
			...defaultProps
		},
		{
			id: 'site-scanner-url',
			url: process.env.SITE_SCANNER_URL,
			title: 'Site Scanner',
			active: isSiteScanner,
			hasLicense: hasSiteScannerLicense,
			...defaultProps
		},
		{
			id: 'product-hub',
			url: process.env.PRODUCT_HUB_URL,
			title: 'Product Hub',
			active: isProductHub,
			hasLicense: true,
			...defaultProps
		}
	];

	return appsList.filter((app) => app.hasLicense);
};
