import React from 'react';

import EllipsedTextWithTooltip from '../../../components/ellipsed-text-with-tooltip/EllipsedTextWithTooltip';
import { IEvBreadcrumb } from '../../../components/header-navigation/EvBreadcrumbs';
import { LabelsPopup } from '../../../components/labels-popup/LabelsPopup';
import { IReportInfo } from '../../../components/report-info/ReportInfo';
import DataItemHelper from '../../../helpers/DataItemHelper';
import { formatDate, formatShortMonthDatetime } from '../../../helpers/DateFormatHelper';
import FormatHelper from '../../../helpers/FormatHelper';
import { getPropertiesString } from '../../../helpers/PropertiesHelper';
import RoutesHelper from '../../../helpers/RoutesHelper';
import TogglesService, { DevelopmentToggles } from '../../../services/TogglesService';
import { PlatformLabel } from '../../../types/IssueDetailsType';
import { IWebSdkTest, IWebSdkTestDetails } from '../types/WebSdkDashboardServiceTypes';

import { MAIN_PAGE_NAV_LABEL, NAME_NOT_PROVIDED } from './WebSdkConsts';

const enableWebSdkProperties = TogglesService.getToggle(
	DevelopmentToggles.ENABLE_WEB_SDK_PROPERTIES
);

export const getWebSdkDashboardBreadcrumbsRoutes = (
	runId: string,
	testName: string = NAME_NOT_PROVIDED,
	runTime?: string
): IEvBreadcrumb[] => {
	return [
		{
			id: MAIN_PAGE_NAV_LABEL,
			url: RoutesHelper.getWebSdkDashboardPath(),
			title: MAIN_PAGE_NAV_LABEL
		},
		{
			id: runId,
			url: '',
			title: `${testName}${runTime ? ` - ${formatDate(runTime)}` : ''}`
		}
	];
};

export const populatePropertiesLabel = (
	labels: PlatformLabel[],
	propertyIds: string[],
	propertiesMap?: Map<string, string>
): PlatformLabel[] => {
	const propertiesLabelIndex = labels.findIndex((label) => label.name === 'Properties');
	if (propertiesLabelIndex > -1) {
		const labelsClone = [...labels];
		if (enableWebSdkProperties) {
			const propertiesLabel = labels[propertiesLabelIndex];

			// if properties are enabled, replace property label placeholder with the actial value
			labelsClone.splice(propertiesLabelIndex, 1, {
				...propertiesLabel,
				value:
					FormatHelper.capitalizeFirstLetter(
						getPropertiesString(propertyIds, propertiesMap) ?? ''
					) || 'N/A'
			});
		} else {
			// if properties are not enabled, remove properties label placeholder
			labelsClone.splice(propertiesLabelIndex, 1);
		}
		return labelsClone;
	}
	return labels;
};

export const getWebSdkInfoItems = (propertiesMap?: Map<string, string>): IReportInfo[] => [
	{ key: 'runTime', label: 'Test date', textFormatter: formatShortMonthDatetime },
	{
		key: 'testPath',
		label: 'Test path',
		isMiddleEllipsis: true,
		textFormatter: FormatHelper.capitalizeFirstLetter
	},
	...(enableWebSdkProperties
		? [
				{
					key: 'propertyIds',
					label: 'Properties',
					customValueRenderer: (test: IWebSdkTestDetails): JSX.Element | null => {
						return test.propertyIds?.length ? (
							<EllipsedTextWithTooltip
								text={FormatHelper.capitalizeFirstLetter(
									getPropertiesString(test.propertyIds, propertiesMap) ?? ''
								)}
								maxWidth="200px"
								showTooltipOnlyOnEllipsed
							/>
						) : null;
					},
					labelFormatter: DataItemHelper.formatLabel
				} as IReportInfo
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  ]
		: []),
	{ key: 'environments', label: 'Environment', textFormatter: FormatHelper.capitalizeFirstLetter },
	{ key: 'flows', label: 'Flow', textFormatter: FormatHelper.capitalizeFirstLetter },
	{
		key: 'labels',
		label: 'Labels',
		customValueRenderer: (test: IWebSdkTestDetails) => (
			<LabelsPopup
				name={test.name}
				labels={populatePropertiesLabel(test.labels, test.propertyIds, propertiesMap)}
				title="View all test labels"
			/>
		),
		labelFormatter: DataItemHelper.formatLabel
	}
];

export const populateTestsTableDataWithDisplayData = (
	data: IWebSdkTest[],
	showTestPath: boolean,
	propertiesMap?: Map<string, string>
): IWebSdkTest[] =>
	data.map((row) => ({
		...row,
		testDisplayName: showTestPath ? `${row.testPath}/${row.testName}` : row.testName,
		propertiesString: getPropertiesString(row.propertyIds, propertiesMap) ?? 'N/A'
	}));
