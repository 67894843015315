import { callGETAndCache } from '../api/ServerCacheApi';
import API_URL_PATHS from '../consts/ApiUrlPaths';
import { IUser } from '../interfaces/User';

const { USER_URL_PATH } = API_URL_PATHS;

export async function getUserMetadata(): Promise<IUser> {
	const user: IUser = await callGETAndCache(USER_URL_PATH);
	return user;
}
