import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';

import AuthenticationService from '../../services/AuthenticationService';
import CentralConfigurationService from '../../services/central-configuration/CentralConfigurationService';
import TogglesService, { DevelopmentToggles } from '../../services/TogglesService';
import { useUserTenant } from '../userTenantProvider/UserTenantProvider';

export type TConfiguration = Record<string, Record<string, unknown>>;
export type TGetToggle = (
	toggleId: DevelopmentToggles,
	// useUserToggle: bc for auth0 use toggle support. Deafult is false
	// If true, will try to extract auth0 value toggle from the user's token
	// If false, will try to extract the value from the central configuration instead
	useUserToggle?: boolean
) => boolean;

interface IConfigurationContext {
	configuration: TConfiguration;
	getToggle: TGetToggle;
}

const ConfigurationContext = createContext<IConfigurationContext>(null);

const ConfigurationProvider: FC = ({ children }) => {
	const [configuration, setConfiguration] = useState<TConfiguration>(null);
	const { tenant } = useUserTenant();

	const normalizeBooleanToggleValue = (toggle: string | boolean): string | boolean => {
		if (toggle === 'true') {
			return true;
		}
		if (toggle === 'false') {
			return false;
		}
		return toggle;
	};

	const getToggle = useCallback(
		(toggleId: DevelopmentToggles, useUserToggle = false): boolean => {
			const localToggleValue = TogglesService.getToggle(toggleId, useUserToggle);
			return useUserToggle
				? localToggleValue
				: localToggleValue ||
						!!normalizeBooleanToggleValue(configuration?.TOGGLES?.[toggleId] as string | boolean);
		},
		[configuration]
	);

	const initConfiguration = useCallback(async (): Promise<void> => {
		if (!configuration) {
			const formattedConfig: TConfiguration = await CentralConfigurationService.getCentralConfig(
				tenant.products
			);
			setConfiguration(formattedConfig);
		}
	}, [tenant, configuration]);

	useEffect(() => {
		if (tenant && AuthenticationService.isLoggedIn()) {
			initConfiguration();
		}
	}, [initConfiguration, tenant]);

	const value = useMemo(() => ({ configuration, getToggle }), [configuration, getToggle]);

	return <ConfigurationContext.Provider value={value}>{children}</ConfigurationContext.Provider>;
};

const useConfiguration = (): IConfigurationContext => useContext(ConfigurationContext);

export { ConfigurationProvider, useConfiguration };
