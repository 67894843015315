import React, { FC } from 'react';

import { EvSwitch } from '@evinced-private/ui-common';

import './BestPracticesButton.scss';

interface IBestPracticesButton {
	onChange: (withBestPractices: boolean) => void;
	withBestPractices: boolean;
}

const BestPracticesButton: FC<IBestPracticesButton> = ({
	onChange,
	withBestPractices
}): JSX.Element => {
	return (
		<EvSwitch
			onChange={onChange}
			checked={withBestPractices}
			label="Include Best Practices"
			className="include-best-practices"
		/>
	);
};

export default BestPracticesButton;
