import React from 'react';

import { IWfaSessionDetails } from '../../services/wfa-dashboard/WfaDashboardService.types';
import { TagsBlock } from '../common/tags-block/TagsBlock';

import { getTagsFromSessionDetails } from './helpers';

interface ISessionInfoBlockProps {
	sessionDetails: IWfaSessionDetails;
	isThreeColumns?: boolean;
}

export const SessionInfoBlock: React.FC<ISessionInfoBlockProps> = ({
	sessionDetails,
	isThreeColumns
}) => {
	return (
		<TagsBlock
			isThreeColumns={isThreeColumns}
			tagGroups={getTagsFromSessionDetails(sessionDetails)}
		/>
	);
};
