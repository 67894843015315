import { SkipLinksProvider } from '@evinced-private/ui-common';

import { BreadcrumbRoutesProvider } from './breadcrumbsProvider/BreadcrumbsProvider';
import { ConfigurationProvider } from './configurationProvider/ConfigurationProvider';
import NotificationsProvider from './notificationsProvider/NotificationsProvider';
import ReactQueryProvider from './reactQueryProvider/ReactQueryProvider';
import { UserTenantProvider } from './userTenantProvider/UserTenantProvider';
import { combineComponents } from './utils/CombineProviders';
import {
	initialPageScrollPosition,
	PageScrollPositionProvider } from './PageScrollPositionProvider';

const providers = [
	{ Component: ReactQueryProvider },
	{ Component: SkipLinksProvider },
	{ Component: NotificationsProvider },
	{ Component: UserTenantProvider },
	{ Component: ConfigurationProvider },
	{ Component: PageScrollPositionProvider, value: { state: initialPageScrollPosition } },
	{ Component: BreadcrumbRoutesProvider }
];
export const AppProvider = combineComponents(...providers);
