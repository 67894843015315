import React from 'react';

import { IReportInfo } from 'src/components/report-info/ReportInfo';
import { SessionInfoPopup } from 'src/components/session-info-popup/SessionInfoPopup';
import { formatShortMonthDatetime } from 'src/helpers/DateFormatHelper';
import { IWfaSessionDetails } from 'src/services/wfa-dashboard/WfaDashboardService.types';

export const getWfaInfoItems = (): IReportInfo[] => [
	{ key: 'runTime', label: 'Session date', textFormatter: formatShortMonthDatetime },
	{
		key: 'createdBy',
		label: 'Created by'
	},
	{
		key: 'sessionInformation',
		label: 'Session information',
		customValueRenderer: (session: IWfaSessionDetails) => (
			<SessionInfoPopup name={session.name} sessionDetails={session} title="Session information" />
		)
	}
];
