import React, { FC, useCallback } from 'react';

import { ProductType } from 'src/interfaces/Tenant';

import DetailsPopup from '../details-popup/DetailsPopup';

import ShowDetailsCellFormatter from './ShowDetailsCellFormatter';

interface IShowIssueDetailsCellFormatter {
	product: ProductType;
	runId: string;
	issueId: string;
	infoTitle: string;
	issueType?: string;
}

export const ShowIssueDetailsCellFormatter: FC<IShowIssueDetailsCellFormatter> = ({
	product,
	runId,
	issueId,
	issueType,
	infoTitle
}) => {
	const renderPopup = useCallback(
		(closeModal: () => void) => (
			<DetailsPopup
				product={product}
				runId={runId}
				issueId={issueId}
				issueType={issueType}
				onClose={closeModal}
			/>
		),
		[issueId, issueType, product, runId]
	);

	return (
		<ShowDetailsCellFormatter
			infoTitle={infoTitle}
			renderPopup={renderPopup}
			ariaLabel="Show Issue Details"
		/>
	);
};
