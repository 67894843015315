import React, { FC, ReactElement, useState } from 'react';

import { BUTTON_TYPES, EvButton, EvIcon } from '@evinced-private/ui-common';

import IssueInfoIcon from '../../icons/IssueInfoIcon.svg';

import './ShowDetailsCellFormatter.scss';

interface IShowDetailsFormatterCell {
	infoTitle: string;
	renderPopup: (closeModal: () => void) => ReactElement;
	ariaLabel: string;
}

const ShowDetailsCellFormatter: FC<IShowDetailsFormatterCell> = ({
	infoTitle,
	renderPopup,
	ariaLabel
}: IShowDetailsFormatterCell) => {
	const [isModalOpen, setModalOpen] = useState(false);

	const openModal = (): void => {
		setModalOpen(true);
	};

	const closeModal = (): void => {
		setModalOpen(false);
	};

	return (
		<div className="show-details-cell-formatter">
			<EvButton
				title={infoTitle}
				customTooltip={{ renderTooltip: true }}
				type={BUTTON_TYPES.ICON}
				className="show-details-button"
				onClick={openModal}
				accessibilityProps={{
					'aria-label': ariaLabel
				}}
				ariaHaspopup="dialog"
			>
				<EvIcon icon={IssueInfoIcon} />
			</EvButton>
			{isModalOpen && renderPopup(closeModal)}
		</div>
	);
};

export default ShowDetailsCellFormatter;
