import { SORT_ORDER, TableSortOption } from '@evinced-private/ui-common';

import { WFA_SESSIONS_TABLE_ID } from 'src/pages/dashboards-container/dashboard_consts';

export const TABLE_STATE_DEFAULT_SORT: { [key: string]: TableSortOption[] } = {
	[WFA_SESSIONS_TABLE_ID]: [
		{
			dataField: 'runTime',
			order: SORT_ORDER.DESC
		}
	]
};
