import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { WFA_SESSION_NAME } from 'src/providers/reactQueryProvider/QueryKeys';
import { getWfaSessionName } from 'src/services/wfa-dashboard/WfaDashboardService';

export const useWfaSessionNameQuery = (sessionId: string): UseQueryResult<string> => {
	const sessionNameQuery = useQuery([WFA_SESSION_NAME, sessionId], () =>
		getWfaSessionName(sessionId)
	);

	return sessionNameQuery;
};
