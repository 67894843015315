import React from 'react';

import { PlatformLabel } from '../../types/IssueDetailsType';
import { TagsBlock } from '../common/tags-block/TagsBlock';

interface LabelsPopupProp {
	labels: PlatformLabel[];
	isThreeColumns?: boolean;
}

export const LabelsBlock: React.FC<LabelsPopupProp> = ({ labels, isThreeColumns }) => {
	const automaticLabels = labels.filter((label) => label.isAutomatic);
	const customLabels = labels.filter((label) => !label.isAutomatic);

	return (
		<TagsBlock
			isThreeColumns={isThreeColumns}
			tagGroups={[
				{
					tags: automaticLabels
				},
				{ tags: customLabels }
			]}
		/>
	);
};
