export const WFA_DASHBOARD_PAGE_MAIN_TITLE_ID = 'wfa-dashboard-page-main-title';
export const WFA_DASHBOARD_PAGE_FILTERS_SECTION_ID = 'wfa-dashboard-page-filters-section';
export const WFA_DASHBOARD_PAGE_USAGE_SECTION_ID = 'wfa-dashboard-page-usage-section';
export const WFA_DASHBOARD_PAGE_USAGE_TITLE_ID = 'wfa-dashboard-page-usage-title';
export const WFA_DASHBOARD_PAGE_TABLE_SECTION_ID = 'wfa-dashboard-page-table-section';
export const WFA_DASHBOARD_PAGE_TABLE_TITLE_ID = 'wfa-dashboard-page-table-title';
export const WFA_SESSION_OVERVIEW_TAB_DAID = 'wfa-session-overview-tab';
export const WFA_SESSION_COMPONENTS_TAB_DAID = 'wfa-session-components-tab';
export const WFA_SESSION_ALL_ISSUES_TAB_DAID = 'wfa-session-all-issues-tab';
export const WFA_SESSION_HEADER_DAID = 'wfa-session-header';
export const WFA_SESSION_TITLE_DAID = 'wfa-session-title';
export const WFA_SESSION_ADDITIONAL_INFO_BUTTON_DAID = 'wfa-session-additional-indo-button';
export const WFA_SESSION_ISSUES_TABLE_DAID = 'wfa-session-all-issues-table';
export const WFA_SESSION_COMPONENTS_TABLE_DAID = 'wfa-session-components-table';
export const WFA_SESSION_FILTERS_SECTION_ID = 'wfa-session-filters-section';
export const WFA_SESSION_COPY_URL_BUTTON_DAID = 'wfa-session-copy-url-button';
export const WFA_SESSION_OVERVIEW_SUMMARY_DAID = 'wfa-session-overview-summary';
export const WFA_SESSION_ISSUE_TYPE_GRAPH_DAID = 'wfa-session-issue-type-graph';
export const WFA_SESSION_SEVERITIES_GRAPH_DAID = 'wfa-session-severities-graph';
export const WFA_SESSION_TOP_COMPONENTS_GRAPH_DAID = 'wfa-session-top-components-graph';
