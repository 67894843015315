const DEFAULT_PATH = '/';
const REDIRECT_TO_KEY = 'prodmon-redirect-after-login-path';
/**
 * Returns the URL the user should be redirected to after a successful login
 */
export function getRedirectToPath(): { path: string } {
	const lastUserLocation = sessionStorage.getItem(REDIRECT_TO_KEY);
	if (lastUserLocation) {
		return JSON.parse(lastUserLocation);
	}
	return { path: DEFAULT_PATH };
}

/**
 * Saves the URL the user should go to after a successful login
 * This URL is saved on a 'session' level
 * to avoid conflicts and cases of old and not valid URLs that were saved long ago
 * @param {string} path
 */
export function setRedirectToPath(path: string): { path: string } {
	const locationToSave = { path };
	sessionStorage.setItem(REDIRECT_TO_KEY, JSON.stringify(locationToSave));
	return locationToSave;
}
