import { OptionType } from '@evinced-private/ui-common';

import { SummaryItem } from 'src/interfaces/SummaryItem';

import { transformUsageDataResults as transformUsageDataResultsGeneric } from '../../../../../../helpers/GQLRequestsHelper/QueryResultsTransformHelper';
import { UsageDataQuery } from '../UsageData.generated';

const TESTS_LABEL = 'Total test runs';
const SCANS_LABEL = 'Total scanned pages';

export const transformUsageDataResults = (
	rawResults: UsageDataQuery,
	testPeriod?: OptionType
): SummaryItem[] => {
	const lastPeriodTestRunsCount = rawResults?.last_n_days?.[0]?.run_id_count ?? 0;
	const twoPeriodsTestRunsCount = rawResults?.last_2n_days?.[0]?.run_id_count ?? 0;

	const lastPeriodPagesCount = rawResults?.last_n_days?.[0]?.total_pages_sum ?? 0;
	const twoPeriodsPagesCount = rawResults?.last_2n_days?.[0]?.total_pages_sum ?? 0;

	return [
		transformUsageDataResultsGeneric(
			lastPeriodTestRunsCount,
			twoPeriodsTestRunsCount,
			TESTS_LABEL,
			testPeriod
		),
		transformUsageDataResultsGeneric(
			lastPeriodPagesCount,
			twoPeriodsPagesCount,
			SCANS_LABEL,
			testPeriod
		)
	];
};
