import React, { FC } from 'react';

import classNames from 'classnames';

import { EvTitle, HeadingLevelRange, TITLE_MODES } from '@evinced-private/ui-common';

import { DATA_ITEM_DAID } from '../../consts/data-automation-ids';
import EllipsedTextWithTooltip from '../ellipsed-text-with-tooltip/EllipsedTextWithTooltip';

import './DataItem.scss';

type DataItemValue = string | JSX.Element;
interface IDataItemProps {
	label: string;
	value: DataItemValue;
	formatter?: (value: string) => string;
	isMiddleEllipsis?: boolean;
	maxWidth?: string;
	headingLevel?: HeadingLevelRange;
	className?: string;
}

const DataItem: FC<IDataItemProps> = ({
	label,
	value,
	isMiddleEllipsis,
	formatter,
	maxWidth = '200px',
	headingLevel = 3,
	className
}) => {
	const showTooltip = isMiddleEllipsis || (!isMiddleEllipsis && value?.toString().length > 35);

	const valueFormatter = (stringValue: string): DataItemValue => {
		return (
			<EllipsedTextWithTooltip
				text={formatter?.(stringValue) ?? stringValue}
				maxWidth={maxWidth}
				useMiddleEllipsis={isMiddleEllipsis}
				showTooltipOnlyOnEllipsed={!showTooltip}
			/>
		);
	};

	const renderValue = (): JSX.Element => {
		if (typeof value === 'string') {
			return (
				<div className="text-value">{value.trim() ? valueFormatter(value.trim()) : 'N/A'}</div>
			);
		}

		return value;
	};

	return (
		<div
			className={classNames('data-item', className)}
			style={{ maxWidth }}
			data-automation-id={DATA_ITEM_DAID}
		>
			<EvTitle
				titleText={label}
				headingLevel={headingLevel}
				className="label"
				mode={TITLE_MODES.NORMAL}
			/>
			{renderValue()}
		</div>
	);
};

export default DataItem;
