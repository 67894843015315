import { EvTableColumn, SORT_ORDER, TableSortOption } from '@evinced-private/ui-common';

import { MfaSession } from '../apps/mfa/types/mfa-types';
import { MSdkTest } from '../apps/mobile-sdk/types/MobileSdkTypes';

import { formatDate } from './DateFormatHelper';

type CsvRow = {
	[key: string]: string;
};

export const getCsvSeparator = (): string => ',';

const escapeCsvField = (field: string): string => {
	if (field) {
		return `${field}`.replace(/"/g, '""');
	}
	return '';
};

type FieldValue = string | number;

const compareDates = (dateString1, dateString2, sortDirection): number => {
	const date1 = new Date(dateString1).getTime();
	const date2 = new Date(dateString2).getTime();

	if (date1 < date2) {
		return -sortDirection;
	}
	if (date1 > date2) {
		return sortDirection;
	}
	return 0;
};

const compareValues = (
	firstValue: FieldValue,
	secondValue: FieldValue,
	sortDirection: number,
	isDate = false
): number => {
	if (isDate) {
		return compareDates(firstValue, secondValue, sortDirection);
	}
	if (typeof firstValue === 'string' && typeof secondValue === 'string') {
		return sortDirection > 0
			? firstValue.localeCompare(secondValue)
			: secondValue.localeCompare(firstValue);
	}

	/** Convert both values to numbers if possible */

	const firstNumber = Number(firstValue);
	const secondNumber = Number(secondValue);

	// Check if both values are valid numbers
	// eslint-disable-next-line no-restricted-globals
	if (!isNaN(firstNumber) && !isNaN(secondNumber)) {
		return sortDirection > 0 ? firstNumber - secondNumber : secondNumber - firstNumber;
	}

	// Handle cases where one value is a string and the other is a number
	if (typeof firstValue === 'string') {
		return sortDirection; // Treat the string as smaller
	}
	if (typeof secondValue === 'string') {
		return -sortDirection; // Treat the string as larger
	}

	// Handle other cases (e.g., non-numeric or non-string values)
	return 0;
};

export type CsvHeader = {
	label: string;
	key: string;
};

export const createHeadersOutOfTableColumns = (tableColumns: EvTableColumn[]): CsvHeader[] =>
	tableColumns.map((column) => ({ label: column.text, key: column.dataField }));

export const addHeaderColumns = (
	headerColumns: CsvHeader[],
	addColumn: { key: string; label: string }[]
): CsvHeader[] => [
	...headerColumns,
	...addColumn.map((column) => ({ label: column.label, key: column.key }))
];

export const getReportsCsvData = (
	reportItems: MfaSession[] | MSdkTest[],
	headers: CsvHeader[],
	sortField?: TableSortOption,
	options?: { formatDate: string[] }
): CsvRow[] => {
	if (reportItems.length) {
		if (sortField) {
			reportItems.sort((a, b) =>
				compareValues(
					a[sortField.dataField],
					b[sortField.dataField],
					sortField.order === SORT_ORDER.ASC ? 1 : -1,
					options?.formatDate?.includes(sortField.dataField)
				)
			);
		} else {
			reportItems.sort((a, b) => b.pageUrl.localeCompare(a.pageUrl));
		}

		return reportItems.map((item) => {
			const csvData = headers.reduce((res, header) => {
				if (options?.formatDate?.includes(header.key)) {
					res[header.key] = escapeCsvField(formatDate(item[header.key]));
				} else {
					res[header.key] = escapeCsvField(item[header.key]);
				}
				return res;
			}, {});
			return csvData;
		});
	}
	return [];
};
