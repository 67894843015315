import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { PLATFORM_ISSUE_DETAILS } from 'src/providers/reactQueryProvider/QueryKeys';

import { ProductType } from '../../../interfaces/Tenant';
import { PlatformIssueDetails } from '../../../types/IssueDetailsType';
import { getPlatformIssueDetails } from '../CommonIssueDetailsService';

interface IUseIssueDetailsQueryParams {
	issueId: string;
	runId: string;
	product: ProductType;
}
export const useIssueDetailsQuery = ({
	issueId,
	runId,
	product
}: IUseIssueDetailsQueryParams): UseQueryResult<PlatformIssueDetails> => {
	const issueDetailsQuery = useQuery(
		[PLATFORM_ISSUE_DETAILS, runId, issueId],
		(): Promise<PlatformIssueDetails> => getPlatformIssueDetails(issueId, runId, product)
	);

	return issueDetailsQuery;
};
