import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';

import RoutesHelper from '../../../helpers/RoutesHelper';
import { ProductType } from '../../../interfaces/Tenant';
import NotFound404Page from '../../../pages/not-found-404-page/NotFound404Page';
import Unauthorized403Page from '../../../pages/unauthorized-403-page/Unauthorized403Page';
import { TableStateProvider } from '../../../providers/dashboard/TableStateProvider';
import { useUserTenant } from '../../../providers/userTenantProvider/UserTenantProvider';
import { hasLicenseForProduct } from '../../../services/CommonDashboardService';
import { DashboardContainerWebSDK } from '../pages/dashboard-container/DashboardContainerWebSdk';
import WebSdkIssuePage from '../pages/issue/WebSdkIssuePage';
import WebSdkTestIssuesPage from '../pages/test-issues/WebSdkTestIssuesPage';

const productType = ProductType.WEB_SDK;
const WebSdkRouter: FC = () => {
	const { tenant } = useUserTenant();
	const hasLicense = hasLicenseForProduct(tenant, productType);

	const history = useHistory();
	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={RoutesHelper.getWebSdkDashboardPath()}
					component={DashboardContainerWebSDK}
				/>
				<Route
					exact
					path={RoutesHelper.getWebSdkTestPath(':runId')}
					render={() => {
						return hasLicense ? (
							<TableStateProvider>
								<WebSdkTestIssuesPage />
							</TableStateProvider>
						) : (
							<Unauthorized403Page />
						);
					}}
				/>
				<Route
					exact
					path={RoutesHelper.getWebSdkIssueInfoPath({ runId: ':runId', issueId: ':issueId' })}
					render={() => {
						return hasLicense ? <WebSdkIssuePage /> : <Unauthorized403Page />;
					}}
				/>
				<Route render={() => <NotFound404Page />} />
			</Switch>
		</Router>
	);
};

export default WebSdkRouter;
