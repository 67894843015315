import React from 'react';

import { EvLinkTypes, EvTableColumn } from '@evinced-private/ui-common';

import EvLinkLocal from 'src/components/common/ev-link-local/EvLinkLocal';
import renderNumberCellFormatter from 'src/components/common/ev-table/cell-formatters/numberCellFormatter';
import { formatShortMonthDatetime } from 'src/helpers/DateFormatHelper';
import RoutesHelper from 'src/helpers/RoutesHelper';
import { IWfaSessionRow } from 'src/services/wfa-dashboard/WfaDashboardService.types';

import { NAME_NOT_PROVIDED } from '../../../../../apps/web-sdks/helpers/WebSdkConsts';
import { SessionViews } from '../../../WfaDashboardConsts';

export const getWfaSessionsColumns = (): EvTableColumn[] => {
	return [
		{
			dataField: 'sessionName',
			text: 'Session',
			style: { width: '200px' },
			headerStyle: { width: '200px' },
			sort: true,
			formatter: (displayName: string, session: IWfaSessionRow) => {
				// todo change to sessions paths
				return (
					<EvLinkLocal
						className="wfa-session-name"
						id={session.sessionId}
						url={RoutesHelper.getWfaSessionPath(session.sessionId, SessionViews.OVERVIEW)}
						ariaLabel={`${displayName} Session`}
						linkText={session.sessionName || NAME_NOT_PROVIDED}
						type={EvLinkTypes.DARK}
					/>
				);
			}
		},
		{
			dataField: 'totalIssues',
			text: 'Total Issues',
			style: { width: '90px' },
			headerStyle: { width: '90px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'criticalIssues',
			text: 'Critical Issues',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'runTime',
			text: 'Last Modified',
			style: { width: '90px' },
			headerStyle: { width: '90px' },
			sort: true,
			formatter: (cell: string): string => formatShortMonthDatetime(cell)
		},
		{
			dataField: 'createdBy',
			text: 'Created by',
			style: { width: '135px' },
			headerStyle: { width: '135px' },
			sort: true
		}
	];
};
