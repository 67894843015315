import React, { FC } from 'react';

import {
	EvFilters,
	EvSection,
	IFilterValues,
	TABLE_FILTERS_SR_SUMMARY
} from '@evinced-private/ui-common';

import omit from 'lodash-es/omit';
import { IFilterOptions } from 'src/helpers/DataFilterHelper';
import { flattenObject } from 'src/helpers/ObjectsHelper';
import { useWfaSessionFilterOptionsQuery } from 'src/pages/wfa-dashboard/hooks/useWfaSessionFilterOptionsQuery';
import { WFA_SESSION_FILTERS_SECTION_ID } from 'src/pages/wfa-dashboard/WfaDashboardAutomationIds';

import { wfaSingleSessionFilterDefinitions } from './WfaSingleSessionFilterDefinitions';

import './WfaSingleSessionFiltersSection.scss';

interface IWfaSingleSessionFiltersProps {
	sessionId: string;
	filters: IFilterOptions;
	onFilterChange: (tableId: string, filters: IFilterOptions) => void;
	tableId: string;
	ariaLabel: string;
}

export const WfaSingleSessionFiltersSection: FC<IWfaSingleSessionFiltersProps> = ({
	sessionId,
	filters,
	onFilterChange,
	tableId,
	ariaLabel
}) => {
	const filterOptionsQuery = useWfaSessionFilterOptionsQuery(sessionId);

	const initialValues = {
		text: { searchValue: filters?.searchValue },
		select: omit(filters, 'searchValue')
	};

	const handleApply = (appliedFilters: IFilterValues): void => {
		const flatFilters = flattenObject<IFilterOptions>(appliedFilters);
		onFilterChange(tableId, flatFilters);
	};

	return (
		<EvSection
			ariaLabel={ariaLabel}
			ariaDescribedby={TABLE_FILTERS_SR_SUMMARY}
			ariaLive="polite"
			skipLinkId="filters-section"
			dataAutomationId={WFA_SESSION_FILTERS_SECTION_ID}
			className="wfa-single-session-filters-section"
		>
			<EvFilters
				className="data-filters"
				onApply={handleApply}
				onReset={handleApply}
				initialValues={initialValues}
				filterOptions={filterOptionsQuery.data ?? {}}
				isFilterOptionsLoaded={!filterOptionsQuery.isLoading}
				filterDefinitions={wfaSingleSessionFilterDefinitions}
			/>
		</EvSection>
	);
};
