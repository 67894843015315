import React from 'react';

import { EvTableColumn, TypesCellFormatter } from '@evinced-private/ui-common';

import { ShowComponentDetailsCellFormatter } from 'src/components/common/show-details-cell-formatter/ShowComponentDetailsCellFormatter';
import { ComponentCellFormatter } from 'src/components/component-cell-formatter/ComponentCellFormatter';
import RoutesHelper from 'src/helpers/RoutesHelper';
import { SessionViews } from 'src/pages/wfa-dashboard/WfaDashboardConsts';
import { IWfaSessionComponent } from 'src/services/wfa-dashboard/WfaDashboardService.types';

import { ProductType } from '../../../../../interfaces/Tenant';

export const getWfaComponentsColumns = (
	sessionId: string,
	onComponentClick: (componentId: string) => void,
	tableId: string
): EvTableColumn[] => {
	return [
		{
			dataField: 'id',
			text: 'Component',
			style: { width: '285px' },
			headerStyle: { width: '285px' },
			sort: true,
			formatter: (componentId: string) => (
				<ComponentCellFormatter
					componentId={componentId}
					tableId={tableId}
					url={RoutesHelper.getWfaSessionPath(sessionId, SessionViews.ALL_ISSUES)}
					onClick={() => onComponentClick(componentId)}
				/>
			)
		},
		{
			dataField: 'totalIssues',
			text: 'Total Issues',
			style: { width: '285px' },
			headerStyle: { width: '285px' },
			sort: true
		},
		{
			dataField: 'pagesImpacted',
			text: 'Pages Impacted',
			style: { width: '285px' },
			headerStyle: { width: '285px' },
			sort: true
		},
		{
			dataField: 'types',
			text: 'Issue Type',
			style: { width: '285px' },
			headerStyle: { width: '285px' },
			sort: false,
			formatter: (types: string[]) => <TypesCellFormatter validationTypes={types} />
		},
		{
			dataField: '',
			text: '',
			style: { width: '80px', paddingRight: '24px', paddingLeft: '24px' },
			headerStyle: { width: '80px' },
			formatter: (cell, { id, totalIssues, pagesImpacted }: IWfaSessionComponent) => {
				return (
					<ShowComponentDetailsCellFormatter
						product={ProductType.WEB_FLOW_ANALYZER}
						runId={sessionId}
						componentId={id}
						totalIssues={totalIssues}
						totalPages={pagesImpacted}
						infoTitle={`Component details and a sample issue for component ${id}`}
					/>
				);
			}
		}
	];
};
