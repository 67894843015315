import { EVINCED_SEVERITIES_IDS } from '@evinced-private/ui-common';

import { EVINCED_SEVERITIES_UUIDS } from './EvincedSeveritiesUUIDs';

// Severity IDs (UUIDs) were copied from server code:
// ../prodmoncore/service/scan/result/ReportService.kt
export const EVINCED_ISSUE_SEVERITY_LIST: {
	key: EVINCED_SEVERITIES_IDS;
	name: string;
	id: EVINCED_SEVERITIES_UUIDS;
}[] = [
	{
		key: EVINCED_SEVERITIES_IDS.CRITICAL,
		name: 'Critical',
		id: EVINCED_SEVERITIES_UUIDS.CRITICAL
	},
	{
		key: EVINCED_SEVERITIES_IDS.SERIOUS,
		name: 'Serious',
		id: EVINCED_SEVERITIES_UUIDS.SERIOUS
	},
	{
		key: EVINCED_SEVERITIES_IDS.MODERATE,
		name: 'Moderate',
		id: EVINCED_SEVERITIES_UUIDS.MODERATE
	},
	{
		key: EVINCED_SEVERITIES_IDS.MINOR,
		name: 'Minor',
		id: EVINCED_SEVERITIES_UUIDS.MINOR
	},
	{
		key: EVINCED_SEVERITIES_IDS.NEEDS_REVIEW,
		name: 'Needs Review',
		id: EVINCED_SEVERITIES_UUIDS.NEEDS_REVIEW
	},
	{
		key: EVINCED_SEVERITIES_IDS.BEST_PRACTICE,
		name: 'Best Practice',
		id: EVINCED_SEVERITIES_UUIDS.BEST_PRACTICE
	}
];
