import { useEffect } from 'react';

export const useWhiteBackground = (): void => {
	useEffect(() => {
		// setting white background to main container since it is the only page with such a colour
		const app = document.getElementsByClassName('app')[0] as HTMLElement;
		const oldBackgroundColor = app.style.backgroundColor;
		app.style.backgroundColor = 'white';
		// setting boxShadow to header since it is the only page with such a header style
		const header = document.getElementsByClassName('ev-header')[0] as HTMLElement;
		const oldHeaderShadow = header.style.boxShadow;
		header.style.boxShadow = '0px 1px 5px rgba(51, 64, 85, 0.1)';

		return () => {
			app.style.backgroundColor = oldBackgroundColor;
			header.style.boxShadow = oldHeaderShadow;
		};
	}, []);
};
