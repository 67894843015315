import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { WFA_SESSION_SUMMARY } from 'src/providers/reactQueryProvider/QueryKeys';
import { getSessionSummary } from 'src/services/wfa-dashboard/WfaDashboardService';

import { SessionSummaryResult } from '../../../services/wfa-dashboard/WfaDashboardService.types';

interface IUseWfaSessionSummaryQuery {
	sessionId: string;
}

export const useWfaSessionSummaryQuery = ({
	sessionId
}: IUseWfaSessionSummaryQuery): UseQueryResult<SessionSummaryResult> => {
	const sessionSummaryQuery = useQuery(
		[WFA_SESSION_SUMMARY, sessionId],
		async (): Promise<SessionSummaryResult> =>
			getSessionSummary({
				sessionId
			})
	);

	return sessionSummaryQuery;
};
