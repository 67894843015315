import React from 'react';

import { EvLinkTypes, EvTableColumn } from '@evinced-private/ui-common';

import DashboardTableLabel from '../../../components/common/DashboardTableLabel/DashboardTableLabel';
import EvLinkLocal from '../../../components/common/ev-link-local/EvLinkLocal';
import renderNumberCellFormatter from '../../../components/common/ev-table/cell-formatters/numberCellFormatter';
import ScreenshotCellFormatter from '../../../components/common/screenshot-cell-formatter/ScreenshotCellFormatter';
import { ShowIssueDetailsCellFormatter } from '../../../components/common/show-details-cell-formatter/ShowIssueDetailsCellFormatter';
import { formatDate, formatShortMonthDatetime } from '../../../helpers/DateFormatHelper';
import RoutesHelper from '../../../helpers/RoutesHelper';
import { ProductType } from '../../../interfaces/Tenant';
import TogglesService, { DevelopmentToggles } from '../../../services/TogglesService';
import { IWebSdkTest, IWebSdkTestIssue } from '../types/WebSdkDashboardServiceTypes';

import { NAME_NOT_PROVIDED } from './WebSdkConsts';

const enableWebSdkProperties = TogglesService.getToggle(
	DevelopmentToggles.ENABLE_WEB_SDK_PROPERTIES
);

export const getWebSdkTestsColumns = (): EvTableColumn[] => {
	return [
		{
			dataField: 'testDisplayName',
			text: 'Test Name',
			style: { width: '270px' },
			headerStyle: { width: '270px' },
			sort: true,
			formatter: (testDisplayName: string, test: IWebSdkTest) => {
				return (
					<EvLinkLocal
						className="web-sdk-test-name"
						id={test.runId}
						url={RoutesHelper.getWebSdkTestPath(test.runId)}
						ariaLabel={`${testDisplayName} Test`}
						linkText={testDisplayName || NAME_NOT_PROVIDED}
						type={EvLinkTypes.DARK}
					/>
				);
			}
		},
		{
			dataField: 'totalIssues',
			text: 'Total Issues',
			style: { width: '90px' },
			headerStyle: { width: '90px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'criticalIssues',
			text: 'Critical Issues',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'runTime',
			text: 'Date',
			style: { width: '90px' },
			headerStyle: { width: '90px' },
			sort: true,
			formatter: (cell: string): string => formatShortMonthDatetime(cell)
		},
		...(enableWebSdkProperties
			? [
					{
						dataField: 'propertiesString',
						text: 'Properties',
						style: { width: '200px', maxWidth: '200px' },
						headerStyle: { width: '200px', maxWidth: '200px' }
					}
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  ]
			: []),
		{
			dataField: 'labels',
			text: 'Labels',
			style: { width: '135px' },
			headerStyle: { width: '135px' },
			formatter: (labels: string[]) => <DashboardTableLabel labels={labels} />
		}
	];
};

export const getWebSdkTestIssuesColumns = (
	runId: string,
	runTime = '',
	testName?: string
): EvTableColumn[] => {
	return [
		{
			dataField: 'screenShotPromise',
			text: 'Screenshot',
			style: { width: '182px', minWidth: '182px' },
			classes: 'image-cell-td',
			headerStyle: { width: '182px' },
			formatter: (cell, issue: IWebSdkTestIssue) => {
				return (
					<ScreenshotCellFormatter
						cellDescriptor={`${issue.url} ${issue.type}`}
						screenshotDetails={{
							pageScreenshotUrl: issue.screenshotUrl,
							boundingBoxes: issue.boundingBoxes
						}}
					/>
				);
			}
		},
		{
			dataField: 'type',
			text: 'Issue Type',
			style: { width: '164px' },
			headerStyle: { width: '164px' },
			sort: true
		},
		{
			dataField: 'severity',
			text: 'Severity',
			style: { width: '90px' },
			headerStyle: { width: '90px' },
			sort: true
		},
		{
			dataField: 'component',
			text: 'Component',
			style: { width: '100px' },
			headerStyle: { width: '100px' }
		},
		{
			dataField: 'url',
			text: 'Url',
			sort: true,
			style: { width: '240px', maxWidth: '240px' },
			headerStyle: { width: '240px', maxWidth: '240px' },
			classes: 'web-sdk-issues-table-url-column'
		},
		{
			dataField: 'pageTitle',
			text: 'Page Title',
			sort: true,
			style: { maxWidth: '160px' },
			headerStyle: { maxWidth: '160px' }
		},
		{
			dataField: '',
			text: '',
			style: { width: '80px', paddingRight: '24px', paddingLeft: '24px' },
			headerStyle: { width: '80px' },
			formatter: (cell, issue: IWebSdkTestIssue) => {
				return (
					<ShowIssueDetailsCellFormatter
						product={ProductType.WEB_SDK}
						runId={runId}
						issueId={issue.id}
						issueType={issue.type}
						infoTitle={`${testName || NAME_NOT_PROVIDED} run at ${formatDate(runTime)}`}
					/>
				);
			}
		}
	];
};
