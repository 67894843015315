import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';

import RoutesHelper from 'src/helpers/RoutesHelper';

import { WfaSingleIssuePage } from '../single-issue-page/WfaSingleIssuePage';
import { SessionViews } from '../WfaDashboardConsts';

import { WfaSingleSessionPage } from './WfaSingleSessionPage';

const SESSION_ID_KEY = ':sessionId';
const ISSUE_ID_KEY = ':issueId';

export const WfaSingleSessionRouter: FC = () => {
	const history = useHistory();
	const basePath = `${RoutesHelper.getWfaSessionBasePath()}/:sessionId`;
	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={RoutesHelper.getWfaSessionPath(SESSION_ID_KEY, SessionViews.OVERVIEW)}
					render={() => <WfaSingleSessionPage viewId={SessionViews.OVERVIEW} />}
				/>
				<Route
					exact
					path={RoutesHelper.getWfaSessionPath(SESSION_ID_KEY, SessionViews.COMPONENTS)}
					render={() => <WfaSingleSessionPage viewId={SessionViews.COMPONENTS} />}
				/>
				<Route
					exact
					path={RoutesHelper.getWfaSessionPath(SESSION_ID_KEY, SessionViews.ALL_ISSUES)}
					render={() => <WfaSingleSessionPage viewId={SessionViews.ALL_ISSUES} />}
				/>
				<Route
					exact
					path={RoutesHelper.getWfaIssueInfoPath({
						runId: SESSION_ID_KEY,
						issueId: ISSUE_ID_KEY
					})}
					render={() => <WfaSingleIssuePage />}
				/>
				<Route
					exact
					path={basePath}
					render={() => <WfaSingleSessionPage viewId={SessionViews.OVERVIEW} />}
				/>
				<Route render={() => <WfaSingleSessionPage viewId={SessionViews.OVERVIEW} />} />
			</Switch>
		</Router>
	);
};
