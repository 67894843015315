export const SELECT_ALL_VALUE = '*';

export enum FilterTypesNames {
	SEARCH_VALUE = 'searchValue',
	LABELS = 'userLabels',
	COMPONENTS = 'components',
	TYPES = 'types',
	SEVERITIES = 'severities',
	URLS = 'urls',
	TAGS = 'tags',
	OS = 'os',
	APPS = 'apps',
	VERSIONS = 'versions'
}
