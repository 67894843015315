import React, { FC } from 'react';

import {
	EvFilters,
	EvSection,
	EvTitle,
	IFilterValues,
	TABLE_FILTERS_SR_SUMMARY } from '@evinced-private/ui-common';

import omit from 'lodash-es/omit';
import { IFilterOptions } from 'src/helpers/DataFilterHelper';
import { flattenObject } from 'src/helpers/ObjectsHelper';
import { WFA_SESSIONS_TABLE_ID } from 'src/pages/dashboards-container/dashboard_consts';
import { useWfaUserOptionsQuery } from 'src/pages/wfa-dashboard/hooks/useWfaUserOptionsQuery';

import {
	WFA_DASHBOARD_PAGE_FILTERS_SECTION_ID,
	WFA_DASHBOARD_PAGE_MAIN_TITLE_ID } from '../../../WfaDashboardAutomationIds';

import {
	SESSIONS_PERIOD_OPTIONS,
	wfaSessionsFilterDefinitions
} from './WfaSessionsFilterDefinitions';

import './WfaSessionsHeader.scss';

const WFA_PAGE_TITLE = 'Web Flow Analyzer session reports';

interface WfaSessionsPageHeaderProps {
	filters: IFilterOptions;
	onFilterChange: (tableId: string, filters: IFilterOptions) => void;
}

const EMPTY_CREATED_BY_OPTION = { value: ' ', label: 'Unknown' };

export const WfaSessionsPageHeader: FC<WfaSessionsPageHeaderProps> = ({
	filters,
	onFilterChange
}) => {
	const userOptionsQuery = useWfaUserOptionsQuery();

	const initialValues = {
		text: { searchValue: filters?.searchValue },
		select: omit(filters, 'searchValue')
	};

	const filterOptions = {
		sessionsPeriod: SESSIONS_PERIOD_OPTIONS,
		createdBy:
			userOptionsQuery.data?.map((item) => {
				if (typeof item.value === 'string' && item.value === ' ') {
					return EMPTY_CREATED_BY_OPTION;
				}
				return item;
			}) ?? []
	};

	const handleApply = (appliedFilters: IFilterValues): void => {
		const flatFilters = flattenObject<IFilterOptions>(appliedFilters);
		onFilterChange(WFA_SESSIONS_TABLE_ID, flatFilters);
	};

	return (
		<div className="wfa-sessions-header">
			<EvTitle
				titleText={WFA_PAGE_TITLE}
				dataAutomationId={WFA_DASHBOARD_PAGE_MAIN_TITLE_ID}
				headingLevel={1}
				className="main-title page-heading"
			/>
			<EvSection
				ariaLabel="Web Flow Analyzer filters"
				ariaDescribedby={TABLE_FILTERS_SR_SUMMARY}
				ariaLive="polite"
				skipLinkId="filters-section"
				className="dashboard-section"
				dataAutomationId={WFA_DASHBOARD_PAGE_FILTERS_SECTION_ID}
			>
				<EvFilters
					className="data-filters"
					onApply={handleApply}
					onReset={handleApply}
					initialValues={initialValues}
					filterOptions={filterOptions}
					filterDefinitions={wfaSessionsFilterDefinitions}
					isInitialValuesLoaded={!userOptionsQuery.isLoading}
				/>
			</EvSection>
		</div>
	);
};
