// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.issue-details-page {
  margin-top: 5px;
  padding: 65px;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/apps/web-sdks/pages/issue/WebSdkIssuePage.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,aAAA;EACA,uBAAA;AACD","sourcesContent":[".issue-details-page {\n\tmargin-top: 5px;\n\tpadding: 65px;\n\tbackground-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
