import React, { FC } from 'react';

import { EvPopup, IEvPopupProps } from '@evinced-private/ui-common';

import { PLATFORM_APP_ID } from '../../../consts/dom-consts';

const PlatformPopup: FC<IEvPopupProps> = (props) => {
	return <EvPopup appElement={PLATFORM_APP_ID} {...props} />;
};

export default PlatformPopup;
