import ServerApi from 'src/api/ServerApi';
import API_URL_PATHS from 'src/consts/ApiUrlPaths';

import logger from '../Logger';

const { PROPERTIES_URL_PATH } = API_URL_PATHS;

export const getPropertiesMap = async (): Promise<Map<string, string>> => {
	try {
		const properties: { name: string; id: string }[] = await ServerApi.apiCall(
			'GET',
			`${PROPERTIES_URL_PATH}`,
			{
				isAbsoluteUrl: true
			}
		);
		const resultMap = new Map(properties.map((property) => [property.id, property.name]));

		return resultMap;
	} catch (e) {
		logger.error('PropertiesService -> getPropertiesMap failed with error', e);
		return new Map();
	}
};
