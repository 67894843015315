import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { BUTTON_TYPES } from '@evinced-private/ui-common';

import PlatformPopup from 'src/components/common/platform-popup/PlatformPopup';
import {
	NOT_SYNCED_YET_POPUP_TEXT,
	NOT_SYNCED_YET_POPUP_TITLE,
	NOT_SYNCED_YET_STATE_KEY
} from 'src/pages/wfa-dashboard/WfaDashboardConsts';

export const WfaSessionNotSyncedYetPopup: FC = () => {
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(history.location?.state?.[NOT_SYNCED_YET_STATE_KEY]);

	const handleClose = (): void => setIsOpen(false);

	useEffect(() => {
		history.replace({ state: { [NOT_SYNCED_YET_STATE_KEY]: false } });
	}, [history]);

	return (
		<PlatformPopup
			title={NOT_SYNCED_YET_POPUP_TITLE}
			isControlled
			isBgTransparent
			isOpen={isOpen}
			popupWidth={480}
			onClose={handleClose}
			buttons={[
				{
					type: BUTTON_TYPES.SECONDARY,
					title: 'Cancel',
					onClick: (onClose) => onClose()
				},
				{
					title: 'OK',
					onClick: (onClose) => {
						onClose();
						window.location.reload();
					}
				}
			]}
		>
			{NOT_SYNCED_YET_POPUP_TEXT}
		</PlatformPopup>
	);
};
